<template>
  <div>
    <!-- 骨架屏 -->
    <skeleton-screen v-if="!dataLoaded"></skeleton-screen>
    <div v-if="dataLoaded" class="mb-2 d-flex justify-content-between">
      <div class="mt-3 sjjkdax" v-if="show == 0" style="font-size: calc(8px + 0.4vw);font-weight: 600;">{{ $t('捐款团体列表') }}</div>
      <div class="pl-0 pr-0 position-relative mt-3" v-if="show == 0">
        <button class="d-flex ckqbb zhankai dydpd pt-2 pl-2 pr-2 pb-2 pl-0 pr-0" type="button" id="dropdownMenuButton" @click="toggleDropdown()">
          <div class="d-flex align-items-center justify-content-between col-md-12">
            <div class="sjbxzt" style="font-size: calc(6px + 0.4vw);">{{ jhuxa ? jhuxa : '' }}
            </div>
            <div class="ml-3"><img style="width: calc(7px + 0.4vw);" src="@/img/daohangl/icon_xlcd_xljt@2x (1).png" alt="" /></div>
          </div>
        </button>
        <div class="dropdown-menu yuanjiao xlcd position-absolute  text-center" aria-labelledby="dropdownMenuButton" v-show="isDropdownOpen" style="border-radius:7px;background: #FFFAF0;">
          <a class="dropdown-item xuazz yuanjiao mb-2 sjbxzt" :style="{ 'background': jhuxa == item.text ? '#FFD672' : '' }" @click="xuanz(item)" href="#" v-for="(item, index) in options" :key="index">{{ item.text }}</a>
        </div>
      </div>
      <!-- <div v-if="show == 0" @click="emsl()" class="d-flex align-items-center curpo fangda mt-3" style="font-size: calc(6px + 0.4vw);">
        <div style="margin-top: 2px;">{{ $t('查看全部') }}</div>
        <div><img class="ml-1" style="width: calc(10px + 0.5vw);" src="@/img/daohangl/icon_hssy_hdlb_xiangqing@2x.png" alt="" /></div>
      </div> -->
    </div>
    <div v-if="dataLoaded" class="col-md-12 pl-0 pr-0">
      <div v-if="show == 0" class="yinc col-md-12 pl-0 pr-0">
        <div class="col-md-12 pl-0 pr-0  d-flex pl-0 pr-0 ">
          <div class="col-md-12 pl-0 pr-0">
            <!--  v-for="(itema, index) in allList" :key="index" -->
            <div class="mt-1 d-flex lieb" style="flex-wrap: wrap;gap: 1%;width: 100%;">
              <div class="sjliebkd" style="width: 24%;" v-for="(item, index) in items " :key="index">
                <div class="col-sm-12 baise mb-3 ckqbb p-3 yuanjiao pl-0 pr-0 curpo" style="width: 100%;text-align: center;">
                  <img @click="fun(1, item.id)" class="yuanjiao" style="width: 100%;height:calc(210px + 0.4vw);object-fit: cover;" :src="item.image" alt="" />
                  <div @click="fun(1, item.id)" class="mt-3" style="font-size: calc(8px + 0.4vw);font-weight: 700;"><span style="color: #FF797A;">{{ item.name }}</span> </div>
                  <div @click="fun(1, item.id)" class="mt-2 ellipsis col-md-12 pl-0 pr-0 mb-2 col-sm-12 col-lg-9" style="font-size: calc(6px + 0.4vw);width: calc(180px + 0.5vw);margin: auto;">
                    {{ item.organization_name }}
                  </div>
                  <div @click="fun(1, item.id)" class="col-sm-12 d-flex justify-content-between pl-0 pr-0" style="width: 100%;">
                    <div class="col-sm-4 text-center pl-0 pr-0" style="font-size: calc(5px + 0.4vw);">
                      <div style="color: #999999;">{{ $t('已使用捐款') }}</div>
                      <div style="font-weight: 500;">{{ item.used_amount ? item.used_amount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') : '0' }}</div>
                    </div>
                    <div class="col-sm-4 text-center pl-0 pr-0" style="font-size: calc(5px + 0.4vw);">
                      <div style="color: #999999;">{{ $t('已收到捐款') }}</div>
                      <div style="font-weight: 500;">{{ item.received_amount ? item.received_amount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') : '0' }}</div>
                    </div>
                    <div class="col-sm-4 text-center pl-0 pr-0" style="font-size: calc(5px + 0.4vw);">
                      <div style="color: #999999;">{{ $t('累计捐款人数') }}</div>
                      <div style="font-weight: 500;">{{ item.donor_num ? item.donor_num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') : '0' }}</div>
                    </div>
                  </div>
                  <div class="col-sm-12 pl-0 pr-0 d-flex justify-content-between mt-3 align-items-center" style="width: 100%;">
                    <div @click="fun(2,item.id)" class="col-sm-4 pl-0 pr-0 ckqbb curpo d-flex yuanjiao pl-2 pr-2 mr-2 pt-2 pb-2 align-items-center" style="background: #FDDDC9;text-align: center;border-radius: 15px;">
                      <div style="font-size: calc(4px + 0.4vw);text-align: center;width: 100%;">
                        {{ $t('资金使用') }}</div>
                      <img style="width: calc(2px + 0.2vw);height:calc(3px + 0.3vw);" src="@/img/details/icon_right@2x.png" alt="" />
                    </div>
                    <!-- 动态进度条 -->
                    <div @click="fun(1, item.id)" class="yuanjiao col-sm-8 pl-0 pr-0" style="background-color: #F6F4F0;height: calc(15px + 0.4vw);">
                      <div class="progress-bar yuanjiao jdtt" role="progressbar" :style="{'width': item.received_amount ?  Math.floor(item.used_amount / item.received_amount * 100) + '%' : 0 + '%'}" aria-valuemin="0" aria-valuemax="100"></div>
                      <div style="position: absolute;right: 5%;top: 0;font-size: calc(8px + 0.4vw);">{{ item.received_amount ? Math.floor(item.used_amount /item.received_amount  * 100) : 0 }}%</div>

                    </div>

                  </div>
                  <div class="col-sm-12 pl-0 pr-0 d-flex justify-content-between mt-3 align-items-center">
                    <div @click="fun(1, item.id)" class="mr-2 col-sm-4 pl-0 pr-0 ckqbb curpo d-flex yuanjiao pl-2 pr-2 pt-2 pb-2 align-items-center" style="background: #F6F4F0;">
                      <div class="mr-1" style="font-size: calc(6px + 0.4vw);text-align: center;width: 100%;">
                        {{ $t('查看详情') }}</div>
                    </div>
                    <!-- 动态进度条 -->
                    <div @click="fun(3, item.id)" class="col-sm-8 pl-0 pr-0 ckqbb yuanjiao pt-2 pb-2" style="background-color: #FFD672;text-align: center;font-size: calc(7px + 0.4vw);">
                      {{ $t('捐款') }}
                    </div>
                  </div>
                </div>
              </div>

            </div>
          </div>
          <!-- 右箭头 -->
          <!-- <div class="col-auto position-absolute pl-0 pr-0 dcbd curpo xsdann"
						style="z-index: 99999;right: 0;" @click="scrollRight">
						<img style="width: calc(23px + 0.5vw);" src="@/img/details/icon_jklb_xiangyou@2x.png" alt="" />
					</div> -->
        </div>
      </div>
      <!-- 手机 -->
      <div v-if="show == 0" class="aaaa" style="width: 100%;">
        <div class="lieb mt-0" style="display: flex;flex-wrap: wrap;gap: 1%;width: 100%;">
          <div style="width: 100%;" v-for="subItem in items" :key="subItem.id">
            <div class="col-sm-12 baise mb-3 ckqbb p-3 yuanjiao pl-0 pr-0 curpo" style="width: 100%;text-align: center;">
              <img @click="fun(1, subItem.id)" class="yuanjiao" style="width: 100%;height: calc(170px + 0.4vw);object-fit: cover;" :src="subItem.image" alt="" />
              <div @click="fun(1, subItem.id)" class="mt-3 sjbzt" style="font-size: calc(8px + 0.4vw);font-weight: 700;"><span style="color: #FF797A;">{{ subItem.name }}</span> </div>
              <div @click="fun(1, subItem.id)" class="mt-2 sjbxzt ellipsis col-md-12 pl-0 pr-0 mb-2 col-sm-12 col-lg-9" style="font-size: calc(6px + 0.4vw);width: calc(200px + 0.5vw);margin: auto;">
                {{ subItem.organization_name }}
              </div>
              <div @click="fun(1, subItem.id)" class="col-sm-12 d-flex justify-content-between pl-0 pr-0" style="width: 100%;">
                <div class="col-sm-4 text-center pl-0 pr-0 sjbxzt" style="font-size: calc(5px + 0.4vw);">
                  <div style="color: #999999;">{{ $t('已使用捐款')}}</div>
                  <div style="font-weight: 500;">{{ subItem.used_amount ? subItem.used_amount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') : '0' }}</div>
                </div>
                <div class="col-sm-4 text-center pl-0 pr-0 sjbxzt" style="font-size: calc(5px + 0.4vw);">
                  <div style="color: #999999;">{{ $t('已收到捐款')}}</div>
                  <div style="font-weight: 500;">{{ subItem.received_amount ? subItem.received_amount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') : '0' }}</div>
                </div>
                <div class="col-sm-4 text-center pl-0 pr-0 sjbxzt" style="font-size: calc(5px + 0.4vw);">
                  <div style="color: #999999;">{{ $t('累计捐款人次')}}</div>
                  <div style="font-weight: 500;">{{ subItem.donor_num ? subItem.donor_num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') : '0' }}</div>
                </div>
              </div>
              <div class="col-sm-12 pl-0 pr-0 d-flex justify-content-between mt-3 align-items-center" style="width: 100%;">
                <div @click="fun(2, subItem.id)" class="col-sm-4 pl-0 pr-0 ckqbb curpo d-flex yuanjiao pl-2 pr-2 mr-2 pt-2 pb-2 align-items-center" style="background: #FDDDC9;text-align: center;border-radius: 15px;width: 30%;">
                  <div class="sjbxzt" style="font-size: calc(4px + 0.4vw);text-align: center;width: 100%;">
                    {{ $t('资金使用')}}</div>
                  <img style="width: calc(2px + 0.2vw);height:calc(3px + 0.3vw);" src="@/img/details/icon_right@2x.png" alt="" />
                </div>
                <!-- 动态进度条 -->
                <div @click="fun(1, subItem.id)" class="yuanjiao col-sm-8 pl-0 pr-0" style="background-color: #F6F4F0;height: calc(20px + 0.4vw);">
                  <div class="progress-bar yuanjiao jdtt" role="progressbar" :style="{'width': subItem.received_amount ?  Math.floor(subItem.used_amount / subItem.received_amount * 100) + '%' : 0 + '%'}" aria-valuemin="0" aria-valuemax="100"></div>
                  <div class="sjbzt" style="position: absolute;right: 5%;top: 0;font-size: calc(8px + 0.4vw);">{{ subItem.received_amount ? Math.floor(subItem.used_amount / subItem.received_amount  * 100) : 0 }}%</div>

                </div>
              </div>
              <div class="col-sm-12 pl-0 pr-0 d-flex justify-content-between mt-3 align-items-center">
                <div @click="fun(1, subItem.id)" class="mr-2 col-sm-4 pl-0 pr-0 ckqbb curpo d-flex yuanjiao pl-2 pr-2 pt-2 pb-2 align-items-center" style="background: #F6F4F0;width: 30%;">
                  <div class="mr-1 sjbxzt" style="font-size: calc(6px + 0.4vw);text-align: center;width: 100%;">
                    {{ $t('查看详情')}}</div>
                </div>
                <!-- 动态进度条 -->
                <div @click="fun(3, subItem.id)" class="col-sm-8 pl-0 pr-0 sjbzt ckqbb yuanjiao pt-2 pb-2" style="background-color: #FFD672;text-align: center;font-size: calc(7px + 0.4vw);">
                  {{ $t('捐款')}}
                </div>
              </div>
            </div>

          </div>
        </div>

      </div>
    </div>
    <router-view />
    <!-- <ckxq v-if="show == 1" :ckids="ids" :ckidss="idss" :istype="isT" @custom='custom'></ckxq> -->
    <!-- <zzsy v-if="show == 2" @custom='custom' :ckidss="idss"></zzsy> -->
    <juank v-if="show == 3" :isid="idds" @custom='custom'></juank>
  </div>
</template>

<script>
import { EventBus } from "@/views/event-bus.js";
import { donation_ActiveLis } from "@/request/aapi.js";
import SkeletonScreen from "./SkeletonScreen/xinSkeletonScreen.vue";
// import ckxq from "./wmuc/ckxq.vue";
// import zzsy from "./wmuc/zzsy.vue";
import juank from "./wmuc/juank.vue";
import router from "@/router";
export default {
  components: {
    SkeletonScreen,
    // ckxq,
    // zzsy,
    juank,
  },
  data() {
    return {
      jhuxa: this.$t("请选择排序类型"),
      isDropdownOpen: false,
      options: [
        // { value: null, text: '请选择排序类型' },
        {
          value: "received_amount_desc",
          text: this.$t("金额最高"),
        },
        {
          value: "donor_num_desc",
          text: this.$t("人数最多"),
        },
        {
          value: "is_use_desc",
          text: this.$t("有交代资金用途"),
        },
        {
          value: "create_time_asc",
          text: this.$t("创建时间升序"),
        },
        {
          value: "create_time_desc",
          text: this.$t("创建时间降序"),
        },
      ],
      dataLoaded: false,
      show: 0,
      currentIndex: 0, // 当前显示的列表索引
      // 用于存储当前应该显示的列表项（按每四个一组分组）
      visibleItems: [],
      progress: 60,
      items: [],
      idss: null,
      isT: null,
      idds: null,
      currentPage:1
    };
  },
  props: {
    ids: {
      type: Number,
      default: null,
    },
  },

  created() {
    this.updateVisibleItems();
    EventBus.$on("tthdfhs", (msg) => {
      // 执行方法，处理接收到的数据
      // this.message = msg;
      // 如果需要，可以在这里调用其他方法
      this.show = 0;
      this.$emit("custom", 0);
    });
  },
  beforeDestroy() {
    // 组件销毁前移除监听器，防止内存泄漏
    EventBus.$off("tthdfhs");
  },
  mounted() {
    if(this.$route.path.includes("/donate-use")){
      this.$emit("custom", 2);
      this.show=2
    }else if(this.$route.path.includes("/donation/")){
      this.$emit("custom", 1);
      this.show=1
    }else if(this.$route.path.includes("/donation")){
    }
  },
  computed: {},
  methods: {
    xuanz(e) {
      this.infos = [];
      this.jhuxa = e.text;
      this.selected = e.value;
      this.isDropdownOpen = false;
      this.updateVisibleItems();
    },
    toggleDropdown() {
      this.isDropdownOpen = !this.isDropdownOpen;
      // 如果需要，可以在这里添加Bootstrap Dropdown的额外处理逻辑
    },
    gundong(){
      console.log(123)
      if (this.currentPage < this.last_page) {
        this.currentPage += 1;
        this.updateVisibleItems();
        }
      
    },
    
    updateVisibleItems() {
      // 计算当前应该显示的列表项索引范围
      const data = {
        page: this.currentPage,
        order: this.selected,
      };
      donation_ActiveLis(data).then((res) => {
        if (res.code == 200) {
          this.dataLoaded = true;
        }
        this.last_page = res.data.last_page;
        this.totalPages = res.data.total;
        if (this.totalPages == 0) {
          this.ishow = true;
        } else {
          this.ishow = false;
        }
        this.items = [...this.items, ...res.data.data];
      });
    },
    // 查看全部跳转
    emsl() {
      this.$emit("emslfun", 1);
      // this.$refs..getInfo()
    },
    smdckxq(e) {
      this.fun(1, e);
    },
    // 详情等页面返回按钮
    custom(e) {
      this.show = e;
      this.$emit("custom", e);
    },
    fun(e, a) {
      this.show = e;
      if (e == 1 && a) {
        this.isT = 1;
        this.idss = a;
        this.$router.push({
          path: "/donation/" + a,
          params: {
            isT: 1,
          },
        });
      } else if (e == 2 && a) {
        this.isT = 1;
        this.idss = a;
        this.$router.push({
         
          name:'zzsy',
          params: {
            isT: 2,
            zjsy:a
          },
        });
      } else if (e == 3 && a) {
        // console.log(a)
        this.idds = a;
        if (localStorage.getItem("token")) {
        } else {
          localStorage.removeItem("token");
          localStorage.removeItem("user_id");
          localStorage.setItem("previousRoutePath", this.$router.currentRoute);
          this.$router.push("/login");
          return;
        }
        
      }
      this.$emit("custom", e);
    },
  },
};
</script>

<style scoped lang="scss">
@media screen and (max-width: 1200px) {
  .sjliebkd {
    width: 49% !important;
  }
  .sjyinc {
    display: block !important;
  }
  .sjjkdax {
    font-size: calc(13px + 0.4vw) !important;
  }
}
@media screen and (max-width: 750px) {
  .sjbzt {
    font-size: calc(11px + 0.4vw) !important;
  }
  .sjbxzt {
    font-size: calc(9px + 0.4vw) !important;
  }
  .aaaa {
    display: block !important;
  }
  .yinc {
    display: none !important;
  }
}
.aaaa {
  display: none;
}
.sjyinc {
  display: none;
}
.ckqbb:hover {
  transform: scale(1.001);
  /* 悬浮时放大1.1倍 */
  // padding: 12px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.dcbd:hover {
  transform: scale(1.2);
  /* 悬浮时放大1.1倍 */
  // padding: 12px;
}
.lieb::-webkit-scrollbar {
  display: none;
}
.jdtt {
  height: 100%;
  background: #fab1ab;
  color: #1a1a1a;
  text-align: right;
  font-size: calc(6px + 0.4vw);
}

.fangda:hover {
  transform: scale(1.02);
}
.xlcd {
  display: block;
  background: #ffebd2;
  border: none;
}

.xuazz:hover {
  background: #ffebd2;
}
.dropdown-menu {
  min-width: calc(65px + 0.5vw) !important;
}
.zhankai {
  background: #ffebd2;
  text-align: center;
  border-radius: 8px;
  border: none;
  font-weight: 400;
  font-size: calc(6px + 0.4vw);
  color: #1a1a1a;
}

.parent::-webkit-scrollbar {
  display: none;
}

.custom-pagination ::v-deep .page-item.active .page-link {
  background-color: rgb(255, 214, 114);
  border-color: rgb(255, 214, 114);
  color: #000;
}
</style>