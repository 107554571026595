<template>
  <div class="col-md-12 col-sm-12 col-lg-12 col-xl-12 pl-0 pr-0">
    <skeleton-screen v-if="!dataLoaded"></skeleton-screen>
    <div v-if="dataLoaded" class="col-md-12 pb-3 pl-0 pr-0" style="font-size: calc(7px + 0.4vw);">
       <!-- pc版样式-->
      <div v-if="tyugyu == 0" class="none">
        <div class="pl-3 pt-3 pb-3 mb-3 baise yuanjiao col-md-12 pl-0 pr-3 d-flex justify-content-between asdfas">
          <div class="d-flex  pl-0 pr-0 align-items-center  pt-3 pb-3 ckqbb p_top"
            style="background: #FFFAF0;border-radius:12px;width: 49.4%;justify-content: space-between;position: relative;overflow: hidden;">
            <div class="d-flex align-items-center ml-3" style="z-index: 999;">
              <img class="mr-2 img" style="width: 50px;font-size: calc(9px + 0.4vw);font-weight: 500;height: 50px;"
                src="@/img/daohangl/pic_hssy_haorenbi@2x.png" alt="" />
              <div class="">
                <div style="font-size: calc(6px + 0.4vw);" class="sjbxzt">{{ $t("平台总发行好人币") }}</div>
                <div style="font-size: calc(15px + 0.4vw);font-weight: 700;">{{ user.
                  good_coin ? user.
                    good_coin.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') : '0' }}</div>
              </div>
            </div>

            <div class="bgheight"
              style="width: 100%;position: absolute;bottom: 0;height: 60%;background-color: #FDDDC9;display: flex;justify-content: flex-end;">
              <img style="height: 100%;width: 70%;" src="@/img/pic_kapianzhuangshi@2x.png" alt="" />
            </div>
          </div>
          <div class="d-flex  pl-0 pr-0 align-items-center  pt-3 pb-3 ckqbb p_top"
            style="background: #FFFAF0;border-radius:12px;width: 49.4%;justify-content: space-between;position: relative;overflow: hidden;">
            <div class="d-flex align-items-center ml-3 " style="z-index: 999;">
              <img class="mr-2 img" style="width: 50px;font-size: calc(9px + 0.4vw);font-weight: 500;height: 50px;"
                src="@/img/daohangl/pic_hssy_tanbi@2x.png" alt="" />
              <div class="">
                <div style="font-size: calc(6px + 0.4vw);" class="sjbxzt">{{ $t("平台总发行碳币") }}</div>
                <div style="font-size: calc(15px + 0.4vw);font-weight: 700;">{{ user.
                  carbon_coin ? user.
                    carbon_coin.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') : '0' }}</div>
              </div>
            </div>
            <div class="bgheight"
              style="width: 100%;position: absolute;bottom: 0;height: 60%;background-color: #FDDDC9;display: flex;justify-content: flex-end;">
              <img style="height: 100%;width: 70%;" src="@/img/pic_kapianzhuangshi@2x.png" alt="" />
            </div>
          </div>
        </div>
        <div class="">
          <div class="col-md-12 pl-0 pr-0 d-flex justify-content-between">
            <div class="d-flex ">
              <div @click="hsjl()" class="pt-2 pb-2 baise pl-5 pr-5 curpo"
                :style="{ 'background': xuanz ? '' : '#FFFAF0' }"
                style="font-size: calc(7px + 0.4vw);border-radius: 12px 0 0 0;">{{ $t("好事记录") }}
              </div>
              <div @click="zgzm()" class="pt-2 pb-2 baise pl-5 pr-5 curpo"
                :style="{ 'background': !xuanz ? '' : '#FFFAF0' }"
                style="font-size: calc(7px + 0.4vw);border-radius: 0 12px 0 0;">{{ $t("志工证明") }}
              </div>
            </div>
            <div class="d-flex pl-0 pr-0" style="justify-content: space-between;">
              <b-form-datepicker v-model="cfsj" aria-controls="example-input" class="a1 pl-3 pr-3"
                :label-no-date-selected="$t('请选择日期')" style="width: 100%;padding: 5px 0px;"
                @context="onContext"></b-form-datepicker>
            </div>
          </div>
          <div v-if="xuanz" class="baise p-3 col-md-12 pl-0 pr-0" style="border-radius: 0 0 12px 12px;">
            <div class="col-md-12 pt-3 pb-3 mb-3 pl-0 pr-0 yuanjiao d-flex justify-content-between align-items-center"
              style="background: #FFFAF0;">
              <div class="col-md-1 ellipsis col-sm-1 col-lg-1 col-xl-1 pl-0 pr-0 ml-3" style="color: #999999;">{{
                $t("好事记录") }}</div>
              <div class="col-md-1 col-sm-2 col-lg-1 col-xl-1 pl-0 pr-0 " style="color: #999999;">{{ $t("日期") }}
              </div>
              <div class="col-md-1 col-sm-1 col-lg-1 col-xl-1 pl-0 pr-0" style="color: #999999;">{{ $t("好人积分") }}</div>
              <div class="col-md-1 col-sm-1 col-lg-1 col-xl-1 pl-0 pr-0" style="color: #999999;">{{ $t("碳积分") }}</div>
              <div class="col-md-2 col-sm-2 col-lg-1 col-xl-1 pl-0 pr-0" style="color: #999999;">{{ $t("活动单位") }}</div>
              <div class="col-md-1 ellipsis col-sm-1 col-lg-1 col-xl-1 pl-0 pr-0" style="color: #999999;">{{ $t("活动地点")
                }}
              </div>
              <div class="col-md-1 col-sm-1 col-lg-1 col-xl-1 pl-0 pr-0 mr-3" style="color: #999999;">{{ $t('活动花絮') }}
              </div>
            </div>
            <div style="height: 61.4vh;overflow-y: auto;" class="parent" @scroll="handleScroll">
              <div v-for="(item, index) in infoList" :key="index"
                class="col-md-12 pt-1 pb-1  pl-0 pr-0 yuanjiao d-flex justify-content-between align-items-center"
                :style="{ backgroundColor: index % 2 === 0 ? 'white' : '#F6F4F0' }">

                <div class="col-md-1 ellipsis col-sm-1 col-lg-1 col-xl-1 pl-0 pr-0  ml-3 text-overflow-hover"
                  :title="item.name" @mouseenter="showFullText = true" @mouseleave="showFullText = false">{{ item.name ?
                    item.name : '--' }}</div>
                <div class="col-md-1 col-sm-2 col-lg-1 col-xl-1 pl-0 pr-0" style="color: #999999;">{{
                  timeFilter(item.create_time) }}</div>
                <div class="col-md-1 col-sm-1 col-lg-1 col-xl-1 pl-0 pr-0 d-flex" style="align-items: center;">
                  <img class="mr-2" style="width: 20px;font-weight: 500;height: 20px;"
                    src="@/img/daohangl/pic_hssy_haorenbi@2x.png" alt="" />
                  {{ item.good_coin > 0 ? '+' : '' }}
                  {{
                    item.good_coin }}
                </div>
                <div class="col-md-1 col-sm-1 col-lg-1 col-xl-1 pl-0 pr-0 d-flex" style="align-items: center;">
                  <img class="mr-2" style="width: 20px;font-weight: 500;height: 20px;"
                    src="@/img/daohangl/pic_hssy_tanbi@2x.png" alt="" />
                  {{ item.carbon_coin > 0 ? '+' : ''
                  }} {{
                    item.carbon_coin }}
                </div>
                <div class="col-md-2 col-sm-2 ellipsis col-lg-1 col-xl-1 pl-0 pr-0">{{ item.organization_name }}</div>
                <div class="col-md-1 ellipsis col-sm-1 col-lg-1 col-xl-1 pl-0 pr-0">{{ item.address ? item.address :
                  '--'
                  }}</div>

                <img v-if="item.image" @click="tiaozhu(item.category, item.activity_id)"
                  class="col-md-1 col-sm-1 col-lg-1 col-xl-1 pl-0 pr-0 mr-3 curpo"
                  style="height: 100px;object-fit: contain;" :src="item.image" alt="" />
                <div v-else @click="tiaozhu(item.category, item.activity_id)"
                  class="col-md-1 col-sm-1 col-lg-1 col-xl-1 pl-0 pr-0 mr-3 curpo" style="height: 100px;"></div>

              </div>
              <!-- 加载更多提示 -->
              <div class="d-flex justify-content-center">
                <!-- <div v-if="isLoading" class="loading">{{ $t("加载中...") }}</div> -->
                <div v-if="infoList.length == 0">
                  <empty></empty>
                </div>
              </div>
            </div>
          </div>
          <div v-else class="baise p-3 col-md-12 pl-0 pr-0" style="border-radius: 0 0 12px 12px;">
            <div class="col-md-12 pt-3 pb-3 mb-3 pl-0 pr-0 yuanjiao d-flex justify-content-between align-items-center"
              style="background: #FFFAF0;">
              <div class="col-md-1 ellipsis col-sm-1 col-lg-1 col-xl-1 pl-0 pr-0 ml-3" style="color: #999999;">{{
                $t("参与单位") }}</div>
              <div class="col-md-1 col-sm-2 col-lg-1 col-xl-1 pl-0 pr-0 " style="color: #999999;">{{ $t("参与单位统编") }}
              </div>
              <div class="col-md-1 col-sm-1 col-lg-1 col-xl-1 pl-0 pr-0" style="color: #999999;">{{ $t("参与时间") }}</div>
              <div class="col-md-1 col-sm-1 col-lg-1 col-xl-1 pl-0 pr-0" style="color: #999999;">{{ $t("参与地点") }}</div>
              <div class="col-md-2 col-sm-2 col-lg-1 col-xl-1 pl-0 pr-0" style="color: #999999;">{{ $t("参与时长") }}</div>
              <div class="col-md-1 col-sm-1 col-lg-1 col-xl-1 pl-0 pr-0" style="color: #999999;">{{ $t("志工证明照片") }}
              </div>
              <div class="col-md-1 col-sm-1 col-lg-1 col-xl-1 pl-0 pr-0 mr-3" style="color: #999999;">{{ $t('活动照片') }}
              </div>
            </div>
            <div style="height: 61.4vh;overflow-y: auto;" class="parent" @scroll="handleScroll">
              <div v-for="(item, index) in zgzminfoList" :key="index"
                class="col-md-12 pt-1 pb-1  pl-0 pr-0 yuanjiao d-flex justify-content-between align-items-center"
                :style="{ backgroundColor: index % 2 === 0 ? 'white' : '#F6F4F0' }">
                <div class="col-md-1 ellipsis col-sm-1 col-lg-1 col-xl-1 pl-0 pr-0 ml-3" style="color: #999999;">{{
                  item.organization_name }}</div>
                <div class="col-md-1 col-sm-2 col-lg-1 col-xl-1 pl-0 pr-0 " style="color: #999999;">{{
                  item.business_number }}
                </div>
                <div class="col-md-1 col-sm-1 col-lg-1 col-xl-1 pl-0 pr-0" style="color: #999999;">{{ item.join_year
                  }}-{{
                    item.join_month }}-{{ item.join_day }}</div>
                <div class="col-md-1 col-sm-1 col-lg-1 col-xl-1 pl-0 pr-0" style="color: #999999;">{{ item.address }}
                </div>
                <div class="col-md-2 col-sm-2 col-lg-1 col-xl-1 pl-0 pr-0" style="color: #999999;">{{ item.time_length
                  }}
                </div>
                <img v-if="item.activity_image.length" class="col-md-1 col-sm-1 col-lg-1 col-xl-1 pl-0 pr-0 curpo"
                  style="height: 100px;object-fit: contain;" :src="item.activity_image[0]" alt="" />
                <div v-if="!item.activity_image.length" class="col-md-1 col-sm-1 col-lg-1 col-xl-1 pl-0 pr-0 mr-3 curpo"
                  style="height: 100px;"></div>
                <img v-if="item.certificate_image.length"
                  class="col-md-1 col-sm-1 col-lg-1 col-xl-1 pl-0 pr-0 mr-3 curpo"
                  style="height: 100px;object-fit: contain;" :src="item.certificate_image[0]" alt="" />
                <div v-if="!item.certificate_image.length"
                  class="col-md-1 col-sm-1 col-lg-1 col-xl-1 pl-0 pr-0 mr-3 curpo" style="height: 100px;"></div>
              </div>
              <!-- 加载更多提示 -->
              <div class="d-flex justify-content-center">
                <!-- <div v-if="isLoading" class="loading">{{ $t("加载中...") }}</div> -->
                <div v-if="zgzminfoList.length == 0">
                  <empty></empty>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
       <!-- 手机版样式 -->
      <div v-if="tyugyu == 0" style="height: 79.4vh;overflow-y: auto;" class="parent pcnone" @scroll="handleScroll">
        <div class="pl-3 pt-3 pb-3 mb-3 baise yuanjiao col-md-12 pl-0 pr-3 d-flex justify-content-between asdfas">
          <div class="d-flex  pl-0 pr-0 align-items-center  pt-3 pb-3 ckqbb p_top"
            style="background: #FFFAF0;border-radius:12px;width: 49.4%;justify-content: space-between;position: relative;overflow: hidden;">
            <div class="d-flex align-items-center ml-3" style="z-index: 999;">
              <img class="mr-2 img" style="width: 50px;font-size: calc(9px + 0.4vw);font-weight: 500;height: 50px;"
                src="@/img/daohangl/pic_hssy_haorenbi@2x.png" alt="" />
              <div class="">
                <div style="font-size: calc(6px + 0.4vw);" class="sjbxzt">{{ $t("平台总发行好人币") }}</div>
                <div style="font-size: calc(15px + 0.4vw);font-weight: 700;">{{ user.
                  good_coin ? user.
                    good_coin.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') : '0' }}</div>
              </div>
            </div>

            <div class="bgheight"
              style="width: 100%;position: absolute;bottom: 0;height: 60%;background-color: #FDDDC9;display: flex;justify-content: flex-end;">
              <img style="height: 100%;width: 70%;" src="@/img/pic_kapianzhuangshi@2x.png" alt="" />
            </div>
          </div>
          <div class="d-flex  pl-0 pr-0 align-items-center  pt-3 pb-3 ckqbb p_top"
            style="background: #FFFAF0;border-radius:12px;width: 49.4%;justify-content: space-between;position: relative;overflow: hidden;">
            <div class="d-flex align-items-center ml-3 " style="z-index: 999;">
              <img class="mr-2 img" style="width: 50px;font-size: calc(9px + 0.4vw);font-weight: 500;height: 50px;"
                src="@/img/daohangl/pic_hssy_tanbi@2x.png" alt="" />
              <div class="">
                <div style="font-size: calc(6px + 0.4vw);" class="sjbxzt">{{ $t("平台总发行碳币") }}</div>
                <div style="font-size: calc(15px + 0.4vw);font-weight: 700;">{{ user.
                  carbon_coin ? user.
                    carbon_coin.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') : '0' }}</div>
              </div>
            </div>
            <div class="bgheight"
              style="width: 100%;position: absolute;bottom: 0;height: 60%;background-color: #FDDDC9;display: flex;justify-content: flex-end;">
              <img style="height: 100%;width: 70%;" src="@/img/pic_kapianzhuangshi@2x.png" alt="" />
            </div>
          </div>
        </div>
       
        <div class="">
          <div class="d-flex align-items-center" style="vertical-align: middle;justify-content: space-between;" >
            <div class="d-flex">
              <div @click="hsjl()" class="pt-2 pb-2 baise pl-3 pr-3 curpo sjbxzt"
              :style="{ 'background': xuanz ? '' : '#FFFAF0' }"
              style="font-size: calc(7px + 0.4vw);border-radius: 12px 0 0 0;">{{ $t("好事记录") }}
            </div>
            <div @click="zgzm()" class="pt-2 pb-2 baise pl-3 pr-3 curpo sjbxzt"
              :style="{ 'background': !xuanz ? '' : '#FFFAF0' }"
              style="font-size: calc(7px + 0.4vw);border-radius: 0 12px 0 0;">{{ $t("志工证明") }}
            </div>
            </div>
            
            <div class="d-flex pl-0 pr-0" style="justify-content: space-between;">
              <b-form-datepicker v-model="cfsj" aria-controls="example-input" class="a1 pl-2 pr-2"
                :label-no-date-selected="$t('请选择日期')" style="width: 100%;padding: 0px 10px;margin-left: 3px;"
                @context="onContext"></b-form-datepicker>
            </div>
          </div>
          <div v-if="xuanz" class="baise p-3 col-md-12 pl-0 pr-0" style="border-radius: 0 0 12px 12px;margin-top: -2px">
            <!-- <div class="col-md-12 pt-3 pb-3 mb-3 pl-0 pr-0 yuanjiao d-flex justify-content-between align-items-center"
              style="background: #FFFAF0;">
              <div class="col-md-1 ellipsis col-sm-1 col-lg-1 col-xl-1 pl-0 pr-0 ml-3" style="color: #999999;">{{
                $t("好事记录") }}</div>
              <div class="col-md-1 col-sm-2 col-lg-1 col-xl-1 pl-0 pr-0 " style="color: #999999;">{{ $t("日期") }}
              </div>
              <div class="col-md-1 col-sm-1 col-lg-1 col-xl-1 pl-0 pr-0" style="color: #999999;">{{ $t("好人积分") }}</div>
              <div class="col-md-1 col-sm-1 col-lg-1 col-xl-1 pl-0 pr-0" style="color: #999999;">{{ $t("碳积分") }}</div>
              <div class="col-md-2 col-sm-2 col-lg-1 col-xl-1 pl-0 pr-0" style="color: #999999;">{{ $t("活动单位") }}</div>
              <div class="col-md-1 ellipsis col-sm-1 col-lg-1 col-xl-1 pl-0 pr-0" style="color: #999999;">{{ $t("活动地点")
                }}
              </div>
              <div class="col-md-1 col-sm-1 col-lg-1 col-xl-1 pl-0 pr-0 mr-3" style="color: #999999;">{{ $t('活动花絮') }}
              </div>
            </div> -->
            <div >
              <div v-for="(item, index) in infoList" :key="index"
                class="col-md-12 pt-1 pb-1 pl-3 pr-3 yuanjiao d-flex flexcolumn  top justify-content-between align-items-center"
                :style="{ backgroundColor: index % 2 === 0 ? '#F6F4F0' : '#F6F4F0' }">
                <div
                  class=" borders ellipsis  col-lg-1 col-xl-1 pl-0 pr-0 P_left ml-3 text-overflow-hover sjbzt"
                  :title="item.name" @mouseenter="showFullText = true" @mouseleave="showFullText = false">{{ item.name ?
                    item.name : '--' }}</div>
                <div class=" d-flex top justify-content-between flexstart" style="width: 100%;">
                  <div style="width: 50%;">
                    <div class="sjbzt">
                      {{ $t("日期") }}
                    </div>
                    <div class="col-lg-1 col-xl-1 pl-0 pr-0 sjbxzt small_top" style="color: #999999;">
                      {{
                        timeFilter(item.create_time) }}</div>
                  </div>
                  <div style="width: 50%;">
                    <div class="sjbzt">
                      {{ $t("好人积分") }}
                    </div>
                    <div class=" col-lg-1 col-xl-1 pl-0 pr-0 d-flex sjbxzt small_top"
                      style="align-items: center;">
                      <img class="mr-2" style="width: 20px;font-weight: 500;height: 20px;"
                        src="@/img/daohangl/pic_hssy_haorenbi@2x.png" alt="" />
                      {{ item.good_coin > 0 ? '+' : '' }}
                      {{
                        item.good_coin }}
                    </div>
                  </div>
                </div>
                <div class="top">
                  <div class="sjbzt">
                    {{ $t("碳积分") }}
                  </div>
                  <div class="col-md-1 col-sm-1 col-lg-1 col-xl-1 pl-0 pr-0 d-flex sjbxzt small_top"
                    style="align-items: center;">
                    <img class="mr-2" style="width: 20px;font-weight: 500;height: 20px;"
                      src="@/img/daohangl/pic_hssy_tanbi@2x.png" alt="" />
                    {{ item.carbon_coin > 0 ? '+' : ''
                    }} {{
                      item.carbon_coin }}
                  </div>
                </div>
                <div class="top1">
                  <div class="sjbzt">
                    {{ $t("活动单位") }}
                  </div>
                  <div class="ellipsis col-lg-1 col-xl-1 pl-0 pr-0 sjbxzt small_top sjbxzt">{{
                    item.organization_name
                  }}</div>
                </div>
                <div class="top1">
                  <div class="sjbzt">
                    {{ $t("活动地点") }}
                  </div>
                  <div class="col-md-1 ellipsis col-sm-1 col-lg-1 col-xl-1 pl-0 pr-0 small_top sjbxzt">{{ item.address ?
                    item.address :
                    '--'
                    }}</div>
                </div>
                <div class="top1">
                  <div class="sjbzt">
                    {{ $t("活动花絮") }}
                  </div>
                  <img v-if="item.image" @click="tiaozhu(item.category, item.activity_id)"
                    class="col-md-1 col-sm-1 col-lg-1 col-xl-1 pl-0 pr-0  curpo small_top"
                    style="height: 100px;object-fit: contain;" :src="item.image" alt="" />
                  <div v-else @click="tiaozhu(item.category, item.activity_id)"
                    class="col-md-1 col-sm-1 col-lg-1 col-xl-1 pl-0 pr-0  curpo small_top" style="height: 100px;"></div>
                </div>

              </div>
              <!-- 加载更多提示 -->
              <div class="d-flex justify-content-center">
                <!-- <div v-if="isLoading" class="loading">{{ $t("加载中...") }}</div> -->
                <div v-if="infoList.length == 0">
                  <empty></empty>
                </div>
              </div>
            </div>
          </div>
          <div v-else class="baise p-3 col-md-12 pl-0 pr-0" style="border-radius: 0 0 12px 12px;margin-top: -2px">
            <!-- <div class="col-md-12 pt-3 pb-3 mb-3 pl-0 pr-0 yuanjiao d-flex justify-content-between align-items-center"
              style="background: #FFFAF0;">
              <div class="col-md-1 ellipsis col-sm-1 col-lg-1 col-xl-1 pl-0 pr-0 ml-3" style="color: #999999;">{{
                $t("参与单位") }}</div>
              <div class="col-md-1 col-sm-2 col-lg-1 col-xl-1 pl-0 pr-0 " style="color: #999999;">{{ $t("参与单位统编") }}
              </div>
              <div class="col-md-1 col-sm-1 col-lg-1 col-xl-1 pl-0 pr-0" style="color: #999999;">{{ $t("参与时间") }}</div>
              <div class="col-md-1 col-sm-1 col-lg-1 col-xl-1 pl-0 pr-0" style="color: #999999;">{{ $t("参与地点") }}</div>
              <div class="col-md-2 col-sm-2 col-lg-1 col-xl-1 pl-0 pr-0" style="color: #999999;">{{ $t("参与时长") }}</div>
              <div class="col-md-1 col-sm-1 col-lg-1 col-xl-1 pl-0 pr-0" style="color: #999999;">{{ $t("志工证明照片") }}
              </div>
              <div class="col-md-1 col-sm-1 col-lg-1 col-xl-1 pl-0 pr-0 mr-3" style="color: #999999;">{{ $t('活动照片') }}
              </div>
            </div> -->
            <div  class="parent" >
              <div v-for="(item, index) in zgzminfoList" :key="index"
                class="col-md-12 pt-1 pb-1  pl-3 pr-3 yuanjiao d-flex top justify-content-between align-items-center flexcolumn"
                :style="{ backgroundColor: index % 2 === 0 ? '#F6F4F0' : '#F6F4F0' }">
                <div
                  class="col-md-1 ellipsis sjbzt col-sm-1 col-lg-1 col-xl-1 pl-0 pr-0 ml-3 borders P_left text-overflow-hover"
                  style="color: #999999;">{{ item.organization_name }}</div>
                <div class=" d-flex top justify-content-between flexstart" style="width: 100%;">

                  <div style="width: 50%;">
                    <div class="sjbzt">
                      {{ $t("参与单位统编") }}
                    </div>
                    <div class="col-md-1 col-sm-2 col-lg-1 col-xl-1 pl-0 pr-0 small_top sjbxzt "
                      style="color: #999999;">{{
                        item.business_number }}
                    </div>
                  </div>
                  <div style="width: 50%;">
                    <div class="sjbzt">
                      {{ $t("参与时间") }}
                    </div>
                    <div class="col-md-1 col-sm-1 col-lg-1 col-xl-1 pl-0 pr-0 small_top sjbxzt" style="color: #999999;">
                      {{
                        item.join_year
                      }}-{{
                        item.join_month }}-{{ item.join_day }}</div>
                  </div>
                </div>
                <div class="top1">
                  <div class="sjbzt">
                    {{ $t("参与地点") }}
                  </div>
                  <div class="col-md-1 col-sm-1 col-lg-1 col-xl-1 pl-0 pr-0 small_top" style="color: #999999;">{{
                    item.address }}
                  </div>
                </div>
                <div class="top1">
                  <div class="sjbzt">
                    {{ $t("参与时长") }}
                  </div>
                  <div class="col-md-2 col-sm-2 col-lg-1 col-xl-1 pl-0 pr-0 small_top sjbxzt" style="color: #999999;">{{
                    item.time_length
                  }}
                  </div>
                </div>
                <div class="top1">
                  <div class="sjbzt">
                    {{ $t("志工证明照片") }}
                  </div>
                  <img v-if="item.activity_image.length"
                    class="small_top col-md-1 col-sm-1 col-lg-1 col-xl-1 pl-0 pr-0 curpo"
                    style="height: 100px;object-fit: contain;" :src="item.activity_image[0]" alt="" />
                  <div v-if="!item.activity_image.length"
                    class=" small_top col-md-1 col-sm-1 col-lg-1 col-xl-1 pl-0 pr-0 mr-3 curpo small_top sjbxzt"
                    style="height: 100px;"></div>
                </div>
                <div class="top1">
                  <div class="sjbzt">
                    {{ $t("活动照片") }}
                  </div>
                  <img v-if="item.certificate_image.length"
                    class="small_top col-md-1 col-sm-1 col-lg-1 col-xl-1 pl-0 pr-0 mr-3 curpo"
                    style="height: 100px;object-fit: contain;" :src="item.certificate_image[0]" alt="" />
                  <div v-if="!item.certificate_image.length"
                    class="small_top col-md-1 col-sm-1 col-lg-1 col-xl-1 pl-0 pr-0 mr-3 curpo" style="height: 100px;">
                  </div>
                </div>
              </div>
              <div class="d-flex justify-content-center">
                <div v-if="zgzminfoList.length == 0">
                  <empty></empty>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <ckxq v-if="tyugyu == 1" :ckidss="idss" :istype="isT" @custom='custom'></ckxq>
  </div>

</template>

<script>
import ckxq from "@/components/jkhd/wmuc/ckxq.vue";
import empty from "@/components/empty/empty.vue";
import { timeFilter, formatTimestamp } from "@/utils/common.js";

import { activ_Lis, merit_list, voluntee } from "@/request/aapi.js";
import SkeletonScreen from "./SkeletonScreen/hsgdbSkeletonScreen.vue";
export default {
  components: {
    SkeletonScreen,
    empty,
    ckxq,
  },
  data() {
    return {
      fullText: '这是一个非常长的文本，当鼠标悬浮时，我希望能够展示全部的内容。',
      truncatedText: '这是一个非常长的文本...', // 这里放置截断后的文本  
      showFullText: false, // 控制是否展示全部文本  
      zgzminfoList: [],
      xuanz: true,
      tyugyu: 0,
      user: {},
      isT: null,
      idss: "",
      dataLoaded: false,
      list: [{}, {}, {}, {}],
      nianDropdownOpen: false,
      youDropdownOpen: false,
      riDropdownOpen: false,
      nian: "",
      you: "",
      ri: "",
      // 获取币
      bicon: {},
      page: 1,
      pages: 1,
      year: null,
      month: null,
      day: null,

      infoList: [],

      isLoading: false, // 是否正在加载数据
      noMore: false, // 是否没有更多数据
      noMores: false,
      cfsj: "",
    };
  },
  created() { },
  mounted() {
    if (localStorage.getItem("token")) {
      this.getmerit();
    } else {
      this.$router.push("/login");
    }
    this.getCoin();
    // 模拟数据加载
  },
  computed: {},
  methods: {
    hsjl() {
      this.page = 1;
      this.infoList = [];
      this.xuanz = true;
      this.getmerit();
    },
    zgzm() {
      this.pages = 1;
      this.zgzminfoList = [];
      this.xuanz = false;
      this.voluntee();
    },
    voluntee() {
      let data = {
        page: this.pages,
        year: this.year,
        month: this.month,
        day: this.day,
      };
      voluntee(data).then((res) => {
        if (res.code == 200) {
          if (this.pages == 1) {
            if (this.pages * res.data.per_page > res.data.total) {
              this.zgzminfoList = res.data.data;
              this.noMores = true;
              return;
            }
          } else {
            if (this.pages * res.data.per_page > res.data.total) {
              this.zgzminfoList = [...this.zgzminfoList, ...res.data.data];
              this.noMores = true;

              return;
            }
            this.zgzminfoList = [...this.zgzminfoList, ...res.data.data];
            this.pages++;
            this.isLoading = false;
          }
          console.log(this.zgzminfoList, "iiiiiiiiiiiiiiiii");
        }
      });
    },
    custom() {
      this.tyugyu = 0;
    },
    tiaozhu(e, v) {
      if (e == 4) {
        this.tyugyu = 1;
        this.isT = 1;
        this.idss = v;
      } else {
        this.$router.push("/hdongdetails?id=" + v);
      }
    },
    getclear() {
      this.cfsj = "";
      this.year = "";
      this.month = "";
      this.day = "";
      this.page = 1;
      this.getmerit();
    },
    onContext(ctx) {
      if (new Date(ctx.selectedYMD).getTime()) {
        this.getDa(new Date(ctx.selectedYMD).getTime());
      }
    },

    // 返回年月日
    getDa(timestamp) {
      const date = new Date(timestamp);
      const year = date.getFullYear();
      const month = date.getMonth() + 1;
      const day = date.getDate();

      this.year = year;
      this.month = month;
      this.day = day;
      this.page = 1;
      this.pages = 1;
      if (this.xuanz) {
        this.getmerit();
      } else {
        this.voluntee();
      }
    },
    // 滚动
    handleScroll(event) {
      const target = event.target;
      const scrollDistance =
        target.scrollHeight - target.scrollTop - target.clientHeight;

      if (scrollDistance < 10 && !this.isLoading && !this.noMore) {
        this.isLoading = true;
        this.page += 1;
        this.getmerit();
      }
    },
    // 时间转换
    timeFilter(stringTime) {
      return formatTimestamp(stringTime * 1000);
    },
    // 获取币
    getCoin() {
      let data = {
        is_coin: 1,
        is_user: 1,
      };
      activ_Lis(data).then((res) => {
        if (res.code == 200) {
          this.dataLoaded = true;
          this.bicon = res.data.coin;
          this.user = res.data.user;
        }
      });
    },
    // 获取功德榜列表
    // getmerit() {
    //   let data = {
    //     page: this.page,
    //     year: this.year,
    //     month: this.month,
    //     day: this.day,
    //   };
    //   merit_list(data).then((res) => {
    //     console.log(res.data.data);
    //     if (res.code == 200) {
    //       if (this.page == 1) {
    //         if (this.page * res.data.per_page > res.data.total) {
    //           this.infoList = res.data.data;
    //           this.noMore = true;
    //           return;
    //         }
    //       } else {
    //         if (this.page * res.data.per_page > res.data.total) {
    //           this.infoList = [...this.infoList, ...res.data.data];
    //           this.noMore = true;

    //           return;
    //         }
    //         this.infoList = [...this.infoList, ...res.data.data];
    //         this.page++;
    //         this.isLoading = false;
    //       }
    //       console.log(this.infoList);
    //     }
    //   });
    // },
    getmerit() {
      let data = {
        page: this.page,
        year: this.year,
        month: this.month,
        day: this.day,
      };
      merit_list(data).then((res) => {
        console.log(res.data.data);
        if (res.code == 200) {
          if (this.page * res.data.per_page >= res.data.total) {
            if (this.page == 1) {
              this.infoList = res.data.data;
            } else {
              this.infoList = [...this.infoList, ...res.data.data];
            }
            this.noMore = true;
          } else {
            this.infoList = [...this.infoList, ...res.data.data];
            this.isLoading = false;
          }
          // if (this.page == 1) {
          //   if (this.page * res.data.per_page > res.data.total) {
          //     this.infoList = res.data.data;
          //     this.noMore = true;
          //     return;
          //   }
          // } else {
          //   if (this.page * res.data.per_page > res.data.total) {
          //     this.infoList = [...this.infoList, ...res.data.data];
          //     this.noMore = true;

          //     return;
          //   }
          //   this.infoList = [...this.infoList, ...res.data.data];
          //   this.page++;
          //   this.isLoading = false;
          // }
          console.log(this.infoList);
        }
      });
    },
    nianDropdown() {
      this.nianDropdownOpen = !this.nianDropdownOpen;
      // 如果需要，可以在这里添加Bootstrap Dropdown的额外处理逻辑
    },
    youDropdown() {
      this.youDropdownOpen = !this.youDropdownOpen;
      // 如果需要，可以在这里添加Bootstrap Dropdown的额外处理逻辑
    },
    riDropdown() {
      this.riDropdownOpen = !this.riDropdownOpen;
      // 如果需要，可以在这里添加Bootstrap Dropdown的额外处理逻辑
    },
    nianxuanz(e) {
      this.nian = e;
      this.nianDropdownOpen = false;
    },
    youxuanz(e) {
      this.you = e;
      this.youDropdownOpen = false;
    },
    rixuanz(e) {
      this.ri = e;
      this.riDropdownOpen = false;
    },
  },
};
</script>

<style scoped>
@media screen and (max-width: 991px) {
  .asdfas{
    margin-top: 1rem !important;
  }
}
@media screen and (max-width: 1200px) {
  ::v-deep .b-form-btn-label-control.form-control>.btn {
    padding: 0px;
  }

  ::v-deep .form-control {
    border: none !important;
  }

  .margin_r {
    margin: 10px
  }

  .none {
    display: none;
  }

  .flexstart {
    align-items: flex-start
  }

  .flexcolumn {
    flex-direction: column;
    align-items: flex-start !important;

  }

  .small_top {
    margin-top: 5px;
  }

  .top {

    margin-top: 10px;
  }

  .top1 {

    margin-top: 15px;
  }

  .pcnone {
    display: block !important;
  }

  .p_top {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }

  .img {
    width: calc(25px + 0.5vw) !important;
    height: calc(25px + 0.5vw) !important;
  }

  .bgheight {
    height: 50% !important;
  }

  .sjbzt {
    font-weight: bolder;
    font-size: calc(11px + 0.4vw) !important;
  }

  .borders {
    width: 100% !important;
    border-bottom: 1px solid #999999;
  }

  .P_left {
    margin-left: 0px !important;
  }

  .sjbxzt {
    font-size: calc(9px + 0.4vw) !important;
  }
}

.pcnone {
  display: none;
}

.text-overflow-hover {
  width: 200px;
  /* 设置一个固定的宽度以触发文本截断 */
  padding: 10px;
  /* 可选的内边距 */
  cursor: pointer;
  /* 鼠标悬浮时显示为手指形状 */
  transition: all 0.2s ease;
  /* 添加过渡效果 */
}

.a1 {
  background-color: #ffebd2;
  border: none;
  font-size: 12px;
  color: #000;
  padding: 5px;
  border-radius: 12px 12px 0 0;
}

.dcbd:hover {
  transform: scale(1.2);
}

.xlcd {
  display: block;
  background: #ffebd2;
  border: none;
}

.dydpd {
  padding-top: calc(4px + 0.4vw);
  padding-bottom: calc(4px + 0.4vw);
  padding-left: calc(10px + 0.5vw);
  padding-right: calc(10px + 0.5vw);
}

.zhankai {
  background: #ffebd2;
  text-align: center;
  border-radius: 12px 12px 0 0;
  border: none;
  font-weight: 400;
  font-size: calc(6px + 0.4vw);
  color: #1a1a1a;
}

.dropdown-item:hover {
  background-color: #ffebd2;
  color: #b66d00;
  border-radius: 12px;
}

/* 隐藏垂直滚动条 */
.parent::-webkit-scrollbar {
  display: none;
}
</style>