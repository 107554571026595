<template>
  <div>
   
    <div v-show="txbiaod == 1">
      <skeleton-screen v-if="!dataLoaded"></skeleton-screen>
      <div v-if="dataLoaded" class="col-md-12 col-sm-12 col-lg-12 col-xl-12 pl-0 pr-0 d-flex justify-content-between afasdf">
        <div class="col-sm-8 pl-0 pr-0 col-xl-9 yuanjiao pb-3 lieb" @scroll="handleScroll" style="height: 89.5vh;overflow-y: auto;" v-if="screenWidth>1200">
          <div class="baise pl-0 pr-0 col-sm-12 yuanjiao">
            <div class="col-sm-12 pl-0 pr-0 d-flex align-items-center pt-3 pb-3 pl-5 flexcolumn"
              style="background: #FFEAB8;border-radius: 12px 12px 0px 0px;">
              <div class="mr-3 sjbzt" style="font-size: calc(8px + 0.4vw);font-weight: 570;">{{ $t("当前活动即将结束") }} </div>
              <div class="d-flex align-items-center top" style="font-size: calc(15px + 0.4vw);font-weight: 700;">
                <div class="beijys mr-2 pt-3 pb-3 pl-3 pr-3 yuanjiao "
                  style="background: linear-gradient(to bottom, #FFD672 50%, #FFFFFF 50%);">{{ countdown }}</div>
                <div class="txjj mr-2 sjbxzt">{{ $t("天") }}</div>
                <div class="beijys mr-2 pt-3 pb-3 pl-3 pr-3 yuanjiao"
                  style="background: linear-gradient(to bottom, #FFD672 50%, #FFFFFF 50%);">{{ shi }}</div>
                <div class="txjj mr-2 sjbxzt">{{ $t("时") }}</div>
                <div class="beijys mr-2 pt-3 pb-3 pl-3 pr-3 yuanjiao"
                  style="background: linear-gradient(to bottom, #FFD672 50%, #FFFFFF 50%);">{{ fen }}</div>
                <div class="txjj mr-2 sjbxzt">{{ $t("分") }}</div>
                <div class="beijys mr-2 pt-3 pb-3 pl-3 pr-3 yuanjiao"
                  style="background: linear-gradient(to bottom, #FFD672 50%, #FFFFFF 50%);">{{ miao }}</div>
                <div class="txjj sjbxzt">{{ $t("秒") }}</div>
              </div>
            </div>
            
            <div class="col-sm-12 p-3 position-relative d-flex align-items-center" @mouseenter="showButtons = true"
              @mouseleave="showButtons = false"  @touchstart="touchStart"  @touchend="touchEnd">
              <div v-show="showButtons" class="col-auto position-absolute pl-0 pr-0 dcbd curpo xsdann"
                style="z-index: 99999;left: 0;" @click="move(-1)" v-if="screenWidth>1200">
                <img style="width: calc(16px + 0.5vw);" src="@/img/details/icon_jklb_xiangzuo@2x.png" alt="" />
              </div>
              <div class="d-flex curpo justify-content-between col-sm-12 pl-0 pr-0 "
                v-for="(item, index) in visibleItems" :key="index"  style="overflow: auto;width: 100%;">
                <div class="col-sm-12 col-lg-8 col-xl-8 pl-0 pr-0 ml-5 m-left">
                  <div @click="huodxiangq(item)" class="d-flex justify-content-between pl-0 pr-0 col-sm-12">
                    <div class="col-sm-8 col-md-8 col-lg-8 col-xl-8 pl-0 pr-0">
                      <div style="font-size: calc(12px + 0.4vw);font-weight: 700;" class="font_big">{{ item.name }}
                      </div>
                      <div class="d-flex align-items-center mt-3 mb-3 ">
                        <div class="d-flex mr-2 align-items-center"
                          style="font-size: calc(6px + 0.4vw);color: #666666;">
                          <img style="width: calc(7px + 0.5vw);height: calc(7px + 0.5vw);"
                            src="@/img/daohangl/icon_hssy_hdlb_tiewen@2x.png" alt="" class="img" />
                          <div class="ml-1 sjbxzt">{{ item.moment_num ?
                            item.moment_num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') : '0' }}</div>
                        </div>
                        <div class="d-flex align-items-center" style="font-size: calc(6px + 0.4vw);color: #666666;">
                          <img style="width: calc(7px + 0.5vw);height: calc(7px + 0.5vw);"
                            src="@/img/daohangl/icon_hssy_hdlb_renyuan@2x.png" alt="" class="img" />
                          <div class="ml-1 sjbxzt">{{ item.member_num ?
                            item.member_num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') : '0' }}</div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div @click="huodxiangq(item)"
                    style="color: #666666;font-size: calc(6px + 0.4vw);line-height: calc(15px + 0.4vw);" class="sjbxzt">
                    {{ item.description }}
                  </div>
                  <button v-if="item.is_member == 0" @click="chanyughd(item.id, item.name)"
                    class="pl-4 pr-4 pt-3 pb-3 yuanjiao curpo ckqbb mt-4 sjbxzt" 
                    style="font-size: calc(6px + 0.4vw);background: #FFD672;border: none;">
                    {{ $t("参与当前活动") }}
                  </button>
                  <button v-else class="pl-4 pr-4 pt-3 pb-3 yuanjiao curpo ckqbb mt-4 sjbxzt"
                    style="font-size: calc(6px + 0.4vw);background:  #E5E5E5;border: none;">
                    {{ $t("已报名") }}
                  </button>
                </div>
               
                <div class="col-md-4 col-lg-3 col-xl-2 tpiand mr-5 pl-0 pr-0">
                  <img  class="yuanjiao" style="width: 100%;height: 170px;object-fit: cover" :src="item.image" alt="" >
                </div>
              </div>

              <div v-show="showButtons" class="col-auto position-absolute pl-0 pr-0 dcbd curpo xsdann"
                style="z-index: 99999;right: 0;" @click="move(1)" v-if="screenWidth>1200">
                <img style="width: calc(16px + 0.5vw);" src="@/img/details/icon_jklb_xiangyou@2x.png" alt="" />
              </div>
            </div>
        
          </div>

          <div class="col-md-12 mt-3 pl-0 pr-0">
            <div class="col-md-12 yuanjiao pl-0 pr-0 parent"
              style="font-size: calc(7px + 0.4vw);line-height: calc(15px + 0.5vw);">
              <div class="col-md-12 d-flex pl-0 pr-0 justify-content-between flexcolumn_c">
                <div class="d-flex justify-content-between align-items-center">
                  <div class="d-flex pl-0 pr-0">
                    <div @click="fun(0)" class="pt-3 pb-3 curpo qieh sjbzt"
                      :style="{ 'background': show == 0 ? '#FFFFFF' : '#FFFAF0', 'font-weight': show == 0 ? '700' : '500', 'color': show == 0 ? '' : '#999999' }">
                      {{ $t("所有活动") }}</div>
                    <div @click="fun(1)" class="pt-3 pb-3 curpo qieh sjbzt"
                      :style="{ 'background': show == 1 ? '#FFFFFF' : '#FFFAF0', 'font-weight': show == 1 ? '700' : '500', 'color': show == 1 ? '' : '#999999' }">
                      {{ $t("志工活动") }}</div>
                  </div>
                  <div v-if="screenWidth <= 1200">
                    <img src="../../img/rili.png" alt="" class="imgri" v-b-toggle.sidebar-1>
                  </div>
                </div>
                <div class="col-sm-4 pl-0 pr-0 position-relative d-flex align-items-center" style="margin-bottom: -1%">
                  <input @input="shukchange" v-model="inputmodel"
                    class=" sjbxzt form-control sxbj col-md-12 inppt pr-5 sjbxzt input" type="text"
                    :placeholder="$t('搜寻')" plaintext='true' aria-label="Username" aria-describedby="basic-addon1"
                    style="width: 100%;">
                  <img style="right: 20px;width: calc(10px + 0.5vw);" class="position-absolute curpo sbxfbd"
                    src="@/img/daohangl/icon_hssy_sousuo@2x.png" alt="" :class="img" />
                </div>
              </div>
              <div class="pl-3 pr-3 col-md-12 baise pt-3 pb-2 pl-0 pr-0 gdt"
                style="border-radius: 0 0 12px 12px;">
                <div v-show="show == 0" v-for="(item, index) in list" :key="index" style="background: #F6F4F0;"
                  class="yuanjiao  curpo  mb-3 ckqbb pl-3 pr-3 pt-3 pb-3">
                  <div class="d-flex justify-content-between align-items-center">
                    <div @click="huodxiangq(item)"
                      class="d-flex justify-content-between align-items-center">
                      <img class="mr-2 yuanjiao"
                        style="width: calc(35px + 0.5vw);height: calc(35px + 0.5vw);object-fit: cover;"
                        :src="item.image" alt="" />
                      <div>
                        <div class="sjbzt" style="font-size: calc(7px + 0.4vw);font-weight: 700;">
                          {{ item.name }}
                        </div>
                        <div class="d-flex align-items-center top">
                          <div class="d-flex mr-3 align-items-center"
                            style="font-size: calc(5px + 0.4vw);color: #666666;">
                            <img style="width: calc(8px + 0.5vw);height: calc(7px + 0.5vw);"
                              src="@/img/daohangl/icon_hssy_hdlb_tiewen@2x.png" alt="" class="img" />
                            <div class="ml-1 sjbxzt">{{ item.moment_num ?
                              item.moment_num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') : '0' }}</div>
                          </div>
                          <div class="d-flex align-items-center" style="font-size: calc(5px + 0.4vw);color: #666666;">
                            <img style="width: calc(8px + 0.5vw);height: calc(7px + 0.5vw);"
                              src="@/img/daohangl/icon_hssy_hdlb_renyuan@2x.png" alt="" class="img" />
                            <div class="ml-1 sjbxzt">{{ item.member_num ?
                              item.member_num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') : '0' }}</div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <button v-if="item.is_member == 0" :disabled="isLoading" @click="chanyughd(item.id, item.name)"
                      class="pl-3 pr-3 pt-1 pb-1 yuanjiao ckqbb annys sjbxzt"
                      style="font-size: calc(5px + 0.4vw);background: #FFD672;border: none;">
                      {{ $t("参加活动") }}
                    </button>
                    <button v-else :disabled="isLoading" class=" sjbxzt pl-3 pr-3 pt-1 pb-1 yuanjiao ckqbb curpo annys"
                      style="font-size: calc(5px + 0.4vw);background: #E5E5E5;border: none;">
                      {{ $t("已报名") }}
                    </button>
                  </div>
                  <div class="mt-2 slhzs sjbxzt" style="font-size: calc(5px + 0.4vw);">
                    {{ item.description }}
                  </div>
                </div>
                <div v-show="show == 1" v-for="(item, index) in list" :key="index" style="background: #F6F4F0;"
                  class="yuanjiao  curpo  mb-3 ckqbb pl-3 pr-3 pt-3 pb-3">
                  <div class="d-flex justify-content-between align-items-center">
                    <div class="d-flex justify-content-between align-items-center">
                      <img @click="huodxiangq(item.id)" class="mr-2 yuanjiao"
                        style="width: calc(35px + 0.5vw);height: calc(35px + 0.5vw);object-fit: cover;"
                        :src="item.image" alt="" />
                      <div>
                        <div class="sjbzt" style="font-size: calc(5px + 0.4vw);">
                          {{ item.name }}
                        </div>
                        <div class="d-flex align-items-center top">
                          <div class="d-flex mr-2 align-items-center"
                            style="font-size: calc(4px + 0.4vw);color: #666666;">
                            <img style="width: calc(7px + 0.5vw);height: calc(7px + 0.5vw);"
                              src="@/img/daohangl/icon_hssy_hdlb_tiewen@2x.png" alt="" class="img" />
                            <div class="sjbxzt">{{ item.moment_num }}</div>
                          </div>
                          <div class="d-flex align-items-center" style="font-size: 12px;color: #666666;">
                            <img style="width: calc(7px + 0.5vw);height: calc(7px + 0.5vw);"
                              src="@/img/daohangl/icon_hssy_hdlb_renyuan@2x.png" alt="" class="img" />
                            <div class="sjbxzt">{{ item.member_num }}</div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <button v-if="item.is_member == 0" :disabled="isLoading" @click="chanyughd(item.id, item.name)"
                      class="pl-3 pr-3 pt-1 pb-1 yuanjiao ckqbb annys sjbxzt"
                      style="font-size: calc(5px + 0.4vw);background: #FFD672;border: none;">
                      {{ $t("参加活动") }}
                    </button>
                    <button v-else :disabled="isLoading" class="pl-3 pr-3 pt-1 pb-1 yuanjiao sjbxzt ckqbb curpo annys"
                      style="font-size: calc(5px + 0.4vw);background: #E5E5E5;border: none;">
                      {{ $t("已报名") }}
                    </button>
                  </div>
                  <div class="mt-2 slhzs sjbxzt" style="font-size: calc(5px + 0.4vw);">
                    {{ item.description }}
                  </div>
                </div>
              </div>

            </div>
          </div>
        </div>
        <div class=" yuanjiao pb-2 lieb" v-else @scroll="handleScroll" style="height: 79.5vh;overflow-y: auto;width: 100%;">
          <div class="baise pl-0 pr-0 col-sm-12 yuanjiao">
            <div class="col-sm-12 pl-0 pr-0 d-flex align-items-center pt-3 pb-3 pl-5 flexcolumn"
              style="background: #FFEAB8;border-radius: 12px 12px 0px 0px;">
              <div class="mr-3 sjbzt" style="font-size: calc(8px + 0.4vw);font-weight: 570;">{{ $t("当前活动即将结束") }} </div>
              <div class="d-flex align-items-center top" style="font-size: calc(15px + 0.4vw);font-weight: 700;">
                <div class="beijys mr-2 pt-3 pb-3 pl-3 pr-3 yuanjiao "
                  style="background: linear-gradient(to bottom, #FFD672 50%, #FFFFFF 50%);">{{ countdown }}</div>
                <div class="txjj mr-2 sjbxzt">{{ $t("天") }}</div>
                <div class="beijys mr-2 pt-3 pb-3 pl-3 pr-3 yuanjiao"
                  style="background: linear-gradient(to bottom, #FFD672 50%, #FFFFFF 50%);">{{ shi }}</div>
                <div class="txjj mr-2 sjbxzt">{{ $t("时") }}</div>
                <div class="beijys mr-2 pt-3 pb-3 pl-3 pr-3 yuanjiao"
                  style="background: linear-gradient(to bottom, #FFD672 50%, #FFFFFF 50%);">{{ fen }}</div>
                <div class="txjj mr-2 sjbxzt">{{ $t("分") }}</div>
                <div class="beijys mr-2 pt-3 pb-3 pl-3 pr-3 yuanjiao"
                  style="background: linear-gradient(to bottom, #FFD672 50%, #FFFFFF 50%);">{{ miao }}</div>
                <div class="txjj sjbxzt">{{ $t("秒") }}</div>
              </div>
            </div>
            
            <div class="col-sm-12 p-3 position-relative d-flex align-items-center" @mouseenter="showButtons = true"
              @mouseleave="showButtons = false"  @touchstart="touchStart"  @touchend="touchEnd">
              <div v-show="showButtons" class="col-auto position-absolute pl-0 pr-0 dcbd curpo xsdann"
                style="z-index: 99999;left: 0;" @click="move(-1)" v-if="screenWidth>1200">
                <img style="width: calc(16px + 0.5vw);" src="@/img/details/icon_jklb_xiangzuo@2x.png" alt="" />
              </div>
              <div class="d-flex curpo justify-content-between col-sm-12 pl-0 pr-0 "
                v-for="(item, index) in visibleItems" :key="index"  style="overflow: auto;width: 100%;">
                <div class=" pl-0 pr-0 ml-5 m-left">
                  <div @click="huodxiangq(item)" class="d-flex justify-content-between pl-0 pr-0 col-sm-12">
                    <div class="col-sm-8 col-md-8 col-lg-8 col-xl-8 pl-0 pr-0">
                      <div style="font-size: calc(12px + 0.4vw);font-weight: 700;" class="font_big">{{ item.name }}
                      </div>
                      <div class="d-flex align-items-center mt-3 mb-3 ">
                        <div class="d-flex mr-2 align-items-center"
                          style="font-size: calc(6px + 0.4vw);color: #666666;">
                          <img style="width: calc(7px + 0.5vw);height: calc(7px + 0.5vw);"
                            src="@/img/daohangl/icon_hssy_hdlb_tiewen@2x.png" alt="" class="img" />
                          <div class="ml-1 sjbxzt">{{ item.moment_num ?
                            item.moment_num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') : '0' }}</div>
                        </div>
                        <div class="d-flex align-items-center" style="font-size: calc(6px + 0.4vw);color: #666666;">
                          <img style="width: calc(7px + 0.5vw);height: calc(7px + 0.5vw);"
                            src="@/img/daohangl/icon_hssy_hdlb_renyuan@2x.png" alt="" class="img" />
                          <div class="ml-1 sjbxzt">{{ item.member_num ?
                            item.member_num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') : '0' }}</div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div @click="huodxiangq(item)"
                    style="color: #666666;font-size: calc(6px + 0.4vw);line-height: calc(15px + 0.4vw);" class="sjbxzt">
                    {{ item.description }}
                  </div>
                  <button v-if="item.is_member == 0" @click="chanyughd(item.id, item.name)"
                    class="pl-4 pr-4 pt-3 pb-3 yuanjiao curpo ckqbb mt-4 sjbxzt" 
                    style="font-size: calc(6px + 0.4vw);background: #FFD672;border: none;">
                    {{ $t("参与当前活动") }}
                  </button>
                  <button v-else class="pl-4 pr-4 pt-3 pb-3 yuanjiao curpo ckqbb mt-2 sjbxzt"
                    style="font-size: calc(6px + 0.4vw);background:  #E5E5E5;border: none;">
                    {{ $t("已报名") }}
                  </button>
                </div>
               
                <div class=" mr-2 pl-0 pr-0" style="width: 40%;">
                  <img  class="yuanjiao" style="width: 100%;height: 100%;object-fit: cover" :src="item.image" alt="" >
                </div>
              </div>

              <div v-show="showButtons" class="col-auto position-absolute pl-0 pr-0 dcbd curpo xsdann"
                style="z-index: 99999;right: 0;" @click="move(1)" v-if="screenWidth>1200">
                <img style="width: calc(16px + 0.5vw);" src="@/img/details/icon_jklb_xiangyou@2x.png" alt="" />
              </div>
            </div>
        
          </div>

          <div class="col-md-12 mt-3 pl-0 pr-0">
            <div class="col-md-12 yuanjiao pl-0 pr-0 parent"
              style="font-size: calc(7px + 0.4vw);line-height: calc(15px + 0.5vw);">
              <div class="col-md-12 d-flex pl-0 pr-0 justify-content-between" style="width: 100%;">
                <div class="d-flex justify-content-between align-items-center" style="width: 35%;">
                  <div class="d-flex pl-0 pr-0">
                    <div @click="fun(0)" class="pt-3 pb-3 curpo qieh sjbzt"
                    style="width: 70px;"
                      :style="{ 'background': show == 0 ? '#FFFFFF' : '#FFFAF0', 'font-weight': show == 0 ? '700' : '500', 'color': show == 0 ? '' : '#999999' }">
                      {{ $t("所有活动") }}</div>
                    <div @click="fun(1)" class="pt-3 pb-3 curpo qieh sjbzt"
                    style="width: 70px;"
                      :style="{ 'background': show == 1 ? '#FFFFFF' : '#FFFAF0', 'font-weight': show == 1 ? '700' : '500', 'color': show == 1 ? '' : '#999999' }">
                      {{ $t("志工活动") }}</div>
                  </div>
                  <div v-if="screenWidth <= 1200">
                    <img src="../../img/rili.png" alt="" class="imgri" v-b-toggle.sidebar-1>
                  </div>
                </div>
                <div class=" pl-0 pr-0 position-relative d-flex align-items-center yuanjiao" style="width: 47%;margin-bottom: -2%;">
                  <input @input="shukchange" v-model="inputmodel"
                    class=" sjbxzt form-control sxbj col-md-12 inppt pr-5 sjbxzt input" type="text"
                    :placeholder="$t('搜寻')" plaintext='true' aria-label="Username" aria-describedby="basic-addon1"
                    style="width: 100%;">
                  <img style="right: 20px;width: calc(10px + 0.5vw);" class="position-absolute curpo sbxfbd"
                    src="@/img/daohangl/icon_hssy_sousuo@2x.png" alt="" :class="img" />
                </div>
              </div>
              <div class="pl-3 pr-3 col-md-12 baise pt-3 pb-2 pl-0 pr-0 gdt"
                style="border-radius: 0 0 12px 12px;">
                <div v-show="show == 0" v-for="(item, index) in list" :key="index" style="background: #F6F4F0;"
                  class="yuanjiao  curpo  mb-3 ckqbb pl-3 pr-3 pt-3 pb-3">
                  <div class="d-flex justify-content-between align-items-center">
                    <div @click="huodxiangq(item)"
                      class="d-flex justify-content-between align-items-center">
                      <img class="mr-2 yuanjiao"
                        style="width: calc(35px + 0.5vw);height: calc(35px + 0.5vw);object-fit: cover;"
                        :src="item.image" alt="" />
                      <div>
                        <div class="sjbzt" style="font-size: calc(7px + 0.4vw);font-weight: 700;">
                          {{ item.name }}
                        </div>
                        <div class="d-flex align-items-center top">
                          <div class="d-flex mr-3 align-items-center"
                            style="font-size: calc(5px + 0.4vw);color: #666666;">
                            <img style="width: calc(8px + 0.5vw);height: calc(7px + 0.5vw);"
                              src="@/img/daohangl/icon_hssy_hdlb_tiewen@2x.png" alt="" class="img" />
                            <div class="ml-1 sjbxzt">{{ item.moment_num ?
                              item.moment_num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') : '0' }}</div>
                          </div>
                          <div class="d-flex align-items-center" style="font-size: calc(5px + 0.4vw);color: #666666;">
                            <img style="width: calc(8px + 0.5vw);height: calc(7px + 0.5vw);"
                              src="@/img/daohangl/icon_hssy_hdlb_renyuan@2x.png" alt="" class="img" />
                            <div class="ml-1 sjbxzt">{{ item.member_num ?
                              item.member_num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') : '0' }}</div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <button v-if="item.is_member == 0" :disabled="isLoading" @click="chanyughd(item.id, item.name)"
                      class="pl-3 pr-3 pt-1 pb-1 yuanjiao ckqbb annys sjbxzt"
                      style="font-size: calc(5px + 0.4vw);background: #FFD672;border: none;">
                      {{ $t("参加活动") }}
                    </button>
                    <button v-else :disabled="isLoading" class=" sjbxzt pl-3 pr-3 pt-1 pb-1 yuanjiao ckqbb curpo annys"
                      style="font-size: calc(5px + 0.4vw);background: #E5E5E5;border: none;">
                      {{ $t("已报名") }}
                    </button>
                  </div>
                  <div class="mt-2 slhzs sjbxzt" style="font-size: calc(5px + 0.4vw);">
                    {{ item.description }}
                  </div>
                </div>
                <div v-show="show == 1" v-for="(item, index) in list" :key="index" style="background: #F6F4F0;"
                  class="yuanjiao  curpo  mb-3 ckqbb pl-3 pr-3 pt-3 pb-3">
                  <div class="d-flex justify-content-between align-items-center">
                    <div class="d-flex justify-content-between align-items-center">
                      <img @click="huodxiangq(item)" class="mr-2 yuanjiao"
                        style="width: calc(35px + 0.5vw);height: calc(35px + 0.5vw);object-fit: cover;"
                        :src="item.image" alt="" />
                      <div>
                        <div class="sjbzt" style="font-size: calc(5px + 0.4vw);">
                          {{ item.name }}
                        </div>
                        <div class="d-flex align-items-center top">
                          <div class="d-flex mr-2 align-items-center"
                            style="font-size: calc(4px + 0.4vw);color: #666666;">
                            <img style="width: calc(7px + 0.5vw);height: calc(7px + 0.5vw);"
                              src="@/img/daohangl/icon_hssy_hdlb_tiewen@2x.png" alt="" class="img" />
                            <div class="sjbxzt">{{ item.moment_num }}</div>
                          </div>
                          <div class="d-flex align-items-center" style="font-size: 12px;color: #666666;">
                            <img style="width: calc(7px + 0.5vw);height: calc(7px + 0.5vw);"
                              src="@/img/daohangl/icon_hssy_hdlb_renyuan@2x.png" alt="" class="img" />
                            <div class="sjbxzt">{{ item.member_num }}</div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <button v-if="item.is_member == 0" :disabled="isLoading" @click="chanyughd(item.id, item.name)"
                      class="pl-3 pr-3 pt-1 pb-1 yuanjiao ckqbb annys sjbxzt"
                      style="font-size: calc(5px + 0.4vw);background: #FFD672;border: none;">
                      {{ $t("参加活动") }}
                    </button>
                    <button v-else :disabled="isLoading" class="pl-3 pr-3 pt-1 pb-1 yuanjiao sjbxzt ckqbb curpo annys"
                      style="font-size: calc(5px + 0.4vw);background: #E5E5E5;border: none;">
                      {{ $t("已报名") }}
                    </button>
                  </div>
                  <div class="mt-2 slhzs sjbxzt" style="font-size: calc(5px + 0.4vw);">
                    {{ item.description }}
                  </div>
                </div>
              </div>

            </div>
          </div>
        </div>
        <!--手机版日历弹出 -->
        <b-sidebar id="sidebar-1" shadow  bg-variant="light" 	ref="sidebar">
            <div class="col-sm-4 col-xl-3  pl-0 pr-0">
              <div class="baise yuanjiao pt-3 pb-3 col-md-12" style="height: 84.5vh;text-align: center;">
                <div class="btn-group d-flex justify-content-between align-items-center mb-3">
                  <img class="dcbd curpo" @click="prevMonth"
                    style="width: calc(20px + 0.6vw);height: calc(20px + 0.6vw);"
                    src="@/img/details/icon_rl_shangyige@2x.png" alt="" />
                  <div style="font-size: calc(13px + 0.5vw);font-weight: 700;">{{ currentYear }}
                    {{ $t("年") }}{{ currentMonth }}{{ $t("月") }}</div>
                  <img class="dcbd curpo" @click="nextMonth"
                    style="width: calc(20px + 0.6vw);height: calc(20px + 0.6vw);"
                    src="@/img/details/icon_rl_xiayige@2x.png" alt="" />
                </div>
                <div class="d-flex justify-content-around">
                  <div style="font-size: calc(9px + 0.4vw);color: #666666;" v-for="day in daysOfWeek" :key="day">
                    {{ day }}
                  </div>
                </div>
                <table class="table table-bordered" style="border: none;">
                  <tbody>
                    <tr class="pl-0 pr-0" v-for="(week, index) in weeksInMonth"
                      style="text-align: center;font-size: calc(8px +0.5vw);font-weight: 600;" :key="index">
                      <td class="pl-0 pr-0 pt-0 pb-0" style="border: none;" v-for="day in week" :key="day.date">
                        <div @click="xuanzdys(day.day, currentMonth)"
                          :style="{ 'background': days == day.day && currentMonth == xwac ? '#FAB1AB' : '', 'font-size': 'calc(10px + 0.4vw)' }"
                          :class="[day.day ? 'mt-2 curpo rqxz pt-1 pb-2 yuanjiao' : 'mt-2 pt-1 pb-2 yuanjiao']" id="">
                          {{ day.day }}
                          <div v-if="isIdEqual(day.day)" class="hongdian"></div>
                          <div v-else class="hongdians"></div>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
                <img style="width: 58%;height: 18%;margin-top: 65%;object-fit: contain;" src="@/img/dayu.png" alt="">
              </div>
          </div>
        </b-sidebar>
        <!-- pc版日历 -->
        <div class="col-sm-4 col-xl-3 pl-3 pl-0 pr-0" v-if="screenWidth>1200">
          <div class="baise yuanjiao pt-3 pb-3 col-md-12" style="height: 87.5vh;text-align: center;">
            <div class="btn-group d-flex justify-content-between align-items-center mb-3">
              <img class="dcbd curpo" @click="prevMonth" style="width: calc(20px + 0.6vw);height: calc(20px + 0.6vw);"
                src="@/img/details/icon_rl_shangyige@2x.png" alt="" />
              <div style="font-size: calc(13px + 0.5vw);font-weight: 700;">{{ currentYear }}
                {{ $t("年") }}{{ currentMonth }}{{ $t("月") }}</div>
              <img class="dcbd curpo" @click="nextMonth" style="width: calc(20px + 0.6vw);height: calc(20px + 0.6vw);"
                src="@/img/details/icon_rl_xiayige@2x.png" alt="" />
            </div>
            <div class="d-flex justify-content-around">
              <div style="font-size: calc(9px + 0.4vw);color: #666666;" v-for="day in daysOfWeek" :key="day">
                {{ day }}
              </div>
            </div>
            <table class="table table-bordered" style="border: none;">
              <tbody>
                <tr class="pl-0 pr-0" v-for="(week, index) in weeksInMonth"
                  style="text-align: center;font-size: calc(8px +0.5vw);font-weight: 600;" :key="index">
                  <td class="pl-0 pr-0 pt-0 pb-0" style="border: none;" v-for="day in week" :key="day.date">
                    <div @click="xuanzdys(day.day, currentMonth)"
                      :style="{ 'background': days == day.day && currentMonth == xwac ? '#FAB1AB' : '', 'font-size': 'calc(10px + 0.4vw)' }"
                      :class="[day.day ? 'mt-2 curpo rqxz pt-1 pb-2 yuanjiao' : 'mt-2 pt-1 pb-2 yuanjiao']" id="">
                      {{ day.day }}
                      <div v-if="isIdEqual(day.day)" class="hongdian"></div>
                      <div v-else class="hongdians"></div>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
            <img style="width: 58%;height: 18%;margin-top: 80%;object-fit: contain;" src="@/img/dayu.png" alt="">
          </div>
        </div>

      </div>
    </div>
    <cjhdbd class="mb-2 afasdf" style="height: 88.6vh;" v-if="txbiaod == 2" ref="childRef" @custom="custom" @hdfh="hdfh">
    </cjhdbd>
  </div>
</template>

<script>
import SkeletonScreen from "./SkeletonScreen/hddjs.vue";
import cjhdbd from "./cjhdbd.vue";
import { expiry, calendar, activity_list } from "@/request/hshd/index.js";
export default {
  components: {
    SkeletonScreen,
    cjhdbd,
  },
  data() {
    return {
      startX: 0,
      endX: 0,
      inputmodel: "",
      txbiaod: 1,
      countdown: "0",
      shi: "0",
      fen: "0",
      miao: "0",
      intervalId: null,
      targetTime: "",
      showButtons: false,
      // 当前显示的元素索引范围
      startIndex: 0,
      // 假设一次显示5个元素
      visibleCount: 1,
      show: 0,
      allItems: [],
      days: "",
      currentYear: new Date().getFullYear(),
      currentMonth: new Date().getMonth() + 1,
      daysOfWeek: [
        this.$t("Sun"),
        this.$t("Mon"),
        this.$t("Tue"),
        this.$t("Wed"),
        this.$t("Thu"),
        this.$t("Fri"),
        this.$t("Sat"),
      ],
      daysInMonth: [],
      xwac: "",
      list: [],
      isLoading: false,
      dataLoaded: false,
      modalShow: false,
      page: 1,
      last_page: "",
      listlast_page:"",
      current_page: "",
      hongdianlist: [],
      screenWidth: 0
    };
  },
  created() {
    this.liebsjian();
    this.daysInMonth = this.getDaysInMonth(this.currentYear, this.currentMonth);
    this.calendar();
    this.activity_list();
  },
  mounted() {
    this.updateScreenWidth(); // 初始化宽度
    window.addEventListener('resize', this.updateScreenWidth);
    console.log(this.$route, "route");
    if (this.$route.query.i) {
      this.txbiaod = this.$route.query.i;
    }
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.updateScreenWidth);
    if (this.intervalId) {
      clearInterval(this.intervalId);
    }
  },
  computed: {
    uniqueItems() {
      return [...new Set(this.allItems)];
    },
    // 计算当前应该显示的元素列表
    visibleItems() {
      return this.allItems.slice(
        this.startIndex,
        this.startIndex + this.visibleCount
      );
    },
    weeksInMonth() {
      let weeks = [];
      let done = false;
      let done1 = true;
      let date = 1;
      const a = this.getDayOfWeek(
        this.currentYear + "-" + this.currentMonth + "-" + "01"
      );
      while (!done) {
        let week = [];
        if (done1) {
          for (let i = 0; i < 7; i++) {
            done1 = false;
            if (date > this.daysInMonth.length) {
              done = true;
              break;
            }
            if (i < a) {
              week.push({ date: "", day: "" });
            } else {
              week.push(this.daysInMonth[date - 1]);
              date++;
            }
          }
        } else {
          for (let i = 0; i < 7; i++) {
            if (date > this.daysInMonth.length) {
              done = true;
              break;
            }
            week.push(this.daysInMonth[date - 1]);
            date++;
          }
        }
        weeks.push(week);
      }
      // console.log(weeks);
      return weeks;
    },
  },
  methods: {
    touchStart(event) {
      this.startX = event.touches[0].clientX;
    },
    touchEnd(event) {
      this.endX = event.changedTouches[0].clientX;
      this.handleSwipe();
    },
    handleSwipe() {
      const distance = this.endX - this.startX;
      if (distance > 50) {
        this.move(-1); // 向右滑动
      } else if (distance < -50) {
        this.move(1); // 向左滑动
      }
    },
    updateScreenWidth() {
      this.screenWidth = window.innerWidth;
    },
    getDayOfWeek(dateString) {
      const days = [
        "星期日",
        "星期一",
        "星期二",
        "星期三",
        "星期四",
        "星期五",
        "星期六",
      ];
      const date = new Date(dateString);
      const dayIndex = date.getDay();
      // return days[dayIndex];
      return dayIndex;
    },
    chanyughd(e, v) {
      this.txbiaod = 2;
      setTimeout(() => {
        this.$refs.childRef.msg(e, v);
      }, 1000);
    },
    shukchange() {
      this.activity_list();
    },
    handleScroll(event) {
      const target = event.target;
      const scrollDistance =
        target.scrollHeight - target.scrollTop - target.clientHeight;
      if (scrollDistance < 10) {
        // 当滚动条距离底部小于10px时，认为是滚动到底部
        if (this.pagelist < this.listlast_page) {
          this.pagelist += 1;
          this.activity_list();
        }
      }
    },
    async activity_list(e) {
      const { data,code } = await activity_list({
        page: this.pagelist,
        volunteer: this.show,
        keywords: this.inputmodel,
        date: e,
        status: 1
      });
      if(code==200){
        this.dataLoaded = true;
      }
      this.listlast_page = data.last_page;
       this.list=[...this.list, ...data.data]
    },
    isIdEqual(e) {
      return this.hongdianlist.find((item) => item === e) !== undefined;
    },
    async calendar() {
      const { data } = await calendar({
        year: this.currentYear,
        month: this.currentMonth,
      });
      this.hongdianlist = data.data;
    },
    async liebsjian() {
      const { data, code } = await expiry({
        page: this.page,
      });
      if (code == 200) {
        this.current_page = data.current_page;
        this.last_page = data.last_page;

        if (data) {
          if (data.data[0]?.end_time) {
            this.targetTime = data.data[0]?.end_time;
          }

          this.allItems = [...this.allItems, ...data.data];
        }

        this.updateCountdown();
        this.intervalId = setInterval(this.updateCountdown, 1000);
      }
    },
    huodxiangq(e) {
      let a = e.identity == 2 ? 'groups' : 'enterprises'
      this.$router.push('/activity/' + a + '/' + e.slug + '/' + e.id);
    },
    custom() {
      this.txbiaod = 1;
    },
    hdfh(e) {
      this.txbiaod = 1;
      for (let i = 0; i < this.list.length; i++) {
        if (this.list[i].id == e) {
          this.list[i].is_member = 1;
        }
      }
      for (let i = 0; i < this.allItems.length; i++) {
        if (this.allItems[i].id == e) {
          this.allItems[i].is_member = 1;
        }
      }
    },
    updateCountdown() {
      const now = new Date().getTime();
      const distance = this.targetTime * 1000 - now;
      if (distance < 0) {
        this.countdown = "0";
        this.shi = "0";
        this.fen = "0";
        this.miao = "0";
        return;
      }

      const days = this.pad(Math.floor(distance / (1000 * 60 * 60 * 24)));
      const hours = this.pad(
        Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60))
      );
      const minutes = this.pad(
        Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60))
      );
      const seconds = this.pad(Math.floor((distance % (1000 * 60)) / 1000));

      this.countdown = `${days}`;
      this.shi = `${hours}`;
      this.fen = `${minutes}`;
      this.miao = `${seconds}`;
    },
    pad(num) {
      return ("0" + num).slice(-2);
    },
    // 切换显示元素的方法
    move(direction) {
      if (direction === -1 && this.startIndex > 0) {
        this.startIndex--;
        this.page--;
      } else if (direction === 1) {
        if (this.page < this.last_page) {
          this.page++;
          this.startIndex++;
          this.liebsjian();
        }
      }
    },
    // 点击加入当前活动
    bulletframe() { },
    fun(e) {
      this.pagelist=1;
      this.show = e;
      this.days = "";
      this.xwac = "";
      this.activity_list();
    },
    xuanzdys(e, v) {
      if (this.days == e) {
        this.days = "";
        this.xwac = "";
        this.activity_list("");
      } else {
        this.days = e;
        this.xwac = v;
        this.activity_list(
          this.currentYear + "-" + this.currentMonth + "-" + e
        );
      }
      this.$refs.sidebar.hide()
    },
    prevMonth() {
      if (this.currentMonth === 1) {
        this.currentYear--;
        this.currentMonth = 12;
      } else {
        this.currentMonth--;
      }
      this.daysInMonth = this.getDaysInMonth(
        this.currentYear,
        this.currentMonth
      );
      this.calendar();
    },
    nextMonth() {
      if (this.currentMonth === 12) {
        this.currentYear++;
        this.currentMonth = 1;
      } else {
        this.currentMonth++;
      }
      this.daysInMonth = this.getDaysInMonth(
        this.currentYear,
        this.currentMonth
      );
      this.calendar();
    },
    getDaysInMonth(year, month) {
      let days = [];
      let numDays = new Date(year, month, 0).getDate();

      for (let day = 1; day <= numDays; day++) {
        days.push({
          date: day,
          day: day,
        });
      }
      return days;
    },
  },
};
</script>

<style scoped>
@media screen and (max-width: 999px) {
  .tpiand {
    display: none;
  }
  .afasdf{
    margin-top: 1rem !important;
  }
  
}

@media screen and (max-width: 1200px) {
  .afasdf::-webkit-scrollbar {
  display: none !important;
}
  .sadsxczxc{
    width: 100% !important;
  }
 ::v-deep .b-sidebar > .b-sidebar-header{
    background-color: #ffffff;
  }
  ::v-deep .b-sidebar > .b-sidebar-body{
    background-color: #ffffff;
  }

  .flexcolumn {
    flex-direction: column;
    align-items: flex-start !important;
    padding: 2rem 1rem !important;
  }

  .top {
    margin-top: 0.5rem;
  }

  .sjbzt {
    font-size: calc(11px + 0.4vw) !important;
  }

  .sjbxzt {
    font-size: calc(9px + 0.4vw) !important;
  }

  .input {
    flex: 1;
    border-radius: 3px !important;
    padding: 18px !important;
    border: 1px solid #d1d7e0 !important;
  }

  .col-sm-4 {
    max-width: 100% !important;
  }

  .font_big {
    font-size: calc(14px + 0.4vw) !important;
  }

  .img {
    width: calc(12px + 0.5vw) !important;
    height: calc(12px + 0.5vw) !important;
  }

  .imgri {
    width: calc(20px + 0.5vw) !important;
    height: calc(20px + 0.5vw) !important;
    margin-left: 0.5rem
  }

  .m-left {
    margin-left: 0px !important;
  }
}

.txjj {
  font-size: calc(7px + 0.4vw);
  font-weight: 500;
}

.dcbd:hover {
  transform: scale(1.2);
}

.ckqbb:hover {
  transform: scale(1.001);
  /* 悬浮时放大1.1倍 */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.rqxz:hover {
  transform: scale(1.001);
  /* 悬浮时放大1.1倍 */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  background-color: #fcd7d5;
  border-radius: 12px;
}

.icon-container {
  display: inline-block;
  background-color: #f0f8ff;
  /* 浅黄色背景 */
  padding: 20px;
  /* 根据需要调整内边距 */
  border-radius: 8px;
  /* 可选：圆角边框 */
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  /* 可选：阴影效果 */
}

/* 隐藏水平滚动条 */
.lieb::-webkit-scrollbar {
  display: none;
}

.icon {
  position: relative;
  width: 100px;
  /* 根据需要调整宽度 */
  height: 100px;
  /* 根据需要调整高度 */
  background-color: #ffffff;
  /* 白色背景 */
  border: 2px solid #f0f8ff;
  /* 淡黄色边框 */
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: 'cursive';
  /* 手写体风格的字体（需要确保该字体在系统中可用或引入相应的字体文件） */
  font-size: 24px;
  /* 根据需要调整字体大小 */
  color: #000000;
  /* 黑色文字 */
  border-radius: 4px;
  /* 可选：圆角边框 */
}



/* 可选：在图标右下角添加小文字（这里以“00”为例，但您可以根据需要更改） */
.icon-container .small-text {
  position: absolute;
  bottom: 5px;
  /* 根据需要调整位置 */
  right: 5px;
  /* 根据需要调整位置 */
  font-size: 12px;
  /* 根据需要调整字体大小 */
  color: #000000;
  /* 黑色文字（或根据需要选择其他颜色） */
}

.slhzs {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
  text-overflow: ellipsis;
}

.dcbd:hover {
  transform: scale(1.2);
}

.qieh {
  width: calc(100px + 0.4vw);
  text-align: center;
  border-radius: 12px 12px 0 0;
}

.parent::-webkit-scrollbar {
  display: none;
}

.sxbj {
  padding-top: calc(15px + 0.5vw);
  padding-bottom: calc(15px + 0.5vw);
}

.inppt {
  background: white;
  border: none;
  border-radius: 13px 13px 0 0;
  font-size: calc(7px + 0.4vw);
  padding-top: 22px;
  padding-bottom: 22px;
}

.inppt:hover {
  border: none;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.gdt::-webkit-scrollbar {
  display: none;
}

.hongdian {
  width: 6px;
  height: 6px;
  border-radius: 50%;
  background-color: #ff797a;
  margin: auto;
}

.hongdians {
  width: 6px;
  height: 6px;
  border-radius: 50%;
}
</style>