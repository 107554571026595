import { render, staticRenderFns } from "./ckxq.vue?vue&type=template&id=88d5bf12&scoped=true"
import script from "./ckxq.vue?vue&type=script&lang=js"
export * from "./ckxq.vue?vue&type=script&lang=js"
import style0 from "./ckxq.vue?vue&type=style&index=0&id=88d5bf12&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "88d5bf12",
  null
  
)

export default component.exports