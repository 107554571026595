<template>
  <div class="col-md-12 col-sm-12 col-lg-12 col-xl-12 pl-0 pr-0 lieb" style="height: 88vh;overflow-y: auto;">
    <div v-if="show==0">
      <skeleton-screen v-if="!dataLoaded"></skeleton-screen>
      <div v-if="dataLoaded" class="col-md-12 col-sm-12 col-lg-12 col-xl-12 baise yuanjiao pt-3 pb-3 heggs" style="font-size: calc(7px + 0.4vw);line-height: calc(15px + 0.5vw);">
        <img v-if="dataLoaded" @click="fanhui(0)" class="mt-1 mb-2 curpo dcbd" style="width: calc(25px + 0.4vw);" src="@/img/login/icon_fanhui@2x.png" alt="" />
        <!-- <div class="col-md-12 pl-0 pr-0">故事的开始，来自于三个少年在桥下的一出戏</div>
			<div class="col-md-12 pl-0 pr-0">19岁那年，玮盛、蝌蚪、韦志三位找来和他们一样的逆境少年，在桥下、在公园排练属于「逆风剧团」的第一出戏。</div>
			<div class="col-md-12 pl-0 pr-0">戏剧曾改变三位创办人的生命，所以他们也想让那些不曾被接纳的少年站上舞台，享受来自他人的掌声。当这些少年也体会过被肯定的感觉，也许生命也可以不一样吧？
			</div>
			<div class="col-md-12 pl-0 pr-0">
				「逆风」这块招牌走了九年，如今早已不仅仅是一个剧团，还是一支飞车联队、一个公益行动基地，更是接近2,000位少年在对家庭失望后，愿意再称之为「家」的地方。</div>
			<img class="col-md-12 pl-0 pr-0 mt-3" src="@/img/pic_hstt_gywm_tu@2x.png" alt="" /> -->
        <div style="text-align: center;" v-if="!incon">
          <img class="mb-3" src="@/img/kong.png" alt="">
          <div style="width: 100%;text-align: center;font-size: calc(6px + 0.4vw);color: #999999;">
            {{ $t('此团体没有提供详细捐款介绍')}}~
          </div>
        </div>
        <div v-html="incon" v-else>

        </div>
        <div class="col-sm-8 pl-0 pr-0 ckqbb yuanjiao pt-2 pb-2 jkss" @click="jkkk(3)">
          {{ $t('捐款')}}
        </div>
      </div>
    </div>
    <juank v-if="show == 1" :isid="idds" @custom='custom'></juank>
  </div>
</template>

<script>
import juank from "@/components/jkhd/wmuc/juank.vue";
import { EventBus } from "@/views/event-bus.js";
import { donation_Deta } from "@/request/aapi.js";
import SkeletonScreen from "./SkeletonScreen/xinSkeletonScreen.vue";
export default {
  components: {
    SkeletonScreen,
    juank,
  },
  data() {
    return {
      dataLoaded: false,
      incon: null,
      show: 0,
      idds: "",
    };
  },
  created() {
    this.istype = this.$route.params.isT;
    this.idds = this.$route.params.ckxq;
  },
  mounted() {
    this.getInfo();
  },
  computed: {},
  methods: {
    getInfo() {
      let data = {
        id: this.$route.params.ckxq,
      };
      donation_Deta(data).then((res) => {
        // console.log(res, "详情")
        if (res.code == 200) {
          this.dataLoaded = true;
          this.incon = res.data.info.introduction;
        }
      });
    },
    custom() {
      this.show = 0;
    },
    fanhui(e) {
      this.$router.push("/donation");
      EventBus.$emit("tthdfhs", "1");
    },
    jkkk() {
      if (localStorage.getItem("token")) {
        } else {
          localStorage.removeItem("token");
          localStorage.removeItem("user_id");
          localStorage.setItem("previousRoutePath", this.$router.currentRoute);
          this.$router.push("/login");
          return;
        }
      this.show = 1;
    },
  },
};
</script>

<style scoped>
@media screen and (max-width: 995px) {
  .heggs {
    margin-top: 1rem !important;
  }
}
.dcbd:hover {
  transform: scale(1.2);
}
.jkss {
  background-color: #ffd672;
  text-align: center;
  font-size: calc(7px + 0.4vw);
  width: 20%;
  cursor: pointer;
}

.lieb::-webkit-scrollbar {
  display: none;
}
</style>