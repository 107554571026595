<template>
	<div>
		<div class="col-md-12 baise pt-2 pb-4 sjbzt" style="border-radius: 0 12px 12px 12px;">
			<form>
				<div class="form-group">
					<label style="font-weight: 500;"> <span style="color: red;">*</span>NT$</label>
					<div>
						<!-- @input="inpNum()" -->
						<input style="background: #F6F4F0;border: none;font-size: calc(7px + 0.4vw);"
							v-model="form.amount"  :disabled='zdyi' type="number"
							class="form-control srk pl-3 sjbxzt" :placeholder="$t('请输入')">
						<div class="mt-2">
							<label class="sjbxzt" style="font-weight: 700;">{{ $t('最低金额：') }}{{ low }}</label>
						</div>
						<div class="d-flex align-items-center mt-0 mb-3">

							<div @click="ysxx(item.id, item.name)" class="curpo toms yuanjiao mr-2"
								v-for="(item, index) in list" :key="index"
								style="width: calc(50px + 0.5vw);text-align: center;"
								:style="{ 'background': item.id == ysxxid ? '#FEF7F6' : '#F6F4F0', 'border': item.id == ysxxid ? '1px solid #FAB1AB' : 'none' }">
								<div class="toms-p sjbxzt">{{ item.name }}</div>
							</div>

							<div @click="ysxx(1, 'a1')" class="curpo toms yuanjiao mr-2"
								style="width: calc(50px + 0.5vw);text-align: center;"
								:style="{ 'background': ysxxid == 'a1' ? '#FEF7F6' : '#F6F4F0', 'border': ysxxid == 'a1' ? '1px solid #FAB1AB' : 'none' }">
								<div class="toms-p sjbxzt">{{ $t('自定义') }}</div>
							</div>
						</div>
					</div>
				</div>



				<div class="">
					<label style="font-weight: 500;color: #B5B9C1;">{{ $t('捐款资讯') }}</label>
				</div>
				<div class="form-group">
					<label style="font-weight: 500;"> <span style="color: red;">*</span>{{ $t('姓名') }}</label>
					<div>
						<input style="background: #F6F4F0;border: none;font-size: calc(7px + 0.4vw);"
							v-model="form.name" :disabled='isLoading' type="name" class="form-control sjbxzt srk pl-3"
							:placeholder="$t('输入全名')">
					</div>
				</div>
				<!-- 手机号 -->
				<div class="form-group">
					<label style="font-weight: 500;"> <span style="color: red;">*</span>{{ $t('手机号') }}</label>
					<div>
						<input v-model="form.phone" :disabled='isLoading' @input="validatePhone()" type="text"
							class="form-control srk sjbxzt"
							style="background: #F6F4F0;border: none;font-size: calc(7px + 0.4vw);"
							:placeholder="$t('login.phoneNumberPlaceholder')">
						<small class="form-text sjbxzt" style="color: red;" v-if="phoneError">{{ phoneError }}</small>
					</div>
				</div>
				<!-- 邮箱 -->
				<div class="form-group">
					<label style="font-weight: 500;"> <span style="color: red;">*</span>{{ $t('邮箱') }}</label>
					<div>
						<input v-model="form.email" :disabled='isLoading' @input="emivalidatePhone()" type="text"
							class="form-control srk sjbxzt"
							style="background: #F6F4F0;border: none;font-size: calc(7px + 0.4vw);"
							:placeholder="$t('login.qsryx')">
						<small class="form-text sjbxzt" style="color: red;" v-if="emilError">{{ emilError }}</small>
					</div>
				</div>
				<div class="form-group">
					<label style="font-weight: 500;"> <span style="color: red;">*</span>{{ $t('邮政区号') }}</label>
					<div>
						<input v-model="form.postal_code" :disabled='isLoading' @input="emivalidatePhone()" type="text"
							class="form-control srk sjbxzt"
							style="background: #F6F4F0;border: none;font-size: calc(7px + 0.4vw);"
							:placeholder="$t('login.qsryxemail')">
						<!-- <small class="form-text" style="color: red;" v-if="postal_code">{{ postal_code }}</small> -->
					</div>
				</div>
				<div class="col-sm-12 d-flex pl-0 pr-0 justify-content-between align-item-center">
					<div class=" form-group col-sm-4 pl-0 pr-0">
						<label style="font-weight: 500;"> <span style="color: red;">*</span>{{ $t('城市') }}</label>
						<button class="d-flex ckqbb zhankai  dydpd pl-4 pr-3 pt-2 pb-2 pl-0 pr-0" type="button"
							id="dropdownMenuButton" style="width: 90%;" @click="sjfstoggleDropdown(1)">
							<div class="d-flex align-items-center justify-content-between " style="flex: 1;">
								<div class="mr-1 sjbxzt" style="font-size: calc(6px + 0.4vw);flex: 1;">{{ form.city_id ?
									form_city_id :
									$t('请选择') }}
								</div>
								<div><img style="width: calc(7px + 0.4vw);"
										src="@/img/daohangl/icon_xlcd_xljt@2x (1).png" alt="" /></div>
							</div>
						</button>
						<div class=" yuanjiao xlcd position-absolute  text-center"
							aria-labelledby="dropdownMenuButton" v-show="sjfsisDropdownOpen"
							style="border-radius:7px;background: #FFFAF0;width: 84%;">
							<a class="dropdown-item xuazz yuanjiao mb-2" v-for="(item, index) in guojialist"
								:key="index" :style="{ 'background': form.city_id == item.id ? '#FFD672' : '' }"
								@click="sjfsxuanz(item, 1)" href="#"> <span class="sjbxzt">{{ item.name }}</span> </a>

						</div>


					</div>
					<div class="form-group col-sm-4 pl-0 pr-0">
						<label style="font-weight: 500;"> <span style="color: red;">*</span>{{ $t('地区') }}</label>
						<button class="d-flex ckqbb zhankai  dydpd pl-4 pr-3 pt-2 pb-2 pl-0 pr-0" type="button"
							id="dropdownMenuButton" style="width: 90%;" @click="sjfstoggleDropdown(2)">
							<div class="d-flex align-items-center justify-content-between " style="flex: 1;">
								<div class="mr-1 sjbxzt" style="font-size: calc(6px + 0.4vw);flex: 1;">{{ form.region_id ?
									form_region_id :
									$t('请选择') }}
								</div>
								<div><img style="width: calc(7px + 0.4vw);"
										src="@/img/daohangl/icon_xlcd_xljt@2x (1).png" alt="" /></div>
							</div>
						</button>
						<div class="dropdown-menu yuanjiao xlcd position-absolute gdtyc text-center"
							aria-labelledby="dropdownMenuButton" v-show="sjfsisDropdownOpen2"
							style="border-radius:7px;background: #FFFAF0;width: 85%;margin-left: 3%;max-height: 200px;overflow-y: auto;">
							<a class="dropdown-item xuazz yuanjiao mb-2" v-for="(item, index) in guojialist2"
								:key="index" :style="{ 'background': form.region_id == item.id ? '#FFD672' : '' }"
								@click="sjfsxuanz(item, 2)" href="#"> <span class="sjbxzt">{{ item.name }}</span> </a>

						</div>
						<!-- <div>
							<input style="background: #F6F4F0;border: none;font-size: calc(7px + 0.4vw);"
								v-model="form.region_id" :disabled='isLoading' type="name" class="form-control srk pl-3"
								placeholder="请输入">
						</div> -->
					</div>
					<div class="form-group  col-sm-4 pl-0 pr-0">
						<label style="font-weight: 500;"><span style="color: red;">*</span>{{ $t('区域') }}</label>

						<button class="d-flex ckqbb zhankai  dydpd pl-4 pr-3 pt-2 pb-2 pl-0 pr-0" type="button"
							id="dropdownMenuButton" style="width: 90%;" @click="sjfstoggleDropdown(3)">
							<div class="d-flex align-items-center justify-content-between " style="flex: 1;">
								<div class="mr-1 sjbxzt" style="font-size: calc(6px + 0.4vw);flex: 1;">{{ form.district_id ?
									form_district_id :
									$t('请选择') }}
								</div>
								<div><img style="width: calc(7px + 0.4vw);"
										src="@/img/daohangl/icon_xlcd_xljt@2x (1).png" alt="" /></div>
							</div>
						</button>
						<div class="dropdown-menu yuanjiao xlcd position-absolute gdtyc text-center"
							aria-labelledby="dropdownMenuButton" v-show="sjfsisDropdownOpen3"
							style="border-radius:7px;background: #FFFAF0;width: 84%;margin-left: 3.5%;max-height: 200px;overflow-y: auto;">
							<a class="dropdown-item xuazz yuanjiao mb-2" v-for="(item, index) in guojialist3"
								:key="index" :style="{ 'background': form.district_id == item.id ? '#FFD672' : '' }"
								@click="sjfsxuanz(item, 3)" href="#"> <span class="sjbxzt">{{ item.name }}</span> </a>
						</div>
					</div>
				</div>
				<div class="form-group">
					<label style="font-weight: 500;"> <span style="color: red;">*</span>{{ $t('地址') }}</label>
					<div>
						<input style="background: #F6F4F0;border: none;font-size: calc(7px + 0.4vw);"
							v-model="form.address" :disabled='isLoading' type="name" class="form-control srk pl-3 sjbxzt"
							:placeholder="$t('请输入地址')">
					</div>
				</div>
				<div class="">
					<label style="font-weight: 500;color: #B5B9C1;">{{ $t('补充资讯') }}</label>
				</div>
				<div class="form-group">
					<label style="font-weight: 500;"> <span style="color: red;">*</span>{{ $t('收据方式') }}</label>
					<div>
						<div class="d-flex align-items-center mt-1 mb-2">
							<div @click="form.receipt_type=1,form.company_number='',form.company_name=''" class="curpo toms yuanjiao mr-2 pt-1 pb-1"
								style="width: calc(80px + 0.5vw);text-align: center;font-size: calc(6px + 0.4vw);"
								:style="{ 'background': 1 == form.receipt_type ? '#FEF7F6' : '#F6F4F0', 'border': 1 == form.receipt_type ? '1px solid #FAB1AB' : 'none' }">
								<div class="toms-p sjbxzt">{{ $t('个人收据') }}</div>
							</div>
							<div @click="form.receipt_type=2,form.id_number=''" class="curpo toms yuanjiao mr-2 pt-1 pb-1"
								style="width: calc(80px + 0.5vw);text-align: center;font-size: calc(6px + 0.4vw);"
								:style="{ 'background': 2 == form.receipt_type ? '#FEF7F6' : '#F6F4F0', 'border': 2 == form.receipt_type ? '1px solid #FAB1AB' : 'none' }">
								<div class="toms-p sjbxzt">{{ $t('公司发票') }}</div>
							</div>
						</div>
					</div>
				</div>
				<div v-if="form.receipt_type==1" class="form-group">
					<!-- <label style="font-weight: 500;"> <span style="color: red;">*</span>收据方式</label> -->
					<div class="pl-0 pr-0 position-relative col-md-12">
						<label style="font-weight: 500;"> <span style="color: red;">*</span>{{ $t('身份证号') }}</label>
						<div>
							<input style="background: #F6F4F0;border: none;font-size: calc(7px + 0.4vw);"
								v-model="form.id_number" :placeholder="$t('请输入身份ID')" :disabled='isLoading' type="name"
								class="form-control srk pl-3 sjbxzt">
						</div>
					</div>
				</div>
				<div v-if="form.receipt_type==2" class="form-group">
					<!-- <label style="font-weight: 500;"> <span style="color: red;">*</span>收据方式</label> -->
					<div class="pl-0 pr-0 position-relative col-md-12">
						<label style="font-weight: 500;"> <span style="color: red;">*</span>{{ $t('公司统编') }}</label>
						<div>
							<input :placeholder="$t('请输入公司统编')" style="background: #F6F4F0;border: none;font-size: calc(7px + 0.4vw);"
								v-model="form.company_number" :disabled='isLoading' type="name"
								class="form-control srk pl-3 sjbxzt">
						</div>
					</div>
				</div>
				<div v-if="form.receipt_type==2" class="form-group">
					<!-- <label style="font-weight: 500;"> <span style="color: red;">*</span>收据方式</label> -->
					<div class="pl-0 pr-0 position-relative col-md-12">
						<label style="font-weight: 500;"> <span style="color: red;">*</span>{{ $t('公司抬头') }}</label>
						<div>
							<input :placeholder="$t('请输入公司抬头')" style="background: #F6F4F0;border: none;font-size: calc(7px + 0.4vw);"
								v-model="form.company_name" :disabled='isLoading' type="name"
								class="form-control srk pl-3 sjbxzt">
						</div>
					</div>
				</div>
				<div class="form-group">
					<label style="font-weight: 500;"> <span style="color: red;">*</span>{{ $t('提醒') }}</label>
					<div>
						<b-form-textarea class="sjbxzt" style="border: none;background: #F6F4F0;font-size: calc(7px + 0.4vw);"
							:disabled='isLoading' id="textarea" v-model="form.remind" :placeholder="$t('写下你的心得')"
							rows="3" max-rows="6" no-resize></b-form-textarea>
					</div>
				</div>
				<div class="form-group">
					<label style="font-weight: 500;"> <span style="color: red;">*</span>{{ $t('选择付款方式') }}</label>
					<div>
						<div class="d-flex align-items-center mt-2 mb-2">
							<div @click="jkfs(item.id)" class="curpo toms yuanjiao mr-2 pt-1 pb-1"
								v-for="(item, index) in zflb" :key="index"
								style="width: calc(70px + 0.5vw);text-align: center;"
								:style="{ 'background': item.id == form.pay_type ? '#FEF7F6' : '#F6F4F0', 'border': item.id == form.pay_type ? '1px solid #FAB1AB' : 'none' }">
								<div class="toms-p sjbxzt">{{ item.name }}</div>
							</div>
						</div>
					</div>
				</div>
				<!-- <div class="">
					<label style="font-weight: 500;color: #B5B9C1;">定期定额咨讯确定</label>
				</div>
				<div class="form-group">
					<label style="font-weight: 500;"> <span style="color: red;">*</span>NT$</label>

					<div>
						<input style="background: #F6F4F0;border: none;font-size: calc(7px + 0.4vw);" v-model="momey"
							disabled type="text" class="form-control srk pl-3" placeholder="请输入">
					</div>
				</div> -->
				<div @click="bulletframe()" class="pl-4 pr-4 pt-2 pb-2 yuanjiao ckqbb curpo mt-4"
					style="font-size: calc(6px + 0.4vw);background: #FFD672;width: calc(120px + 0.5vw);text-align: center;">
					{{ $t('捐款') }}
					<span v-if="isLoading" class="spinner-border spinner-border-sm ml-2 sjbzt" role="status"
						aria-hidden="true">
					</span>
				</div>
			</form>
		</div>
	</div>
</template>

<script>
import {
	region_list,
} from "@/request/zhbd/index.js";
import {
	todonate_base,
	donation_pay
} from '../../../request/jkhd/jkhd'
export default {
	components: {

	},
	data() {
		return {
			ysxxid: "1",
			dqysxxid: "1",
			isLoading: false,
			isDropdownOpen: false,

			// 城市
			sjfsisDropdownOpen: false,
			// 地区
			sjfsisDropdownOpen2: false,
			// 区域
			sjfsisDropdownOpen3: false,
			emilError: "",
			phoneError: "",
			zdyi: true,
			form: {
				id: '',
				receipt_type:1,
				postal_code: '',
				amount: '',
				name: "",
				phone: "",
				email: '',
				region_id: '',
				city_id: '',
				district_id: '',
				address: '',
				id_number: '',
				remind: '',
				pay_type: 1,
				// 1:定期，2单笔
				type: 2,
company_name:"",
				company_number:""

			},

			forms: {
				postal_code: '',
			},
			zflb: [{
				id: 1,
				name: this.$t('信用卡')
			}, {
				id: 2,
				name: this.$t('ATM转账')
			}],
			money: '',
			//nts
			list: [],
			low: '',
			max: '',
			//定期
			dqlist: [],

			// 城市
			guojialist: [],
			guojialist2: [],
			guojialist3: [],

			// 选的城市id
			iscity: null,

			form_city_id: '',
			form_region_id: '',
			form_district_id: '',

			timer: null,


		};
	},
	created() {
		this.getCity(0)
	},
	props: {
		ckidss: {
			type: Number,
			default: null,
		}
	},
	mounted() {
		console.log(this.isid)
        this.form.id=this.ckidss
		this.gettodonate()
	},
	computed: {

	},
	methods: {
		// 输入金额时触发
		inpNum() {
			clearTimeout(this.timer)
			this.timer = null
			this.ysxxid = 'a1'


			if (Number(this.form.amount) < Number(this.list[0].name) || Number(this.form.amount) > Number(this.list[
				this.list.length - 1].name)) {

				this.timer = setTimeout(() => {
					this.$bvToast.toast(
						`${this.$t('NT$不能低于')}${this.list[0].name},${this.$t('NT$不能高于')}${this.list[this.list.length - 1].name}`, {
						title: this.$t("login.warning"),
						variant: "danger",
						autoHideDelay: 5000,
					});

					this.form.amount = ''
				}, 3000)

				// this.ysxxid = null
			} else {
				// this.ysxxid = null
			}

		},
		// 城市列表
		async getCity(a,v) {
			await region_list({
				parent_id: v
			}).then(res => {
				// console.log(res, "城市")
				if (res.code == 200) {
					if (a == 0) {
						this.guojialist = res.data.data
						this.form.city_id=res.data.data[0].id
						this.form_city_id=res.data.data[0].name
						this.getCity(1, res.data.data[0].id);
					} else if (a == 1) {
						this.guojialist2 = res.data.data


					} else if (a == 2) {
						this.guojialist3 = res.data.data
					}
				}
			})
		},




		// 捐款基本信息
		gettodonate() {
			todonate_base({organization_id:this.form.id}).then(res => {
				const data = res.data.donation
				this.list = data.init_amount.map((item, index) => {
					return {
						...item,
						id: index + 1,
						name: item,
					}
				});
				this.dqlist = data.init_period.map((item, index) => {
					return {
						...item,
						id: index + 1,
						name: item,
					}
				});
				// this.dqlist.unshift({ id: 1, name: 0 })
				// console.log(this.dqlist, "0000")
				//最低捐款数量
				// if (!this.form.amount || !this.form.period) {
				// 	this.form.amount = this.list[0].name
				// 	this.form.period = this.dqlist[0].name
				// }

				this.low = data.minimum_amount
				this.max = data.maximum_period
				// this.form.period = this.dqlist[0].name

				// this.momey = this.form.amount * this.form.period
			});
		},
		// 点击捐款
		bulletframe() {
			// if (this.form.amount < this.low || this.form.amount > this.list[this.list.length - 1].name) {
			// 	this.$bvToast.toast(`${this.$t('NT$不能低于')}${this.list[0].name},${this.$t('NT$不能高于')}${this.list[this.list.length - 1].name}`, {
			// 		title: this.$t("login.warning"),
			// 		variant: "danger",
			// 		autoHideDelay: 5000,
			// 	});
			// 	return;
			// } else 
			if (this.form.name == '') {
				this.$bvToast.toast(this.$t('请输入姓名'), {
					title: this.$t("login.warning"),
					variant: "danger",
					autoHideDelay: 5000,
				});
				return;
			} else if (this.form.phone == '') {
				this.$bvToast.toast(this.$t('请输入手机号码'), {
					title: this.$t("login.warning"),
					variant: "danger",
					autoHideDelay: 5000,
				});
				return;
			} else if (this.form.email == '') {
				this.$bvToast.toast(this.$t('请输入信箱'), {
					title: this.$t("login.warning"),
					variant: "danger",
					autoHideDelay: 5000,
				});
				return;
			} else if (this.form.postal_code == '') {
				this.$bvToast.toast(this.$t('请输入邮政区号'), {
					title: this.$t("login.warning"),
					variant: "danger",
					autoHideDelay: 5000,
				});
				return;
			} else if (this.form.city_id == '') {
				this.$bvToast.toast(this.$t('请选择居住城市'), {
					title: this.$t("login.warning"),
					variant: "danger",
					autoHideDelay: 5000,
				});
				return;
			} else if (this.form.district_id == '') {
				this.$bvToast.toast(this.$t('请选择区域'), {
					title: this.$t("login.warning"),
					variant: "danger",
					autoHideDelay: 5000,
				});
				return;
			} else if (this.form.region_id == '') {
				this.$bvToast.toast(this.$t('请选择地区'), {
					title: this.$t("login.warning"),
					variant: "danger",
					autoHideDelay: 5000,
				});
				return;
			} else if (this.form.address == '') {
				this.$bvToast.toast(this.$t('请输入地址'), {
					title: this.$t("login.warning"),
					variant: "danger",
					autoHideDelay: 5000,
				});
				return;
			}  else if (this.form.id_number == '' && this.form.receipt_type == 1) {
				this.$bvToast.toast(this.$t('请输入身份ID'), {
					title: this.$t("login.warning"),
					variant: "danger",
					autoHideDelay: 5000,
				});
				return;
			}else if (this.form.company_name == '' && this.form.receipt_type==2) {
				this.$bvToast.toast(this.$t('请输入公司统编'), {
					title: this.$t("login.warning"),
					variant: "danger",
					autoHideDelay: 5000,
				});
				return;
			}else if (this.form.company_number == '' && this.form.receipt_type==2) {
				this.$bvToast.toast(this.$t('请输入公司抬头'), {
					title: this.$t("login.warning"),
					variant: "danger",
					autoHideDelay: 5000,
				});
				return;
			} else if (this.form.remind == '') {
				this.$bvToast.toast(this.$t('请输入心得'), {
					title: this.$t("login.warning"),
					variant: "danger",
					autoHideDelay: 5000,
				});
				return;
			}
			this.isLoading = true;


			// 模拟登录逻辑  
			setTimeout(() => {
				donation_pay(this.form).then(res => {
					// console.log(res, "捐款")
					if (res.code == 200) {
						// this.$bvToast.toast(res.msg, {
						// 	title: this.$t('login.notice'),
						// 	autoHideDelay: 2000,
						// 	delay: 5000,
						// 	appendToast: true,
						// 	variant: "success",
						// });
						this.isLoading = false; // 处理完成后关闭转圈  
						window.location.href = 'https://api.gddao.com/web/pay/submit?order_no=' + res.data.data.order_no; 
						this.$emit('custom', 0);

					} else {
						this.$bvToast.toast(res.msg, {
							title: this.$t('login.notice'),
							autoHideDelay: 2000,
							delay: 5000,
							appendToast: true,
							variant: "success",
						});
						this.isLoading = false; // 处理完成后关闭转圈 
					}
				})


			}, 2000);





		},
		xuanz(e) {
			this.form.jhuxa = e.id
			this.iscity = e.name
			this.isDropdownOpen = false
		},
		toggleDropdown() {
			this.isDropdownOpen = !this.isDropdownOpen;
			// 如果需要，可以在这里添加Bootstrap Dropdown的额外处理逻辑  
		},
		sjfsxuanz(e, b) {
			if (b == 1) {
				this.form.city_id = e.id
				this.form_city_id = e.name
				this.sjfsisDropdownOpen = false
				this.getCity(1,e.id)

			} else if (b == 2) {
				this.form.district_id=''
				this.form.region_id = e.id
				this.form_region_id = e.name
				this.sjfsisDropdownOpen2 = false
				this.getCity(2,e.id)
			} else if (b == 3) {
				this.form.district_id = e.id
				this.form_district_id = e.name
				this.sjfsisDropdownOpen3 = false

			}

		},
		sjfstoggleDropdown(a) {
			if (a == 1) {
				this.sjfsisDropdownOpen = !this.sjfsisDropdownOpen;
				this.sjfsisDropdownOpen2 = false;
				this.sjfsisDropdownOpen3 = false;

			} else if (a == 2) {
				this.sjfsisDropdownOpen2 = !this.sjfsisDropdownOpen2;
				this.sjfsisDropdownOpen = false;
				this.sjfsisDropdownOpen3 = false;

			} else if (a == 3) {
				this.sjfsisDropdownOpen3 = !this.sjfsisDropdownOpen3;
				this.sjfsisDropdownOpen = false;
				this.sjfsisDropdownOpen2 = false;

			}
			// 如果需要，可以在这里添加Bootstrap Dropdown的额外处理逻辑  
		},
		// 校验手机号
		validatePhone() {
			const regex = /^09[0-9]{8}$/; // 简单的手机号正则表达式
			if (!regex.test(this.form.phone)) {
				this.phoneError = this.$t('login.invalid_phone_number');
			} else {
				this.phoneError = '';
			}
			if (this.form.phone == '') {
				this.phoneError = '';
			}
		},
		// 校验邮箱
		emivalidatePhone() {
			const regex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
			if (!regex.test(this.form.email)) {
				this.emilError = this.$t("login.invalid_emil_number");
			} else {
				this.emilError = "";
			}
			if (this.form.email == "") {
				this.emilError = "";
			}
		},
		// 捐款
		jkfs(e) {
			this.form.pay_type = e
		},
		// nts
		ysxx(e, v) {
			if (v == 'a1') {
				this.zdyi = false
				this.form.amount = null
				this.ysxxid = 'a1'
				// this.form.period = 0

			} else {
				this.form.amount = v
				this.zdyi = true
				this.ysxxid = e
				this.gettodonate()
			}

		},
		// 定期期数
		dqysxx(e, v) {
			console.log(`e:${e}, v:${v}`);

			// this.form.period = v
			this.dqysxxid = e
			this.gettodonate()
		},
		fanhui(e) {
			this.$emit('custom', e)
		},
		fun(e) {
			this.show = e
		}
	},
};
</script>

<style scoped lang="scss">
@media screen and (max-width: 991px) {
  .sjbzt {
    font-size: calc(11px + 0.4vw) !important;
  }
  .sjbxzt {
    font-size: calc(9px + 0.4vw) !important;
  }
}
.dropdown-menu {
  min-width: calc(25px + 0.5vw) !important;
}
.ckqbb:hover {
	transform: scale(1.001);
	/* 悬浮时放大1.1倍 */
	box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.xlcd {
	display: block;
	background: #FFEBD2;
	border: none;
}

.zhankai {
	background: #FFEBD2;
	text-align: center;
	border-radius: 12px 12px 12px 12px;
	border: none;
	font-weight: 400;
	font-size: calc(6px + 0.4vw);
	color: #1A1A1A;
}

.xuazz:hover {
	background: #FFEBD2;
}

textarea.form-control {
	overflow-y: auto !important;
}
</style>