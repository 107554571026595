<template>
	<div class="col-md-12 pl-0 pr-0">
		<img @click="fanhui(0)" class="mt-1 mb-2 curpo dcbd sjdsad" style="width: calc(25px + 0.4vw);"
			src="@/img/login/icon_fanhui@2x.png" alt="" />
		<div class="col-md-12 yuanjiao pt-2 pb-3 pl-0 pr-0 sjbzt"
			style="font-size: calc(7px + 0.4vw);line-height: calc(15px + 0.5vw);">
			<div class="col-md-12 baise pt-2 pb-4 yuanjiao parent heggs" style="height: 81vh;overflow-y: auto;">
				<form>
					<div class="form-group">
						<label style="font-weight: 500;"> <span style="color: red;">*</span>{{ $t("参与单位") }}</label>
						<div>
							<input style="background: #F6F4F0;border: none;font-size: calc(7px + 0.4vw);"
								v-model="form.cydw" type="text" class="form-control srk pl-3 sjbxzt"
								:placeholder="$t('请输入参与单位')">
						</div>
					</div>
					<div class="form-group">
						<label style="font-weight: 500;"> <span style="color: red;">*</span>{{ $t("单位统一编号") }}</label>
						<div>
							<input style="background: #F6F4F0;border: none;font-size: calc(7px + 0.4vw);"
								v-model="form.dwtybh" :disabled='isLoading' type="dwtybh" class="form-control sjbxzt srk pl-3"
								:placeholder="$t('请输入单位统一编号')">
						</div>
					</div>
					<label style="font-weight: 500;"> <span style="color: red;">*</span>{{ $t("参与时间")
									}}</label>
					<div class="col-sm-12 d-flex mb-3 pl-0 pr-2 justify-content-between align-item-center">
						<div class=" pl-0 pr-0 col-sm-4 pl-0">
							<div class=" col-sm-12 pl-0 pr-2">
								<div class="pl-0 pr-0 position-relative col-md-12 pl-0 pr-0">
									<!-- 触发Dropdown的按钮 -->
									<button class="d-flex ckqbb zhankai col-sm-12 dydpd pt-2 pb-2 pl-0 pr-0"
										type="button" id="dropdownMenuButton" @click="niantoggleDropdown()">
										<div class="d-flex align-items-center justify-content-between col-md-12 pl-0 pr-0" style="font-size: calc(7px + 0.4vw);padding-left: calc(5px + 0.4vw) !important;padding-right: calc(5px + 0.4vw) !important;">
											<div >{{ selectedYear }}
											</div>
											<div>
												{{ $t("年") }}
												<img class="ml-1" style="width: calc(7px + 0.4vw);"
													src="@/img/daohangl/icon_xlcd_xljt@2x (1).png" alt="" />
											</div>
										</div>
									</button>
									<div class="dropdown-menu yuanjiao xlcd position-absolute col-sm-12 text-center"
										aria-labelledby="dropdownMenuButton" v-show="nianisDropdownOpen"
										style="border-radius:7px;background: #FFFAF0;height: 300px;overflow-y: auto;">
										<a class="dropdown-item xuazz sjbxzt yuanjiao mb-2" v-for="(year, index) in years"
											:key="index"
											:style="{ 'background': year == selectedYear ? '#FFD672' : '' }"
											@click="nianxuanz(year)" href="#">{{ year }}</a>
									</div>
								</div>
							</div>
						</div>
						<div class=" pl-0 pr-0 col-sm-4 pl-1 pr-1">
							<div class=" col-sm-12 pl-0 pr-0">
								<div class="pl-0 pr-0 position-relative col-md-12 pl-0 pr-0">
									<!-- 触发Dropdown的按钮 -->
									<button class="d-flex ckqbb zhankai col-sm-12 dydpd pt-2 pb-2 pl-0 pr-0"
										type="button" id="dropdownMenuButton" @click="youtoggleDropdown()">
										<div class="d-flex align-items-center justify-content-between col-md-12 pl-0 pr-0" style="font-size: calc(7px + 0.4vw);padding-left: calc(5px + 0.4vw) !important;padding-right: calc(5px + 0.4vw) !important;">
											<div >{{ selectedMonth }}
											</div>
											<div>
												{{ $t("月") }}
												<img class="ml-1" style="width: calc(7px + 0.4vw);"
													src="@/img/daohangl/icon_xlcd_xljt@2x (1).png" alt="" />
											</div>
										</div>
									</button>
									<!-- Dropdown内容，使用v-show控制显示 -->
									<div class="dropdown-menu yuanjiao xlcd position-absolute col-sm-12 text-center"
										aria-labelledby="dropdownMenuButton" v-show="youisDropdownOpen"
										style="border-radius:7px;background: #FFFAF0;height: 300px;overflow-y: auto;">
										<a class="dropdown-item xuazz sjbxzt yuanjiao mb-2" v-for="(month, index) in months"
											:key="index"
											:style="{ 'background': month == selectedMonth ? '#FFD672' : '' }"
											@click="youxuanz(month)" href="#">{{ month }}</a>
									</div>
								</div>
							</div>
						</div>
						<div class=" pl-0 pr-0 col-sm-4  pr-0">
							<div class=" col-sm-12 pl-2 pr-0">
								<div class="pl-0 pr-0 position-relative col-md-12 pl-0 pr-0">
									<!-- 触发Dropdown的按钮 -->
									<button class="d-flex ckqbb zhankai col-sm-12 dydpd pt-2 pb-2 pl-0 pr-0"
										type="button" id="dropdownMenuButton" @click="tiantoggleDropdown()">
										<div class="d-flex align-items-center justify-content-between col-md-12 pl-0 pr-0" style="font-size: calc(7px + 0.4vw);padding-left: calc(5px + 0.4vw) !important;padding-right: calc(5px + 0.4vw) !important;">
											<div >{{ selectedDay }}
											</div>
											<div>
												{{ $t("天") }}
												<img class="ml-1" style="width: calc(7px + 0.4vw);"
													src="@/img/daohangl/icon_xlcd_xljt@2x (1).png" alt="" />
											</div>
										</div>
									</button>
									<div class="dropdown-menu yuanjiao xlcd position-absolute col-sm-12 text-center"
										aria-labelledby="dropdownMenuButton" v-show="tianisDropdownOpen"
										style="border-radius:7px;background: #FFFAF0;height: 300px;overflow-y: auto;">
										<a class="dropdown-item xuazz sjbxzt yuanjiao mb-2" v-for="(item, index) in days"
											:key="index" :style="{ 'background': item == selectedDay ? '#FFD672' : '' }"
											@click="tianxuanz(item)" href="#">{{ item }}</a>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div class="form-group">
						<label style="font-weight: 500;"> <span style="color: red;">*</span>{{ $t("参与地点") }}</label>
						<div>
							<input style="background: #F6F4F0;border: none;font-size: calc(7px + 0.4vw);"
								v-model="form.cydd" :disabled='isLoading' type="cydd" class="form-control srk pl-3 sjbxzt"
								:placeholder="$t('请输入参与地点')">
						</div>
					</div>
					<div class="form-group">
						<label style="font-weight: 500;"> <span style="color: red;">*</span>{{ $t("参与时长（小时）") }}</label>
						<div>
							<input style="background: #F6F4F0;border: none;font-size: calc(7px + 0.4vw);"
								v-model="form.cysc" :disabled='isLoading' type="number" class="form-control sjbxzt srk pl-3"
								:placeholder="$t('请输入参与时长')">
						</div>
					</div>
					<!-- 上传志工证明 -->
					<label style="font-weight: 500;"> <span style="color: red;">*</span>{{ $t("上传志工证明") }}</label>
					<div class='d-flex mb-3' style="width: 100%;flex-wrap: wrap;">
						<div v-show="imageUrl" v-for="(item, index) in imageUrl" :key="index"
							class="mb-2 position-relative sjbxzt sjbdkd" style="width:24%;margin-right: 1%;">
							<img style="width: 100%;height: calc(150px + 1vw);" :src="item" alt="" />
							<!-- 每一个图片的删除按钮 -->
							<img @click="sczgzmsc(index)" class="position-absolute curpo dcbd"
								style="width: calc(15px + 0.4vw);right: 3%;top: 3%;"
								src="@/img/grzx/icon_guanbitupian@2x.png" alt="" />
						</div>
						<div class="mb-2 sjbdkd" style="width: 24%;margin-right: 1%;">
							<div class="d-flex justify-content-center position-relative"
								style="height: calc(150px + 1vw);background: #F6F4F0;border-radius: 12px;">
								<div class="sjbxzt" style="font-size: calc(9px + 0.3vw);margin-top: 10%;color: #999999;">
									{{ $t('login.placeholderArchiveMessage') }}
								</div>
								<input ref="fileInput" type="file" @change="onFileChange" style="display: none;">
								<b-button block class="btn denglu sjbxzt position-absolute sjxuanzda" @click="triggerFileInput"
									style="width: 60%;font-size: calc(7px + 0.3vw);height: 35px;bottom: 25%;"
									squared><span>{{ $t('login.xzndda') }}</span></b-button>
							</div>
						</div>
					</div>

					<div class="form-group">
						<label style="font-weight: 500;"> <span style="color: red;">*</span>{{ $t("发布心得") }}</label>
						<div>
							<b-form-textarea class="sjbxzt" style="border: none;background: #F6F4F0;font-size: calc(7px + 0.4vw);"
								:disabled='isLoading' id="textarea" v-model="form.fbxd" :placeholder="$t('写下你的心得')"
								rows="3" max-rows="6" no-resize></b-form-textarea>
						</div>
					</div>
					<!-- 活动照片 -->
					<label style="font-weight: 500;"> <span style="color: red;">*</span>{{ $t('活动照片') }} <span
							style="color: #999999;font-size: calc(4px + 0.4vw);" class="sjbxzt">{{ $t('图片比例按16:9上传') }}</span></label>
					<div class='d-flex mb-3' style="width: 100%;flex-wrap: wrap;">
						<div v-show="hdzpimageUrl" v-for="(item, index) in hdzpimageUrl" :key="index"
							class="mb-2 position-relative sjbdkd" style="width: 24%;margin-right: 1%;">
							<img style="width: 100%;height: calc(150px + 1vw);" :src="item" alt="" />
							<!-- 每一个图片的删除按钮 -->
							<img @click="hdzpsczgzmsc(index)" class="position-absolute curpo dcbd"
								style="width: calc(15px + 0.4vw);right: 3%;top: 3%;"
								src="@/img/grzx/icon_guanbitupian@2x.png" alt="" />
						</div>
						<div class="mb-2 sjbdkd" style="width: 24%;margin-right: 1%;">
							<div class="d-flex justify-content-center position-relative"
								style="height: calc(150px + 1vw);background: #F6F4F0;border-radius: 12px;">
								<div class="sjbxzt" style="font-size: calc(9px + 0.3vw);margin-top: 10%;color: #999999;">
									{{ $t('login.placeholderArchiveMessage') }}
								</div>
								<input ref="hdzpfileInput" type="file" @change="hdzponFileChange"
									style="display: none;">
								<b-button block class="btn denglu position-absolute sjbxzt sjxuanzda" @click="hdzptriggerFileInput"
									style="width: 60%;font-size: calc(7px + 0.3vw);height: 35px;bottom: 25%;"
									squared><span>{{ $t('login.xzndda') }}</span></b-button>
							</div>
						</div>
					</div>
					<div @click="bulletframe()" class="pl-4 pr-4 pt-2 pb-2 yuanjiao ckqbb curpo mt-4 sjbzt"
						style="font-size: calc(6px + 0.4vw);background: #FFD672;width: calc(140px + 0.5vw);text-align: center;">
						{{ $t('保存更改') }}
						<span v-if="isLoading" class="spinner-border spinner-border-sm ml-2" role="status"
							aria-hidden="true">
						</span>
					</div>
				</form>
			</div>
		</div>
	</div>
</template>

<script>
import {
	volunteer_info,
	user_volunteer_save
} from "@/request/grzx/index.js";
import {
	image
} from "@/request/api.js";
export default {
	components: {},
	data() {
		return {
			selectedYear: new Date().getFullYear(),
			selectedMonth: new Date().getMonth() + 1, // 月份从1开始
			selectedDay: new Date().getDate(),
			years: Array.from({
				length: new Date().getFullYear() - 1900 + 1
			},
				(_, i) => new Date().getFullYear() - i
			), // 假设从1900年开始
			months: [],
			days: [],
			show: 1,
			isLoading: false,
			tianisDropdownOpen: false,
			youisDropdownOpen: false,
			nianisDropdownOpen: false,
			imageUrl: [],
			imageUrlpath: [],
			hdzpimageUrl: [],
			hdzpimageUrlpath: [],
			form: {
				cydw: "",
				dwtybh: "",
				tian: "",
				you: "",
				nian: "",
				cydd: "",
				cysc: "",
				fbxd: "",
			},
		};
	},
	created() {
		// this.volunteer_info();
	},
	mounted() {
		this.updateMonthsAndDays(); // 组件挂载后初始化月份和天数
	},
	computed: {
		// 根据年份动态计算月份
		monthsComputed() {
			return Array.from({
				length: 12
			}, (_, i) => i + 1);
		},
		// 根据年份和月份动态计算天数
		daysComputed() {
			const year = this.selectedYear;
			const month = this.selectedMonth;
			let daysInMonth = new Date(year, month, 0).getDate(); // 获取该月有多少天
			return Array.from({
				length: daysInMonth
			}, (_, i) => i + 1);
		},
	},
	methods: {
		updateMonthsAndDays() {
			this.months = this.monthsComputed;
			this.days = this.daysComputed;
		},
		// async volunteer_info() {
		// 	const {
		// 		data
		// 	} = await volunteer_info({});
		// 	if (data.info) {
		// 		this.form = {
		// 			cydw: data.info.organization_name,
		// 			dwtybh: data.info.business_number,
		// 			cydd: data.info.address,
		// 			cysc: data.info.time_length,
		// 			fbxd: data.info.summary,
		// 		};
		// 		this.selectedYear = data.info.join_year;
		// 		this.selectedMonth = data.info.join_month;
		// 		this.selectedDay = data.info.join_day;
		// 		let imageUrl = [];
		// 		let imageUrlpath = [];
		// 		for (let i = 0; i < data.info.certificate_image.length; i++) {
		// 			imageUrl.push(data.info.certificate_image[i].url);
		// 			imageUrlpath.push(data.info.certificate_image[i].path);
		// 		}
		// 		this.imageUrl = imageUrl;
		// 		this.imageUrlpath = imageUrlpath;
		// 		let hdzpimageUrl = [];
		// 		let hdzpimageUrlpath = [];
		// 		for (let i = 0; i < data.info.activity_image.length; i++) {
		// 			hdzpimageUrl.push(data.info.activity_image[i].url);
		// 			hdzpimageUrlpath.push(data.info.activity_image[i].path);
		// 		}
		// 		this.hdzpimageUrl = hdzpimageUrl;
		// 		this.hdzpimageUrlpath = hdzpimageUrlpath;
		// 	} else {
		// 		this.form = {
		// 			cydw: "",
		// 			dwtybh: "",
		// 			tian: "01",
		// 			you: "01",
		// 			nian: "2024",
		// 			cydd: "",
		// 			cysc: "",
		// 			fbxd: "",
		// 		};
		// 	}
		// },
		// 上传活动照片
		hdzpsczgzmsc(index) {
			this.hdzpimageUrl.splice(index, 1);
			this.hdzpimageUrlpath.splice(index, 1);
		},
		hdzptriggerFileInput() {
			// 触发隐藏的input的点击事件
			this.$refs.hdzpfileInput.click();
		},
		async hdzponFileChange(e) {
			const {
				data,
				msg,
				code
			} = await image({
				file: e.target.files[0],
				type:'members-volunteer-cert'
			});
			if (code == 200) {
				this.hdzpimageUrlpath.push(data.path);
				this.hdzpimageUrl.push(data.url);
			} else {
				this.$bvToast.toast(msg, {
					title: this.$t("login.warning"),
					autoHideDelay: 2000,
					delay: 5000,
					appendToast: true,
					variant: "danger",
				});
			}
		},
		// 上传志工证明
		sczgzmsc(index) {
			this.imageUrl.splice(index, 1);
			this.imageUrlpath.splice(index, 1);
		},
		triggerFileInput() {
			// 触发隐藏的input的点击事件
			this.$refs.fileInput.click();
		},
		async onFileChange(e) {
			const {
				data,
				msg,
				code
			} = await image({
				file: e.target.files[0],
				type:'members-volunteer-cert'
			});
			if (code == 200) {
				this.imageUrlpath.push(data.path);
				this.imageUrl.push(data.url);
			} else {
				this.$bvToast.toast(msg, {
					title: this.$t("login.warning"),
					autoHideDelay: 2000,
					delay: 5000,
					appendToast: true,
					variant: "danger",
				});
			}
		},
		fanhui(e) {
			this.$emit("custom", e);
		},
		// 保存更改
		async bulletframe() {
			this.isLoading = true;
			const {
				msg,
				data,
				code
			} = await user_volunteer_save({
				organization_name: this.form.cydw,
				business_number: this.form.dwtybh,
				join_year: this.selectedYear,
				join_month: this.selectedMonth,
				join_day: this.selectedDay,
				address: this.form.cydd,
				time_length: this.form.cysc,
				summary: this.form.fbxd,
				certificate_image: this.imageUrlpath,
				activity_image: this.hdzpimageUrlpath,
			});
			if(code==200){
				this.$bvToast.toast(this.$t("保存/修改成功"), {
					title: this.$t("login.notice"),
					autoHideDelay: 2000,
					delay: 5000,
					appendToast: true,
					variant: "success",
				});
			setTimeout(() => {
				
				this.$emit("custom");
				this.isLoading = false; // 处理完成后关闭转圈
			}, 2000);
			}else{
				this.$bvToast.toast(msg, {
					title: this.$t("login.warning"),
					autoHideDelay: 2000,
					delay: 5000,
					appendToast: true,
					variant: "danger",
				});
				this.isLoading = false;
			}
		},
		tianxuanz(e) {
			this.selectedDay = e;
			this.tianisDropdownOpen = false;
		},
		tiantoggleDropdown() {
			this.tianisDropdownOpen = !this.tianisDropdownOpen;
			this.youisDropdownOpen = false;
			this.nianisDropdownOpen = false;
			// 如果需要，可以在这里添加Bootstrap Dropdown的额外处理逻辑
		},
		youxuanz(e) {
			this.selectedMonth = e;
			this.updateMonthsAndDays();
			this.youisDropdownOpen = false;
		},
		youtoggleDropdown() {
			this.youisDropdownOpen = !this.youisDropdownOpen;
			this.tianisDropdownOpen = false;
			this.nianisDropdownOpen = false;
			// 如果需要，可以在这里添加Bootstrap Dropdown的额外处理逻辑
		},
		nianxuanz(e) {
			this.selectedYear = e;
			this.updateMonthsAndDays();
			this.nianisDropdownOpen = false;
		},
		niantoggleDropdown() {
			this.nianisDropdownOpen = !this.nianisDropdownOpen;
			this.youisDropdownOpen = false;
			this.tianisDropdownOpen = false;
			// 如果需要，可以在这里添加Bootstrap Dropdown的额外处理逻辑
		},
	},
};
</script>

<style scoped>
@media screen and (max-width: 992px) {
  .heggs {
    height: 72vh !important;
  }
}
@media screen and (max-width: 1200px) {
	.sjbzt {
    font-size: calc(11px + 0.4vw) !important;
  }
  .sjbxzt {
    font-size: calc(9px + 0.4vw) !important;
  }
  .sjdsad{
     margin-top: 1rem !important;
  }
  .sjbdkd{
	width: 48% !important;
  }
  .sjxuanzda{
	width: 80% !important;
  }
}
.dcbd:hover {
	transform: scale(1.1);
}

.qieh {
	width: calc(100px + 0.4vw);
	text-align: center;
	border-radius: 12px 12px 0 0;
}

.ckqbb:hover {
	transform: scale(1.001);
	/* 悬浮时放大1.1倍 */
	box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.xlcd {
	display: block;
	background: #ffebd2;
	border: none;
}

.xlcd::-webkit-scrollbar {
	display: none;
}
.dropdown-menu {
  min-width: calc(25px + 0.5vw) !important;
}
.denglu {
	background: #ffd672;
	border: none !important;
	height: 48px;
	color: #1a1a1a;
	font-weight: 500;
	border-radius: 15px !important;
}

.denglu:hover {
	background: #ffd672;
	border: none !important;
	height: 48px;
	color: #1a1a1a;
	font-weight: 500;
	transform: scale(1.001);
	/* 悬浮时放大1.1倍 */
	box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.zhankai {
	background: #ffebd2;
	text-align: center;
	border-radius: 12px 12px 12px 12px;
	border: none;
	font-weight: 400;
	font-size: calc(6px + 0.4vw);
	color: #1a1a1a;
}

.xuazz:hover {
	background: #ffebd2;
}

textarea.form-control {
	overflow-y: auto !important;
}

/* 隐藏水平滚动条 */
.parent::-webkit-scrollbar {
	height: 0;
}
</style>