<template>
  <div class="col-md-12 pl-0 pr-0">
    <skeleton-screen class="mt-3" v-if="!dataLoaded"></skeleton-screen>
    <div v-if="dataLoaded" class="col-md-12 yuanjiao pt-3 pb-3 pl-0 pr-0 d-flex justify-content-between">
      <!-- 左 -->
      <div class=" col-lg-9 pl-0 pr-0 sjwd">
        <div class="col-md-12 baise yuanjiao pt-3 pb-3">
          <div class="col-sm-5 pl-0 pr-0 position-relative d-flex align-items-center">
            <input style="border: 1px solid #E5E5E5;" class="form-control sxbj col-md-12 inppt pr-5" type="text" :placeholder="this.$t('搜寻')" @input="search()" v-model="account" plaintext='true' aria-label="Username" aria-describedby="basic-addon1">
            <img style="right: 20px;width: calc(10px + 0.5vw);" @click="search()" class="position-absolute curpo dcbd" src="@/img/daohangl/icon_hssy_sousuo@2x.png" alt="" />
          </div>
        </div>
        <div class="col-md-12 baise yuanjiao pl-0 pr-0 pt-3 pb-3 mt-3 pl-3 pr-3 yinc">
          <div class="mb-2" style="font-size: calc(6px + 0.5vw);font-weight: 500;">
            <div>{{ $t('企业成员') }}</div>
          </div>
          <div class="mb-2" style="height: 1px;background: #E5E5E5;"></div>
          <div @click="funs(item.user_id, item.user_slug)" v-for="(item, index) in conLis" :key="index" style="background: #F6F4F0;" class="col-sm-12 pl-3 pr-3 pl-0 pr-0 d-flex curpo justify-content-between align-items-center mb-2 yuanjiao xunafuxgio  pt-3 pb-3">
            <div class="col-sm-10 pl-0 pr-0 d-flex align-items-center">
              <img class="mr-2" style="width: calc(40px + 0.5vw);height: calc(40px + 0.5vw);border-radius: 50%;" :src="item.avatar ? item.avatar : '@/img/daohangl/pic_hssy_hdlb_tx_one@2x.png'" alt="" />
              <div class="col-sm-2 col-md-3 col-lg-4 col-xl-3 pl-0 pr-0">
                <div class="ellipsis" style="font-size: calc(8px + 0.4vw);width: calc(105px + 1vw);">
                  {{ item.nickname ? item.nickname : '' }}
                </div>
                <div class="d-flex align-items-center">
                  <div class="d-flex mr-2 align-items-center" style="font-size: calc(6px + 0.4vw);color: #666666;">
                    <div>{{ item.username ? item.username : '' }}</div>
                  </div>
                </div>
              </div>
              <div class="col-sm-8 col-md-6 col-lg-4 col-xl-4 text-center pl-0 pr-0">
                <div class="d-flex align-items-center mr-3 pt-1 pb-1 pl-2 curpo pr-2 ckqbb" style="background: #FFEBD2;border-radius:12px;">
                  <img class="mr-2" style="width: 15px;font-size: calc(7px + 0.4vw);font-weight: 500;" src="@/img/daohangl/pic_hssy_haorenbi@2x.png" alt="" />
                  <div class="d-flex">
                    <div style="font-size: calc(6px + 0.4vw);">{{ $t('好人积分') }}：</div>
                    <div style="font-size: calc(6px + 0.4vw);font-weight: 700;">{{ item.good_coin ?
											item.good_coin.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') : '0' }}</div>
                  </div>
                </div>
                <div class="d-flex align-items-center mr-3 pt-1 pb-1 pl-2 pr-2 curpo mt-2 ckqbb" style="background: #FDDDC9;border-radius:12px;width: auto;">
                  <img class="mr-2" style="width: 15px;font-size: calc(7px + 0.4vw);font-weight: 500;" src="@/img/daohangl/pic_hssy_tanbi@2x.png" alt="" />
                  <div class="d-flex">
                    <div style="font-size: calc(6px + 0.4vw);">{{ $t('碳积分') }}：</div>
                    <div style="font-size: calc(6px + 0.4vw);font-weight: 700;">{{ item.carbon_coin
											? item.carbon_coin.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') : '0' }}</div>
                  </div>
                </div>
              </div>
            </div>
            <button class="col-sm-2 col-md-2 col-lg-1 col-xl-1 pl-0 pr-0 pt-2 pb-2 yuanjiao xunafuxgio annys" style="font-size: calc(5px + 0.4vw);background: #FFD672;border: none;">
              {{ $t('查看主页') }}
            </button>
          </div>

          <!-- 加载更多提示 -->
          <div class="d-flex justify-content-center">

            <div v-if="isLoadingis" class="loading">{{ $t('加载中') }}...</div>
            <!-- <div v-if="isnoMore && conLis.length != 0" class="no-more">没有更多了</div> -->
            <div style="text-align: center;" v-if="conLis.length == 0">
              {{ $t('暂无') }}
            </div>
          </div>

        </div>
		<div class="col-md-12 baise yuanjiao pl-0 pr-0 pt-3 pb-3 mt-3 pl-3 pr-3 aaaa">
          <div class="mb-2" style="font-size: calc(6px + 0.5vw);font-weight: 500;">
            <div>{{ $t('企业成员') }}</div>
          </div>
          <div class="mb-2" style="height: 1px;background: #E5E5E5;"></div>
          <div @click="funs(item.user_id, item.user_slug)" v-for="(item, index) in conLis" :key="index" style="background: #F6F4F0;" class="col-sm-12 pl-3 pr-3 pl-0 pr-0 d-flex curpo justify-content-between align-items-center mb-2 yuanjiao xunafuxgio  pt-3 pb-3">
			<div class="col-sm-10 pl-0 pr-0 d-flex align-items-center" style="justify-content: space-between;">
                <div>
                  <div class="d-flex">
                    <img class="mr-2" style="width: calc(40px + 0.5vw);height: calc(35px + 0.5vw);border-radius: 50%;object-fit: cover;" :src="item.avatar" alt="" />
                    <div class=" pl-0 pr-0">
                      <div class=" sjbzt" style="font-size: calc(8px + 0.4vw);">
                        {{ item.nickname }}
                      </div>
                      <div class="d-flex align-items-center">
                        <div class="d-flex mr-2 align-items-center sjbxzt" style="font-size: calc(6px + 0.4vw);color: #666666;">
                          <div>{{ item.username }}</div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class=" text-center pl-0 pr-0 d-flex mt-2" style="align-items: center;">
                    <div class="d-flex align-items-center mr-2 pt-1 pb-1 pl-2 curpo pr-2 ckqbb" style="background: #FFEBD2;border-radius:12px;">
                      <img class="mr-1" style="width: 20px;font-size: calc(7px + 0.4vw);font-weight: 500;" src="@/img/daohangl/pic_hssy_haorenbi@2x.png" alt="" />
                      <div class="d-flex">
                        <!-- <div style="font-size: calc(6px + 0.4vw);">{{ $t('总发行好人币') }}</div> -->
                        <div class="sjbxzt" style="font-size: calc(6px + 0.4vw);">{{ $t('好人积分') }}:</div>

                        <div class="sjbxzt" style="font-size: calc(6px + 0.4vw);font-weight: 700;">{{ item.good_coin ? item.good_coin.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') : '0' }}
                        </div>
                      </div>
                    </div>
                    <div class="d-flex align-items-center mr-2 pt-1 pb-1 pl-2 pr-2 curpo  ckqbb" style="background: #FDDDC9;border-radius:12px;width: auto;">
                      <img class="mr-1" style="width: 20px;font-size: calc(7px + 0.4vw);font-weight: 500;" src="@/img/daohangl/pic_hssy_tanbi@2x.png" alt="" />
                      <div class="d-flex">
                        <!-- <div style="font-size: calc(6px + 0.4vw);">{{ $t('总发行碳币') }}</div> -->
                        <div class="sjbxzt" style="font-size: calc(6px + 0.4vw);">{{ $t('碳积分') }}:</div>

                        <div class="sjbxzt" style="font-size: calc(6px + 0.4vw);font-weight: 700;">{{ item.carbon_coin ? item.carbon_coin.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') : '0' }}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

              </div>
			  <button class="pl-0 pr-0 pt-2 pb-2 yuanjiao xunafuxgio annys" style="font-size: calc(5px + 0.4vw);background: #FFD672;border: none;width: calc(70px + 0.5vw);">
                {{ $t('查看主页') }}
              </button>
          </div>

          <!-- 加载更多提示 -->
          <div class="d-flex justify-content-center">

            <div v-if="isLoadingis" class="loading">{{ $t('加载中') }}...</div>
            <!-- <div v-if="isnoMore && conLis.length != 0" class="no-more">没有更多了</div> -->
            <div style="text-align: center;" v-if="conLis.length == 0">
              {{ $t('暂无') }}
            </div>
          </div>

        </div>
      </div>
      <!-- 右 -->
      <div class="col-lg-3 col-md-4 pl-0 pr-0 pl-3 yinc">
        <div class="baise pl-0 pr-0 pt-3 pb-3 col-md-12 yuanjiao">
          <div class="mb-2 ml-3 mr-3" style="font-size: calc(6px + 0.5vw);font-weight: 500;">
            <div>{{ $t('企业管理员') }}</div>
          </div>
          <div class="mb-2 ml-3 mr-3" style="height: 1px;background: #E5E5E5;"></div>
          <div @click="funs(item.user_id, item.user_slug)" v-for="(item, index) in conLisAdim" :key="index" class="d-flex curpo justify-content-between align-items-center mb-2 xunafuxgio pl-3 pr-3 pt-2 pb-2">
            <div class="d-flex justify-content-between align-items-center">
              <img class="mr-2" style="width: calc(35px + 0.5vw);height: calc(35px + 0.5vw);border-radius: 50%;" :src="item.avatar ? item.avatar : ''" alt="" />
              <div>
                <div class="ellipsis" style="font-size: calc(5px + 0.4vw);width: calc(105px + 1vw);">
                  {{ item.nickname ? item.nickname : '' }}
                </div>
                <div class="d-flex align-items-center mt-1">
                  <div class="d-flex mr-2 align-items-center" style="font-size: calc(4px + 0.4vw);color: #666666;">
                    <div>{{ item.username ? item.username : '' }}</div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div style="text-align: center;" v-if="conLisAdim.length == 0">
            <!-- 暂无 -->
          </div>

        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { comp_member } from "@/request/aapi.js";

import SkeletonScreen from "./tuabduihd/SkeletonScreen/SkeletonScreen.vue";
export default {
  components: {
    SkeletonScreen,
  },
  data() {
    return {
      dataLoaded: false,
      hdlb: [{}, {}, {}],
      tdcy: [{}, {}, {}, {}, {}],
      page: 1,
      pagea: 1,
      is_admin: -1,

      // 企业成员
      conLis: [],
      // 企业管理员
      conLisAdim: [],
      // 搜寻
      account: null,
      isid: "",
    };
  },
  created() {},
  mounted() {
    this.$nextTick(()=>{
      this.isid = this.$route.params.id;
    this.getNumber();
    this.getNumbera();
    })
    
    // 模拟数据加载
  },
  computed: {},
  methods: {
    isgun() {
      if (this.isggun < 10 && !this.isLoadingis && !this.isnoMore) {
        this.isLoadingis = true;
        this.getNumber();
      }
    },
    // 搜寻
    search() {
      this.getNumber();
    },

    // 企业成员
    getNumber() {
      let data = {
        page: this.page,
        id: this.isid,
        // 是否管理员，-1全部，0否，1是（包含创建者）
        is_admin: -1,
        // 账号
        account: this.account,
      };
      comp_member(data).then((res) => {
        // console.log(res, "企业成员");
        if (res.code == 200) {
          this.dataLoaded = true;
          if (this.page == 1) {
            if (this.page * res.data.per_page > res.data.total) {
              this.conLis = res.data.data;
              this.isnoMore = true;
              // console.log(this.conLis, "信息1");
              return;
            }
          } else {
            if (this.page * res.data.per_page > res.data.total) {
              this.conLis = [...this.conLis, ...res.data.data];
              this.isnoMore = true;
              // console.log(this.conLis, "信息2");

              return;
            }
            this.conLis = [...this.conLis, ...res.data.data];
            this.page++;
            this.isLoadingis = false;
            // console.log(this.conLis, "信息3");
          }
        }
      });
    },

    // 企业管理员

    getNumbera() {
      let data = {
        page: this.pagea,
        id: this.isid,
        // 是否管理员，-1全部，0否，1是（包含创建者）
        is_admin: 1,
      };
      comp_member(data).then((res) => {
        // console.log(res, "企业管理成员");
        if (res.code == 200) {
          this.conLisAdim = res.data.data;
        }
      });
    },
    funs(e, v) {
      // 判断是不是自己的账号是的话跳转个人中心
      if (e == localStorage.getItem("user_id")) {
        tthis.$router.push("/member/" + v);
      } else {
        this.$router.push("/members/" + v);
      }
    },
  },
};
</script>

<style scoped>
@media screen and (max-width: 999px) {
  .aaaa {
    display: block !important;
  }
  .yinc {
    display: none !important;
  }
  .sjbzt {
    font-size: calc(11px + 0.4vw) !important;
  }
  .sjbxzt {
    font-size: calc(9px + 0.4vw) !important;
  }
  .sjwd {
    width: 100% !important;
  }
}
.aaaa {
  display: none;
}
/* @media screen and (max-width: 1200px) {
		.yinc {
			display: none !important;
		}
	} */
.dcbd:hover {
  transform: scale(1.2);
}

.inppt {
  background: white;
  border: none;
  border-radius: 13px;
  font-size: calc(7px + 0.4vw);
}

.xunafuxgio:hover {
  transform: scale(1.001);
  /* 悬浮时放大1.1倍 */
  border-radius: 12px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  /* 悬浮时添加阴影效果 */
}

.qieh {
  width: calc(100px + 0.4vw);
  text-align: center;
  border-radius: 12px 12px 0 0;
}

.sxbj {
  padding-top: calc(15px + 0.5vw);
  padding-bottom: calc(15px + 0.5vw);
}
</style>