import request from './index.js'

// 普通用户注册
export const register = (params) => request.post("/login/register", params);
// 验证码
export const sendcode = (params) => request.post("/send_code/sms", params);
// emil验证码
export const sendcodeemil = (params) => request.post("/send_code/email", params);
// 登录
export const login = (params) => request.post("/login/pwd", params);
// 首次登录验证手机号
export const verify_phone = (params) => request.post("/login/verify_phone", params);
// 首次登录验证邮箱
export const organization_complete_step1 = (params) => request.post("/login/organization_complete_step1", params);
// 找回密码新设密码
export const set_pwd = (params) => request.post("/login/set_pwd", params);
// 获取机构注册类别
export const register_category = (params) => request.get("/login/register_category", {params});
// 企业和社福团体注册第二步
export const organization_register_step2 = (params) => request.post("/login/organization_register_step2", params);
// 上传图片
export const image = (params) => request.post("/upload/image", params);
// 企业和社福团体注册
export const organization_register_step1 = (params) => request.post("/login/organization_register_step1", params);


// 获取基本信息
export const common_base = (params) => request.post("/common/base", params);
// 左下角底部关于我们
export const about_us = (params) => request.post("/about/about_us", params);

// 好事团体-列表
export const group_list = (params) => request.post("/group/list", params);
// 好事团体-团体详情（主要部分）
export const group_detail = (params) => request.post("/group/detail", params);
// 好事团体-关于我们
export const group_about = (params) => request.post("/group/about", params);
// 好事企业-关于我们
export const company_about = (params) => request.post("/company/about", params);
// 好事团体-团队成员
export const group_team = (params) => request.post("/group/member", params);
// 好事团体-加入团体
export const group_join = (params) => request.post("/group/join", params);
// 好事团体-离开团队
export const group_leave = (params) => request.post("/group/leave", params);
// 涂鸦墙列表
export const moment_list = (params) => request.post("/moment/list", params);
// 用户政策
export const user_agreement = (params) => request.post("/about/user_agreement", params);
// 隐私政策
export const privacy_policy = (params) => request.post("/about/privacy_policy", params);
// 使用条款
export const terms_use = (params) => request.get("/about/terms_use", {params});
// 使用说明
export const instructions_use = (params) => request.get("/about/instructions_use", {params});
// 使用说明
export const about_api = (params) => request.get("/about/api", {params});
// 校验短信验证码
export const check_sms = (params) => request.get("/send_code/check_sms", {params});
// 校验邮件验证码
export const check_email = (params) => request.get("/send_code/check_email", {params});
