<template>
  <div class="col-md-12 col-sm-12 pl-1 pr-1 pl-0 pr-0 col-lg-12 col-xl-12 baise parent heggs" style="height: 75vh;overflow-y: auto;">
    <div class="col-md-12 col-sm-12 col-lg-12 col-xl-12 pl-0 pr-0 pb-3 sjbzt" style="font-size: calc(7px + 0.4vw);line-height: calc(15px + 0.5vw);">
      <form>
        <div class="form-group">
          <label style="font-weight: 500;"> <span style="color: red;">*</span>{{ $t('用户名') }}</label>
          <div>
            <input style="background: #F6F4F0;border: none;font-size: calc(7px + 0.4vw);" v-model="form.yhm" @input="jzsrsjih()" :disabled='isLoading' type="yhm" class="form-control sjbxzt srk pl-3" :placeholder="$t('请输入用户名')">
            <small class="form-text sjbxzt" style="color: red;" v-if="yhmjzsr">{{ yhmxzsr }}</small>
          </div>
        </div>
        <div class="form-group">
          <label style="font-weight: 500;"> <span style="color: red;">*</span>{{ $t('用户暱称') }}</label>
          <div>
            <input style="background: #F6F4F0;border: none;font-size: calc(7px + 0.4vw);" v-model="form.yhnc" :disabled='isLoading' @input="yhncjzsrsjh()" type="yhnc" class="form-control sjbxzt srk pl-3" :placeholder="$t('请输入用户暱称')">
            <small class="form-text sjbxzt" style="color: red;" v-if="ncjzsr">{{ $t("为了隐私安全，请不用以手机号码作为用户暱称！") }}</small>
          </div>
        </div>
        <!-- 手机号 -->
        <div class="form-group">
          <label style="font-weight: 500;"><span style="color: red;">*</span>{{ $t('手机号') }}</label>
          <div>
            <input style="background: #F6F4F0;border: none;font-size: calc(7px + 0.4vw);" v-model="form.phone" :disabled='isLoading || gghm' @input="validatePhone()" type="text" class="form-control sjbxzt srk" :placeholder="$t('请输入手机号')">
            <small class="form-text sjbxzt" style="color: red;" v-if="phoneError">{{ phoneError }}</small>
            <div class="mt-2">
              <span @click="gghm = !gghm" class="yzm curpo pt-1 pb-1 pr-2 pl-2 ckqbb" v-show="phonetimeTrue">
                {{ gghm ? $t('更改号码') : $t('展不更改') }}
              </span>
            </div>
          </div>
        </div>

        <!-- 验证码 -->
        <div class="form-group" v-if="!gghm">
          <label style="font-weight: 500;"><span style="color: red;">*</span>{{ $t('login.captchaLabel')
						}}</label>
          <div>
            <b-form-input @input="yzmsd" style="background: #F6F4F0;border: none;font-size: calc(7px + 0.4vw);" v-model="form.phoneuse" :disabled='isLoading' type="number" class="form-control sjbxzt srk mb-3" :placeholder="$t('login.captchaPlaceholder')">
            </b-form-input>
            <div class="position-absolute yzm curpo pb-1" v-if="yzmqr==1">
              <div v-show="phonetimeTrue" @click="phoneobtainCode()">
                {{ $t('login.getCaptchaButton') }}
              </div>
              <div v-show="!phonetimeTrue">
                {{ phonetime }}{{ $t('login.resend_countdown') }}
              </div>
            </div>
            <div v-else class="position-absolute yzm pb-1" style="background: #FAB1AB;">
              {{ $t("验证码正确") }}
            </div>
          </div>
        </div>
        <!-- 邮箱 -->
        <div class="form-group">
          <label style="font-weight: 500;"><span style="color: red;">*</span>{{ $t('login.emailLoginButton')
						}}</label>
          <div>
            <input style="background: #F6F4F0;border: none;font-size: calc(7px + 0.4vw);" v-model="form.emil" :disabled='isLoading || ggyx' @input="validateemil()" type="text" class="form-control sjbxzt srk" :placeholder="$t('login.qsryx')">
            <small class="form-text sjbxzt" style="color: red;" v-if="emilError">{{ emilError }}</small>
            <div class="mt-2">
              <span @click="ggyx = !ggyx" class="yzm curpo pt-1 pb-1 pr-2 pl-2 ckqbb" v-show="phonetimeTrue">
                {{ ggyx ? $t('更改邮箱') : $t('展不更改') }}
              </span>
            </div>
          </div>
        </div>
        <!-- 验证码 -->
        <div class="form-group" v-if="!ggyx">
          <label style="font-weight: 500;"><span style="color: red;">*</span>{{ $t('login.captchaLabel')
						}}</label>
          <div>
            <b-form-input @input="emyzmsd" style="background: #F6F4F0;border: none;font-size: calc(7px + 0.4vw);" v-model="form.emiluse" :disabled='isLoading' type="number" class="form-control sjbxzt srk mb-3" :placeholder="$t('login.captchaPlaceholder')">
            </b-form-input>
			<div class="position-absolute yzm curpo pb-1" v-if="emyzmqr==1">
            <div v-show="timeTrue" @click="obtainCode()">
              {{ $t('login.getCaptchaButton') }}
            </div>
            <div v-show="!timeTrue">
              {{ time }}{{ $t('login.resend_countdown') }}
            </div>
          </div>
          <div v-else class="position-absolute yzm pb-1" style="background: #FAB1AB;">
            {{ $t("验证码正确") }}
          </div>
          </div>
        </div>
        <label style="font-weight: 500;">{{ $t('居住城市') }}</label>
        <!-- 居住城市 -->
        <div class="col-sm-12 d-flex mb-3 pl-0 pr-0 justify-content-between align-item-center">
          <div class=" pl-0 pr-0 col-sm-4 pr-2">
            <div class=" col-sm-12 pl-0 pr-0">
              
              <div class="pl-0 pr-0 position-relative col-md-12 pl-0 pr-0">
                <!-- 触发Dropdown的按钮 -->
                <button class="d-flex ckqbb zhankai col-sm-12 dydpd pt-2 pb-2 pl-0 pr-0" type="button" id="dropdownMenuButton" @click="gjtoggleDropdown()">
                  <div class="d-flex align-items-center justify-content-between col-md-12 pl-0 pr-0" style="font-size: calc(7px + 0.4vw);padding-left: calc(10px + 0.4vw) !important;padding-right: calc(10px + 0.4vw) !important">
                    <div >
                      {{ form.gj ?  form.gj : $t('请选择') }}
                    </div>
                    <div>
                      {{ $t('国家') }}
                      <img class="ml-1" style="width: calc(7px + 0.4vw);" src="@/img/daohangl/icon_xlcd_xljt@2x (1).png" alt="" />
                    </div>
                  </div>
                </button>
                <!-- Dropdown内容，使用v-show控制显示 -->
                <div class="dropdown-menu yuanjiao xlcd position-absolute col-sm-12 text-center" aria-labelledby="dropdownMenuButton" v-show="gjisDropdownOpen" style="border-radius:7px;background: #FFFAF0;">
                  <a v-for="(item, index) in guojialist" :key="index" class="dropdown-item xuazz yuanjiao sjbxzt mb-2" :style="{ 'background': guojiaid == item.id ? '#FFD672' : '' }" @click="gjxuanz(item.name, item.id)" href="#"> {{ item.name }}</a>
                </div>
              </div>
            </div>
          </div>
          <div class=" pl-0 pr-0 col-sm-4 pl-1 pr-1">
            <div class=" col-sm-12 pl-0 pr-0">
              <div class="pl-0 pr-0 position-relative col-md-12 pl-0 pr-0">
                <!-- <label class="mt-3" style="font-weight: 500;"></label> -->
                <!-- 触发Dropdown的按钮 -->
                <button class="d-flex ckqbb zhankai col-sm-12 dydpd pt-2 pb-2 pl-0 pr-0" type="button" id="dropdownMenuButton" @click="xstoggleDropdown()">
                  <div class="d-flex align-items-center justify-content-between col-md-12 pl-0 pr-0" style="font-size: calc(7px + 0.4vw);padding-left: calc(10px + 0.4vw) !important;padding-right: calc(10px + 0.4vw) !important">
                    <div >{{ form.xs ? form.xs : $t('请选择') }}
                    </div>
                    <div>
                      {{ $t('县市') }}
                      <img class="ml-1" style="width: calc(7px + 0.4vw);" src="@/img/daohangl/icon_xlcd_xljt@2x (1).png" alt="" />
                    </div>
                  </div>
                </button>
                <!-- Dropdown内容，使用v-show控制显示 -->
                <div class="dropdown-menu yuanjiao xlcd position-absolute col-sm-12 text-center" aria-labelledby="dropdownMenuButton" v-show="xsisDropdownOpen" style="border-radius:7px;background: #FFFAF0;max-height: 250px;overflow-y: auto;">
                  <a v-for="(item, index) in xianslist" :key="index" class="dropdown-item xuazz sjbxzt yuanjiao mb-2" :style="{ 'background': xiansid == item.id ? '#FFD672' : '' }" @click="xsxuanz(item.name, item.id)" href="#">{{ item.name }}</a>
                </div>
              </div>
            </div>
          </div>
          <div class=" pl-0 pr-0 col-sm-4 pl-2">
            <div class=" col-sm-12 pl-0 pr-0">
              <!-- <label class="mt-3" style="font-weight: 500;"></label> -->
              <div class="pl-0 pr-0 position-relative col-md-12 pl-0 pr-0">
                <!-- 触发Dropdown的按钮 -->
                <button class="d-flex ckqbb zhankai col-sm-12 dydpd pt-2 pb-2 pl-0 pr-0" type="button" id="dropdownMenuButton" @click="qytoggleDropdown()">
                  <div class="d-flex align-items-center justify-content-between col-md-12 pl-0 pr-0" style="font-size: calc(7px + 0.4vw);padding-left: calc(10px + 0.4vw) !important;padding-right: calc(10px + 0.4vw) !important">
                    <div >{{ form.qy ? form.qy : $t('请选择') }}
                    </div>
                    <div>
                      {{ $t('区域') }}
                      <img class="ml-1" style="width: calc(7px + 0.4vw);" src="@/img/daohangl/icon_xlcd_xljt@2x (1).png" alt="" />
                    </div>
                  </div>
                </button>
                <!-- Dropdown内容，使用v-show控制显示 -->
                <div class="dropdown-menu yuanjiao xlcd position-absolute col-sm-12 text-center" aria-labelledby="dropdownMenuButton" v-show="qyisDropdownOpen" style="border-radius:7px;background: #FFFAF0;max-height: 250px;overflow-y: auto;">
                  <a v-for="(item, index) in quyulist" :key="index" class="dropdown-item xuazz sjbxzt yuanjiao mb-2" :style="{ 'background': quyuid == item.id ? '#FFD672' : '' }" @click="qyxuanz(item.name, item.id)" href="#">{{ item.name }}</a>

                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="form-group">
          <label style="font-weight: 500;"> <span style="color: red;">*</span>{{ $t('统一编号') }}</label>
          <div>
            <input style="background: #F6F4F0;border: none;font-size: calc(7px + 0.4vw);" v-model="form.tybh" :disabled='isLoading' type="yhm" class="form-control sjbxzt srk pl-3" :placeholder="$t('请输入统一编号')">
          </div>
        </div>
        <div class="form-group">
          <label style="font-weight: 500;"> <span style="color: red;">*</span>{{ $t('立案字号') }}</label>
          <div>
            <input style="background: #F6F4F0;border: none;font-size: calc(7px + 0.4vw);" v-model="form.registration_number" :disabled='isLoading' type="yhm" class="sjbxzt form-control srk pl-3" :placeholder="$t('请输入立案字号')">
          </div>
        </div>
        <div class="col-sm-12 d-flex mb-3 pl-0 pr-0 justify-content-between align-item-center">
          <div class=" pl-0 pr-0 col-sm-6 pr-2">
            <div class="">
              <label style="font-weight: 500;">{{ $t('Telegram') }}</label>
              <div class='position-relative'>
                <input style="background: #F6F4F0;border: none;font-size: calc(7px + 0.4vw);" v-model="form.Telegram" :disabled='isLoading' type="Telegram" class="paddlef sjbxzt form-control srk pl-5" placeholder="http://">
                <img class="position-absolute" style="width: calc(15px + 0.4vw);left: 14px;top: 20%;" src="@/img/grzx/icon_grzx_lj_one@2x.png" alt="" />
              </div>
            </div>
          </div>
          <div class=" pl-0 pr-0 col-sm-6 pl-1 pr-1">
            <div class="">
              <label style="font-weight: 500;">{{ $t('Instagram') }}</label>
              <div class='position-relative'>
                <input style="background: #F6F4F0;border: none;font-size: calc(7px + 0.4vw);" v-model="form.Instagram" :disabled='isLoading' type="Instagram" class="paddlef sjbxzt form-control srk pl-5" placeholder="http://">
                <img class="position-absolute" style="width: calc(15px + 0.4vw);left: 14px;top: 20%;" src="@/img/grzx/icon_grzx_lj_two@2x.png" alt="" />
              </div>
            </div>
          </div>
        </div>
        <div class="col-sm-12 d-flex mb-3 pl-0 pr-0 justify-content-between align-item-center">
          <div class=" pl-0 pr-0 col-sm-6 pr-2">
            <div class="">
              <label style="font-weight: 500;">{{ $t('Facebook') }}</label>
              <div class='position-relative'>
                <input style="background: #F6F4F0;border: none;font-size: calc(7px + 0.4vw);" v-model="form.Facebook" :disabled='isLoading' type="Facebook" class="paddlef sjbxzt form-control srk pl-5" placeholder="http://">
                <img class="position-absolute" style="width: calc(15px + 0.4vw);left: 14px;top: 20%;" src="@/img/grzx/icon_grzx_lj_three@2x.png" alt="" />
              </div>
            </div>
          </div>
          <div class=" pl-0 pr-0 col-sm-6 pl-1 pr-1">
            <div class="">
              <label style="font-weight: 500;">{{ $t('X') }}</label>
              <div class='position-relative'>
                <input style="background: #F6F4F0;border: none;font-size: calc(7px + 0.4vw);" v-model="form.X" :disabled='isLoading' type="X" class="paddlef form-control sjbxzt srk pl-5" placeholder="http://">
                <img class="position-absolute" style="width: calc(15px + 0.4vw);left: 14px;top: 20%;" src="@/img/grzx/icon_grzx_lj_four@2x.png" alt="" />
              </div>
            </div>
          </div>
        </div>
        <div class="col-sm-12 d-flex mb-3 pl-0 pr-0 justify-content-between align-item-center">
          <div class=" pl-0 pr-0 col-sm-6 pr-2">
            <div class="">
              <label style="font-weight: 500;">{{ $t('treads') }}</label>
              <div class='position-relative'>
                <input style="background: #F6F4F0;border: none;font-size: calc(7px + 0.4vw);" v-model="form.treads" :disabled='isLoading' type="treads" class="paddlef form-control sjbxzt srk pl-5" placeholder="http://">
                <img class="position-absolute" style="width: calc(15px + 0.4vw);left: 14px;top: 20%;" src="@/img/grzx/icon_grzx_lj_five@2x.png" alt="" />
              </div>
            </div>
          </div>
          <div class=" pl-0 pr-0 col-sm-6 pr-2">
            <div class="">
              <label style="font-weight: 500;">Line</label>
              <div class='position-relative'>
                <input style="background: #F6F4F0;border: none;font-size: calc(7px + 0.4vw);" v-model="form.line" :disabled='isLoading' type="treads" class="paddlef sjbxzt form-control srk pl-5" placeholder="http://">
                <img class="position-absolute" style="width: calc(15px + 0.4vw);left: 14px;top: 20%;" src="@/img/icon_grzx_lj_six@2x.png" alt="" />
              </div>
            </div>
          </div>
        </div>
        <div @click="bulletframe()" class="pl-4 pr-4 pt-2 pb-2 yuanjiao sjbzt ckqbb curpo mt-3" style="font-size: calc(6px + 0.4vw);background: #FFD672;width: calc(120px + 0.5vw);text-align: center;">
          {{ $t('保存更改') }}
          <span v-if="isLoading" class="spinner-border spinner-border-sm ml-2" role="status" aria-hidden="true">
          </span>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import { EventBus } from "@/views/event-bus.js";
import { sendcode, sendcodeemilcheck_sms,
  check_email, } from "@/request/api.js";
import {
  region_list,
  organization_info,
  organization_step1_save,
  
} from "@/request/zhbd/index.js";
export default {
  components: {},
  data() {
    return {
		yzmqr: 1,
      emyzmqr: 1,
      yhmjzsr:false,
      ncjzsr:false,
      quyulist: [],
      xianslist: [],
      guojialist: [],
      yhmxzsr:"",
      ggyx: true,
      gghm: true,
      isLoading: false,
      emilError: "",
      phoneError: "",
      time: "",
      timeTrue: true, //判断验证码
      phonetime: "",
      phonetimeTrue: true, //判断验证码
      gjisDropdownOpen: false,
      xsisDropdownOpen: false,
      qyisDropdownOpen: false,
      guojiaid: "",
      xiansid: "",
      quyuid: "",
      form: {
        yhm: "",
        yhnc: "",
        emil: "",
        phone: "",
        emiluse: "",
        phoneuse: "",
        gj: "",
        xs: "",
        qy: "",
        tybh: "",
        Telegram: "",
        Instagram: "",
        Facebook: "",
        X: "",
        treads: "",
        registration_number: "",
        line:""
      },
    };
  },
  created() {
    this.region_list(0);
    this.organization_info();
  },
  mounted() {},
  computed: {},
  methods: {
    yhncjzsrsjh(){
      const regex = /^09[0-9]{8}$/;
      if (regex.test(this.form.yhnc)) {
        this.ncjzsr = true;
      }else{
        this.ncjzsr = false;
      }
    },
    jzsrsjih(){
      this.form.yhm = this.form.yhm.trim()
      const regex = /^09[0-9]{8}$/;
      const sjixsz = /[^a-zA-Z0-9]/g;
      if(sjixsz.test(this.form.yhm)||regex.test(this.form.yhm)){
        this.yhmjzsr = true;
        this.yhmxzsr = sjixsz.test(this.form.yhm) ? this.$t("用户名仅限数字与英文！") : this.$t("为了隐私安全，请不用以手机号码作为用户名！")
      }else{
        this.yhmjzsr = false;
      }
    },
	async emyzmsd(e) {
      this.emyzmqr = 1;
      let numberArray = e.split("").map((char) => parseInt(char));
      console.log(numberArray.length);
      if (numberArray.length >= "6") {
        console.log(e.slice(0, 6));
        setTimeout(() => {
          this.form.emiluse = e.slice(0, 6);
          check_email({ email: this.form.emil, code: this.form.emiluse }).then(
            (res) => {
              if (res.code == 200) {
                this.$bvToast.toast(this.$t("验证码正确"), {
                  title: this.$t("login.notice"),
                  autoHideDelay: 2000,
                  delay: 5000,
                  appendToast: true,
                  variant: "success",
                });
                this.emyzmqr = 0;
              } else {
                this.$bvToast.toast(this.$t("验证码错误"), {
                  title: this.$t("login.warning"),
                  variant: "danger",
                  autoHideDelay: 5000,
                });
                this.form.emiluse = "";
                this.emyzmqr = 1;
                this.timeTrue = true;
              }
            }
          );
        }, 100);
      }
    },
	async yzmsd(e) {
      this.yzmqr = 1;
      let numberArray = e.split("").map((char) => parseInt(char));
      console.log(numberArray.length);
      if (numberArray.length >= "6") {
        console.log(e.slice(0, 6));
        setTimeout(() => {
          this.form.phoneuse = e.slice(0, 6);
          check_sms({ phone: this.form.phone, code: this.form.phoneuse }).then(
            (res) => {
              if (res.code == 200) {
                this.$bvToast.toast(this.$t("验证码正确"), {
                  title: this.$t("login.notice"),
                  autoHideDelay: 2000,
                  delay: 5000,
                  appendToast: true,
                  variant: "success",
                });
                this.yzmqr = 0;
              } else {
                this.$bvToast.toast(this.$t("验证码错误"), {
                  title: this.$t("login.warning"),
                  variant: "danger",
                  autoHideDelay: 5000,
                });
                this.form.phoneuse = "";
                this.yzmqr = 1;
                this.phonetimeTrue = true;
              }
            }
          );
        }, 100);
      }
    },
    async organization_info() {
      const { data } = await organization_info({});
      this.form.yhm = data.info.username;
      this.form.yhnc = data.info.nickname;
      this.form.emil = data.info.email;
      this.form.phone = data.info.phone;
      this.form.tybh = data.info.business_number;
      this.form.Telegram = data.info.telegram;
      this.form.Instagram = data.info.instagram;
      this.form.Facebook = data.info.facebook;
      this.form.X = data.info.x;
      this.form.treads = data.info.treads;
      this.form.registration_number = data.info.registration_number;
      this.guojialist = data.region_list;
      this.xianslist = data.city_list;
      this.quyulist = data.district_list;
      this.guojiaid = data.info.region_id;
      this.xiansid = data.info.city_id;
      this.quyuid = data.info.district_id;
      this.form.line = data.info.line;
      for (let i = 0; i < this.guojialist.length; i++) {
        if (this.guojialist[i].id == this.guojiaid) {
          this.form.gj = this.guojialist[i].name;
        }
      }
      for (let i = 0; i < this.xianslist.length; i++) {
        if (this.xianslist[i].id == this.xiansid) {
          this.form.xs = this.xianslist[i].name;
        }
      }
      for (let i = 0; i < this.quyulist.length; i++) {
        if (this.quyulist[i].id == this.quyuid) {
          this.form.qy = this.quyulist[i].name;
        }
      }
    },
    async region_list(e) {
      const { data } = await region_list({
        parent_id: 0,
      });
      this.guojialist = data.data;
    },
    // 点击
    async bulletframe() {
      if (this.form.yhm == "") {
        this.$bvToast.toast(this.$t("请输入用户名"), {
          title: this.$t("login.warning"),
          variant: "danger",
          autoHideDelay: 5000,
        });
        return;
      } else if (this.form.yhnc == "") {
        this.$bvToast.toast(this.$t("请输入用户暱称"), {
          title: this.$t("login.warning"),
          variant: "danger",
          autoHideDelay: 5000,
        });
        return;
      } else if (this.form.emil == "") {
        this.$bvToast.toast(this.$t("请输入信箱"), {
          title: this.$t("login.warning"),
          variant: "danger",
          autoHideDelay: 5000,
        });
        return;
      } else if (this.form.phone == "") {
        this.$bvToast.toast(this.$t("请输入手机号"), {
          title: this.$t("login.warning"),
          variant: "danger",
          autoHideDelay: 5000,
        });
        return;
      } else if (this.form.tybh == "") {
        this.$bvToast.toast(this.$t("请输入统一编号"), {
          title: this.$t("login.warning"),
          variant: "danger",
          autoHideDelay: 5000,
        });
        return;
      } else if (this.form.registration_number == "") {
        this.$bvToast.toast(this.$t("请输入立案字号"), {
          title: this.$t("login.warning"),
          variant: "danger",
          autoHideDelay: 5000,
        });
        return;
      }
      if (!this.gghm && this.form.phoneuse == "") {
        this.$bvToast.toast(this.$t("请输入手机号的验证码"), {
          title: this.$t("login.warning"),
          variant: "danger",
          autoHideDelay: 5000,
        });
        return;
      } else if (!this.ggyx && this.form.emiluse == "") {
        this.$bvToast.toast(this.$t("请输入信箱的验证码"), {
          title: this.$t("login.warning"),
          variant: "danger",
          autoHideDelay: 5000,
        });
        return;
      }
      this.isLoading = true;
      const { msg, code } = await organization_step1_save({
        username: this.form.yhm,
        nickname: this.form.yhnc,
        phone: this.form.phone,
        sms_code: this.form.phoneuse,
        email: this.form.emil,
        email_code: this.form.emiluse,
        region_id: this.guojiaid,
        city_id: this.xiansid,
        district_id: this.quyuid,
        registration_number: this.form.registration_number,
        business_number: this.form.tybh,
        telegram: this.form.Telegram,
        instagram: this.form.Instagram,
        facebook: this.form.Facebook,
        x: this.form.X,
        treads: this.form.treads,
        line:this.form.line
      });
      if (code == 200) {
        this.$bvToast.toast(this.$t("保存/修改成功"), {
          title: this.$t("login.notice"),
          autoHideDelay: 2000,
          delay: 5000,
          appendToast: true,
          variant: "success",
        });
        EventBus.$emit("call-sibling-b-method", "Hello from Sibling A!");
        this.phonetimeTrue = true;
        this.timeTrue = true;
        this.ggyx = true;
        this.gghm = true;
        setTimeout(() => {
          this.isLoading = false; // 处理完成后关闭转圈
        }, 2000);
      } else {
        this.$bvToast.toast(msg, {
          title: this.$t("login.warning"),
          variant: "danger",
          autoHideDelay: 5000,
        });
        this.isLoading = false; // 处理完成后关闭转圈
      }
    },
    // 校验验证码
    validateemil() {
		this.form.emiluse = "";
      this.emyzmqr = 1;
      this.timeTrue = true;
      const regex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
      if (!regex.test(this.form.emil)) {
        this.emilError = this.$t("请输入正确邮箱");
      } else {
        this.emilError = "";
      }
      if (this.form.emil == "") {
        this.emilError = "";
      }
    },
    // 获取验证码
    async obtainCode() {
      if (this.emilError != "") {
        return;
      } else if (this.form.emil == "") {
        this.$bvToast.toast(this.$t("login.qsryx"), {
          title: this.$t("login.warning"),
          variant: "danger",
          autoHideDelay: 5000,
        });
        return;
      }
      let _this = this;
      this.$bvToast.toast(this.$t("login.verification_success"), {
        title: this.$t("login.notice"),
        autoHideDelay: 2000,
        delay: 5000,
        appendToast: true,
        variant: "success",
      });
        this.acquire();
      const { msg, code } = await sendcodeemil({
        email: this.form.emil,
      });
      if (code == 200) {
       
      } else {
        this.$bvToast.toast(msg, {
          title: this.$t("login.warning"),
          autoHideDelay: 2000,
          delay: 5000,
          appendToast: true,
          variant: "danger",
        });
      }
    },
    // 验证码获取成功
    acquire() {
      this.timeTrue = false;
      this.time = 60;
      var setTimeoutS = setInterval(() => {
        this.time--;
        if (this.time <= 0) {
          clearInterval(setTimeoutS);
          this.timeTrue = true;
        }
      }, 1000);
    },
    // 校验验证码
    validatePhone() {
      this.form.phoneuse = "";
      this.yzmqr = 1;
      this.phonetimeTrue = true;
      const regex = /^09[0-9]{8}$/;
      if (!regex.test(this.form.phone)) {
        this.phoneError = this.$t("请输入正确手机号");
      } else {
        this.phoneError = "";
      }
      if (this.form.phone == "") {
        this.phoneError = "";
      }
    },
    // 获取验证码
    async phoneobtainCode() {
      if (this.phoneError != "") {
        return;
      } else if (this.form.phone == "") {
        this.$bvToast.toast(this.$t("login.qsryx"), {
          title: this.$t("login.warning"),
          variant: "danger",
          autoHideDelay: 5000,
        });
        return;
      }
      this.$bvToast.toast(this.$t("login.verification_success"), {
        title: this.$t("login.notice"),
        autoHideDelay: 2000,
        delay: 5000,
        appendToast: true,
        variant: "success",
      });
      this.phoneacquire();
      const { msg, code } = await sendcode({
        phone: this.form.phone,
      });
      if (code == 200) {
      } else {
        this.$bvToast.toast(msg, {
          title: this.$t("login.warning"),
          autoHideDelay: 2000,
          delay: 5000,
          appendToast: true,
          variant: "danger",
        });
      }
    },
    // 验证码获取成功
    phoneacquire() {
      this.phonetimeTrue = false;
      this.phonetime = 60;
      var setTimeoutS = setInterval(() => {
        this.phonetime--;
        if (this.phonetime <= 0) {
          clearInterval(setTimeoutS);
          this.phonetimeTrue = true;
        }
      }, 1000);
    },
    async gjxuanz(e, v) {
      this.form.gj = e;
      this.guojiaid = v;
      const { data } = await region_list({
        parent_id: v,
      });
      this.xianslist = data.data;
      this.quyuid = "";
      this.form.qy = "";
      this.quyulist = [];
      this.gjisDropdownOpen = false;
    },
    gjtoggleDropdown() {
      this.gjisDropdownOpen = !this.gjisDropdownOpen;
      this.xsisDropdownOpen = false;
      this.qyisDropdownOpen = false;
      // 如果需要，可以在这里添加Bootstrap Dropdown的额外处理逻辑
    },
    async xsxuanz(e, v) {
      this.form.xs = e;
      this.xiansid = v;
      const { data } = await region_list({
        parent_id: v,
      });
      this.quyulist = data.data;
      this.xsisDropdownOpen = false;
    },
    xstoggleDropdown() {
      this.xsisDropdownOpen = !this.xsisDropdownOpen;
      this.gjisDropdownOpen = false;
      this.qyisDropdownOpen = false;
      // 如果需要，可以在这里添加Bootstrap Dropdown的额外处理逻辑
    },
    qyxuanz(e, v) {
      this.form.qy = e;
      this.quyuid = v;
      this.qyisDropdownOpen = false;
    },
    qytoggleDropdown() {
      this.qyisDropdownOpen = !this.qyisDropdownOpen;
      this.gjisDropdownOpen = false;
      this.xsisDropdownOpen = false;
      // 如果需要，可以在这里添加Bootstrap Dropdown的额外处理逻辑
    },
  },
};
</script>

<style scoped>
@media screen and (max-width: 991px) {
	.sjbzt {
    font-size: calc(11px + 0.4vw) !important;
  }
  .sjbxzt {
    font-size: calc(9px + 0.4vw) !important;
  }
  .paddlef{
    padding-left: 40px !important;
  }
  .heggs {
    height: 60vh !important;
  }
}
.dropdown-menu {
	min-width: calc(25px + 0.5vw) !important;
}
/* 隐藏水平滚动条 */
.parent::-webkit-scrollbar {
  display: none;
}

textarea.form-control {
  overflow-y: auto !important;
}

.dcbd:hover {
  transform: scale(1.2);
}

.ckqbb:hover {
  transform: scale(1.001);
  /* 悬浮时放大1.1倍 */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.xlcd {
  display: block;
  background: #ffebd2;
  border: none;
}

/* 隐藏水平滚动条 */
.xlcd::-webkit-scrollbar {
  display: none;
}

.xuazz:hover {
  background: #ffebd2;
}

.zhankai {
  background: #ffebd2;
  text-align: center;
  border-radius: 12px 12px 12px 12px;
  border: none;
  font-weight: 400;
  font-size: calc(6px + 0.4vw);
  color: #1a1a1a;
}

textarea.form-control {
  overflow-y: auto !important;
}

.yzm {
  height: 27px;
  line-height: 27px;
  text-align: center;
  font-weight: 400;
  font-size: 12px;
  color: #1a1a1a;
  background: #ffd672;
  border-radius: 12px;
  padding: 0 12px;
}
</style>