import request from '../index.js'

// 获取临期活动
export const expiry = (params) => request.get("/activity/expiry", { params });
// 活动日程
export const calendar = (params) => request.get("/activity/calendar", { params });
// 活动列表
export const activity_list = (params) => request.get("/activity/list", { params });
// 申请参与活动
export const activity_apply = (params) => request.get("/activity/apply", { params });
// 申请参与活动所需基本数据
export const apply_base = (params) => request.get("/activity/apply_base", { params });
// 活动详情
export const activity_detail = (params) => request.get("/activity/detail", { params });
// 活动日程
export const activity_schedule = (params) => request.get("/activity/schedule", { params });
// 活动数据
export const activity_data = (params) => request.get("/activity/data", { params });
// 评价列表
export const review_list = (params) => request.get("/activity/review_list", { params });
// 评价提交
export const submit_review = (params) => request.get("/activity/submit_review", { params });
// 活动成员
export const activity_member = (params) => request.get("/activity/member", { params });
// 团队管理员
export const group_admin = (params) => request.get("/activity/admin", { params });

// 好事团体-获取全部活动
export const group_allactive = (params) => request.get("/group/all_activity", { params });
// 删除评价
export const activity_review_del = (params) => request.get("/activity/review_del", { params });
 

