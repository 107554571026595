<template>
  <div>
    <!-- 骨架屏 -->
    <skeleton-screen v-if="!dataLoaded"></skeleton-screen>
    <div v-if="dataLoaded" class="mt-3 mb-2" style="font-size: calc(8px + 0.4vw);font-weight: 700;"><span v-if="show == 0">{{ $t('捐款团体列表')}}</span></div>
    <div v-if="dataLoaded" class="col-md-12 pl-0 pr-0">
      <div v-if="show == 0" class="col-md-12 pl-0 pr-0">
        <empty v-if="ishow"></empty>
        <div class="col-md-12 pl-0 pr-0 position-relative d-flex pl-0 pr-0 justify-content-between align-items-center" v-else>
          <!-- :disabled="currentIndex <= 0" -->
          <!-- <div class="col-auto position-absolute pl-0 pr-0 dcbd curpo xsdann" style="z-index: 99999;left: 0;" @click="changePage(currentPage - 1)">
            <img style="width: calc(23px + 0.5vw);" src="@/img/details/icon_jklb_xiangzuo@2x.png" alt="" />
          </div> -->
          <div class="col-md-12 pl-0 pr-0">
            <div class=" col-md-12 pl-0 pr-0 mt-3 lieb yinc" style="display: flex;flex-wrap: wrap;gap: 1%;">
              <div class="sjliebkd" style="width: 24%;" v-for="subItem in items" :key="subItem.id">
                <div class="col-sm-12 baise mb-3 ckqbb p-3 yuanjiao pl-0 pr-0 curpo" style="width: 100%;text-align: center;">
                  <img @click="fun(1, subItem.id)" class="yuanjiao" style="width: 100%;height: calc(170px + 0.4vw);object-fit: cover;" :src="subItem.image" alt="" />
                  <div @click="fun(1, subItem.id)" class="mt-3" style="font-size: calc(8px + 0.4vw);font-weight: 700;"><span style="color: #FF797A;">{{ subItem.name }}</span> </div>
                  <div @click="fun(1, subItem.id)" class="mt-2 ellipsis col-md-12 pl-0 pr-0 mb-2 col-sm-12 col-lg-9" style="font-size: calc(6px + 0.4vw);width: calc(200px + 0.5vw);margin: auto;">
                    {{ subItem.organization_name }}
                  </div>
                  <div @click="fun(1, subItem.id)" class="col-sm-12 d-flex justify-content-between pl-0 pr-0" style="width: 100%;">
                    <div class="col-sm-4 text-center pl-0 pr-0" style="font-size: calc(5px + 0.4vw);">
                      <div style="color: #999999;">{{ $t('已使用捐款')}}</div>
                      <div style="font-weight: 500;">{{ subItem.used_amount ? subItem.used_amount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') : '0' }}</div>
                    </div>
                    <div class="col-sm-4 text-center pl-0 pr-0" style="font-size: calc(5px + 0.4vw);">
                      <div style="color: #999999;">{{ $t('已收到捐款')}}</div>
                      <div style="font-weight: 500;">{{ subItem.received_amount ? subItem.received_amount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') : '0' }}</div>
                    </div>
                    <div class="col-sm-4 text-center pl-0 pr-0" style="font-size: calc(5px + 0.4vw);">
                      <div style="color: #999999;">{{ $t('累计捐款人次')}}</div>
                      <div style="font-weight: 500;">{{ subItem.donor_num ? subItem.donor_num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') : '0' }}</div>
                    </div>
                  </div>
                  <div class="col-sm-12 pl-0 pr-0 d-flex justify-content-between mt-3 align-items-center" style="width: 100%;">
                    <div @click="fun(2, subItem.id)" class="col-sm-4 pl-0 pr-0 ckqbb curpo d-flex yuanjiao pl-2 pr-2 mr-2 pt-2 pb-2 align-items-center" style="background: #FDDDC9;text-align: center;border-radius: 15px;">
                      <div style="font-size: calc(4px + 0.4vw);text-align: center;width: 100%;">
                        {{ $t('资金使用')}}</div>
                      <img style="width: calc(2px + 0.2vw);height:calc(3px + 0.3vw);" src="@/img/details/icon_right@2x.png" alt="" />
                    </div>
                    <!-- 动态进度条 -->
                    <div @click="fun(1, subItem.id)" class="yuanjiao col-sm-8 pl-0 pr-0" style="background-color: #F6F4F0;height: calc(15px + 0.4vw);">
                      <div class="progress-bar yuanjiao jdtt" role="progressbar" :style="{'width': subItem.received_amount ?  Math.floor(subItem.used_amount / subItem.received_amount * 100) + '%' : 0 + '%'}" aria-valuemin="0" aria-valuemax="100"></div>
                      <div style="position: absolute;right: 5%;top: 0;font-size: calc(8px + 0.4vw);">{{ subItem.received_amount ? Math.floor(subItem.used_amount / subItem.received_amount  * 100) : 0 }}%</div>

                    </div>
                  </div>
                  <div class="col-sm-12 pl-0 pr-0 d-flex justify-content-between mt-3 align-items-center">
                    <div @click="fun(1, subItem.id)" class="mr-2 col-sm-4 pl-0 pr-0 ckqbb curpo d-flex yuanjiao pl-2 pr-2 pt-2 pb-2 align-items-center" style="background: #F6F4F0;">
                      <div class="mr-1" style="font-size: calc(6px + 0.4vw);text-align: center;width: 100%;">
                        {{ $t('查看详情')}}</div>
                    </div>
                    <!-- 动态进度条 -->
                    <div @click="fun(3, subItem.id)" class="col-sm-8 pl-0 pr-0 ckqbb yuanjiao pt-2 pb-2" style="background-color: #FFD672;text-align: center;font-size: calc(7px + 0.4vw);">
                      {{ $t('捐款')}}
                    </div>
                  </div>
                </div>

              </div>
            </div>
            <div class="aaaa" style="width: 100%;">
              <div class="lieb" style="display: flex;flex-wrap: wrap;gap: 1%;width: 100%;">
                <div  style="width: 100%;" v-for="subItem in items" :key="subItem.id">
                  <div class="col-sm-12 baise mb-3 ckqbb p-3 yuanjiao pl-0 pr-0 curpo" style="width: 100%;text-align: center;">
                    <img @click="fun(1, subItem.id)" class="yuanjiao" style="width: 100%;height: calc(170px + 0.4vw);object-fit: cover;" :src="subItem.image" alt="" />
                    <div @click="fun(1, subItem.id)" class="mt-3 sjbzt" style="font-size: calc(8px + 0.4vw);font-weight: 700;"><span style="color: #FF797A;">{{ subItem.name }}</span> </div>
                    <div @click="fun(1, subItem.id)" class="mt-2 sjbxzt ellipsis col-md-12 pl-0 pr-0 mb-2 col-sm-12 col-lg-9" style="font-size: calc(6px + 0.4vw);width: calc(200px + 0.5vw);margin: auto;">
                      {{ subItem.organization_name }}
                    </div>
                    <div @click="fun(1)" class="col-sm-12 d-flex justify-content-between pl-0 pr-0" style="width: 100%;">
                      <div class="col-sm-4 text-center pl-0 pr-0 sjbxzt" style="font-size: calc(5px + 0.4vw);">
                        <div style="color: #999999;">{{ $t('已使用捐款')}}</div>
                        <div style="font-weight: 500;">{{ subItem.used_amount ? subItem.used_amount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') : '0' }}</div>
                      </div>
                      <div class="col-sm-4 text-center pl-0 pr-0 sjbxzt" style="font-size: calc(5px + 0.4vw);">
                        <div style="color: #999999;">{{ $t('已收到捐款')}}</div>
                        <div style="font-weight: 500;">{{ subItem.received_amount ? subItem.received_amount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') : '0' }}</div>
                      </div>
                      <div class="col-sm-4 text-center pl-0 pr-0 sjbxzt" style="font-size: calc(5px + 0.4vw);">
                        <div style="color: #999999;">{{ $t('累计捐款人次')}}</div>
                        <div style="font-weight: 500;">{{ subItem.donor_num ? subItem.donor_num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') : '0' }}</div>
                      </div>
                    </div>
                    <div class="col-sm-12 pl-0 pr-0 d-flex justify-content-between mt-3 align-items-center" style="width: 100%;">
                      <div @click="fun(2, subItem.id)" class="col-sm-4 pl-0 pr-0 ckqbb curpo d-flex yuanjiao pl-2 pr-2 mr-2 pt-2 pb-2 align-items-center" style="background: #FDDDC9;text-align: center;border-radius: 15px;width: 30%;">
                        <div class="sjbxzt" style="font-size: calc(4px + 0.4vw);text-align: center;width: 100%;">
                          {{ $t('资金使用')}}</div>
                        <img style="width: calc(2px + 0.2vw);height:calc(3px + 0.3vw);" src="@/img/details/icon_right@2x.png" alt="" />
                      </div>
                      <!-- 动态进度条 -->
                      <div @click="fun(1, subItem.id)" class="yuanjiao col-sm-8 pl-0 pr-0" style="background-color: #F6F4F0;height: calc(20px + 0.4vw);">
                        <div class="progress-bar yuanjiao jdtt" role="progressbar" :style="{'width': subItem.received_amount ?  Math.floor(subItem.used_amount / subItem.received_amount * 100) + '%' : 0 + '%'}" aria-valuemin="0" aria-valuemax="100"></div>
                        <div class="sjbzt" style="position: absolute;right: 5%;top: 0;font-size: calc(8px + 0.4vw);">{{ subItem.received_amount ? Math.floor(subItem.used_amount / subItem.received_amount  * 100) : 0 }}%</div>

                      </div>
                    </div>
                    <div class="col-sm-12 pl-0 pr-0 d-flex justify-content-between mt-3 align-items-center">
                      <div @click="fun(1, subItem.id)" class="mr-2 col-sm-4 pl-0 pr-0 ckqbb curpo d-flex yuanjiao pl-2 pr-2 pt-2 pb-2 align-items-center" style="background: #F6F4F0;width: 30%;">
                        <div class="mr-1 sjbxzt" style="font-size: calc(6px + 0.4vw);text-align: center;width: 100%;">
                          {{ $t('查看详情')}}</div>
                      </div>
                      <!-- 动态进度条 -->
                      <div @click="fun(3, subItem.id)" class="col-sm-8 pl-0 pr-0 sjbzt ckqbb yuanjiao pt-2 pb-2" style="background-color: #FFD672;text-align: center;font-size: calc(7px + 0.4vw);">
                        {{ $t('捐款')}}
                      </div>
                    </div>
                  </div>

                </div>
              </div>

            </div>
            <!-- </div> -->
          </div>
          <!-- :disabled="currentIndex >= Math.floor((items.length - 1) / 4)" -->
          <!-- <div class="col-auto position-absolute pl-0 pr-0 dcbd curpo xsdann" style="z-index: 99999;right: 0;" @click="changePage(currentPage + 1)">
            <img style="width: calc(23px + 0.5vw);" src="@/img/details/icon_jklb_xiangyou@2x.png" alt="" />
          </div> -->
        </div>
      </div>
    </div>
    <router-view />
    <!-- <ckxq v-if="show == 1" @custom='custom' :ckidss="idss" :istype="isT"></ckxq> -->
    <!-- <zzsy v-if="show == 2" @custom='custom' :ckidss="idss"></zzsy> -->
    <juank v-if="show == 3" @custom='custom' :ckidss="idds"></juank>
  </div>
</template>

<script>
import { EventBus } from "@/views/event-bus.js";
import empty from "@/components/empty/empty.vue";
import { donation_ActiveLis } from "@/request/aapi.js";
import SkeletonScreen from "./SkeletonScreen/xinSkeletonScreen.vue";
// import ckxq from "./wmuc/ckxq.vue";
// import zzsy from "./wmuc/zzsy.vue";
import juank from "./wmuc/juank.vue";
export default {
  components: {
    SkeletonScreen,
    // ckxq,
    // zzsy,
    juank,
    empty,
  },
  data() {
    return {
      dataLoaded: false,
      show: 0,
      currentIndex: 0, // 当前显示的列表索引
      // 用于存储当前应该显示的列表项（按每四个一组分组）
      visibleItems: [],
      currentPage: 1, // 当前页码
      totalPages: 0, // 总页数
      progress: 60,
      items: [
      ],
      idss: null,
      isT: null,
      idds: null,
      organization_id: "",
      ishow: false,
    };
  },
  created() {
    this.organization_id = this.$route.params.id;
     this.updateVisibleItems();
     EventBus.$on("tthdfh", (msg) => {
      // 执行方法，处理接收到的数据
      // this.message = msg;
      // 如果需要，可以在这里调用其他方法
      this.show=0
    });
    EventBus.$on("hsttdonation", (msg) => {
      // 执行方法，处理接收到的数据
      // this.message = msg;
      // 如果需要，可以在这里调用其他方法
            if (this.currentPage < this.last_page) {
          this.currentPage += 1;
          this.updateVisibleItems();
        }
    });
  },
  beforeDestroy() {
    // 组件销毁前移除监听器，防止内存泄漏
    EventBus.$off("tthdfh");
    EventBus.$off("hsttdonation");
  },
  mounted() {
    if(this.$route.path.includes("/donate-use")){
      this.show=2
    }else if(this.$route.path.includes("/donation/")){
      this.show=1
    }
  },
  computed: {},
  methods: {
    // handleScroll(event) {
    //   const target = event.target;
    //   const scrollDistance =
    //     target.scrollHeight - target.scrollTop - target.clientHeight;
    //   if (scrollDistance < 10) {
    //     // 当滚动条距离底部小于10px时，认为是滚动到底部
    //     if (this.currentPage < this.last_page) {
    //       this.currentPage += 1;
    //       this.updateVisibleItems();
    //     }
    //   }
    // },
    // 详情等页面返回按钮
    custom(e) {
      this.show = e;
      this.$emit("custom", e);
    },
    fun(e, a) {
      this.show = e;
      if (e == 1 && a) {
        this.isT = 1;
        this.idss = a;
        this.$router.push({ path: 'donation/' + a,params:{
          isT:1
        }});
      }else if(e==2 && a){
        this.isT = 1;
        this.idss = a;
        this.$router.push({ path: 'donation/' + a + '/donate-use',params:{
          isT:2
        }});
      }else if (e == 3 && a) {
        if (localStorage.getItem("token")) {
        } else {
          localStorage.removeItem("token");
          localStorage.removeItem("user_id");
          localStorage.setItem("previousRoutePath", this.$router.currentRoute);
          this.$router.push("/login");
          return;
        }
        // console.log(a)
        this.idds = a;
      }
      // this.$emit("custom", e);
    },
    updateVisibleItems() {
      // 计算当前应该显示的列表项索引范围
      const data = {
        organization_id: this.organization_id,
        page: this.currentPage,
        order: "",
      };
      donation_ActiveLis(data).then((res) => {
        if (res.code == 200) {
          this.dataLoaded = true;
        }
        this.last_page = res.data.last_page;
        this.totalPages = res.data.total;
        if (this.totalPages == 0) {
          this.ishow = true;
        } else {
          this.ishow = false;
        }
        this.items = [...this.items, ...res.data.data];
      });
    },
  },
};
</script>

<style scoped lang="scss">
@media screen and (max-width: 1200px) {
  .sjliebkd {
    width: 49% !important;
  }
}
@media screen and (max-width: 750px) {
  .sjbzt {
    font-size: calc(11px + 0.4vw) !important;
  }
  .sjbxzt {
    font-size: calc(9px + 0.4vw) !important;
  }
  .aaaa {
    display: block !important;
  }
  .yinc {
    display: none !important;
  }
}
.aaaa {
  display: none;
}
.ckqbb:hover {
  transform: scale(1.001);
  /* 悬浮时放大1.1倍 */
  // padding: 12px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.dcbd:hover {
  transform: scale(1.2);
  /* 悬浮时放大1.1倍 */
  // padding: 12px;
}
/* 隐藏水平滚动条 */
.lieb::-webkit-scrollbar {
  display: none;
}
.jdtt {
  height: 100%;
  background: #fab1ab;
  color: #1a1a1a;
  text-align: right;
  font-size: calc(6px + 0.4vw);
}
</style>