import Vue from "vue";
import VueRouter from "vue-router";
import index from "../views/index.vue";
import home from "@/views/index/home.vue";
import group from "@/views/index/group.vue";
import groupdetails from "@/views/details/groupdetails.vue";
import enterprise from "@/views/index/enterprise.vue";
import enterprisedetails from "@/views/details/enterprisedetails.vue";
import activities from "@/views/index/activities.vue";
import hdongdetails from "@/views/details/hdongdetails.vue";
import jkhd from "@/views/index/jkhd.vue";
import hsgdb from "@/views/index/hsgdb.vue";
import grzx from "@/views/index/grzx.vue";
import zhbd from "@/views/index/zhbd.vue";
import variousReminders from "@/views/index/variousReminders.vue";
import yhzcysxy from "@/views/yhzcysxy.vue";
import brdgrzx from "@/views/index/brdgrzx.vue";
import joinactiv from "@/views/index/joinactiv";
import register from "@/views/register";
import ttdonation from "@/components/haosttd/juankuan.vue";
import tttuyaqiang from "@/components/haosttd/tuyaqiang.vue";
import tuabduihd from "@/components/haosttd/tuabduihd.vue";
import yuygy from "@/components/haosttd/yuygy.vue";
import guanywm from "@/components/haosttd/guanywm.vue";
import tuanduicy from "@/components/haosttd/tuanduicy.vue";
import esgphb from "@/components/haosttd/esgphb.vue";
import wzzc from "@/components/haosttd/wzzc.vue";
import ttckxq from "@/components/haosttd/wmuc/ckxq.vue";
import ttzzsy from "@/components/haosttd/wmuc/zzsy.vue";
import ckxq from "@/components/jkhd/wmuc/ckxq.vue";
import zzsy from "@/components/jkhd/wmuc/zzsy.vue";
import qytuyaqiang from "@/components/qiye/tuyaqiang.vue";
import qyguanywm from "@/components/haosttd/guanywm.vue";
import qytuabduihd from "@/components/qiye/tuabduihd.vue";
import qytuanduicy from "@/components/qiye/tuanduicy.vue";
import hshdhdzx from "@/components/hodong/hdzx.vue";
import hdsjian from "@/components/hodong/hdsjian.vue";
import hdtuyaqiang from "@/components/hodong/tuyaqiang.vue";
import hdsj from "@/components/hodong/hdsj.vue";
import hdhduicy from "@/components/hodong/hduicy.vue";
import hdhk from "@/components/hodong/hdhk.vue";
import yincger from "@/views/index/gerkbai.vue";
import supplies from "@/components/haosttd/supplies.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "index",
    component: index,
    children: [
      {
        path: "/",
        name: "home",
        component: home,
      },
      {
        path: "groups",
        name: "group",
        component: group,
      },
      {
        path: "groups/:id/:slug",
        name: "groupdetails",
        component: groupdetails,
        children: [
          {
            path: "the_wall",
            name: "tttuyaqiang",
            component: tttuyaqiang,
          },
          {
            path: "donation",
            name: "ttdonation",
            component: ttdonation,
            children: [
              {
                path: ":ckxq",
                name: "ttckxq",
                component: ttckxq,
              },
              {
                path: ":zjsy/donate-use",
                name: "ttzzsy",
                component: ttzzsy,
              },
            ],
          },
          {
            path: "activity",
            name: "tuabduihd",
            component: tuabduihd,
          },
          {
            path: "donate-supplies",
            name: "supplies",
            component: supplies,
          },
          {
            path: "share-car",
            name: "yuygy",
            component: yuygy,
          },
          {
            path: "about-us",
            name: "guanywm",
            component: guanywm,
          },
          {
            path: "members",
            name: "tuanduicy",
            component: tuanduicy,
          },
          {
            path: "rank",
            name: "esgphb",
            component: esgphb,
          },
          {
            path: "apply-supplies",
            name: "wzzc",
            component: wzzc,
          },
        ],
      },
      {
        path: "enterprises",
        name: "enterprise",
        component: enterprise,
      },
      {
        path: "enterprises/:id/:slug",
        name: "enterprisedetails",
        component: enterprisedetails,
        children: [
          {
            path: "the_wall",
            name: "qytuyaqiang",
            component: qytuyaqiang,
          },
          {
            path: "about-us",
            name: "qyguanywm",
            component: qyguanywm,
          },
          {
            path: "activity",
            name: "qytuabduihd",
            component: qytuabduihd,
          },
          {
            path: "members",
            name: "qytuanduicy",
            component: () =>
              import(
                /* webpackChunkName: "about" */ "@/components/qiye/tuanduicy.vue"
              ),
          },
        ],
      },
      {
        path: "activity",
        name: "activities",
        component: activities,
      },
      {
        path: "activity/:leix/:slug/:id",
        name: "hdongdetails",
        component: hdongdetails,
        children: [
          {
            path: "info",
            name: "hshdhdzx",
            component: hshdhdzx,
          },
          {
            path: "schedule",
            name: "hdsjian",
            component: hdsjian,
          },
          {
            path: "the_wall",
            name: "hdtuyaqiang",
            component: hdtuyaqiang,
          },
          {
            path: "data-analysis",
            name: "hdsj",
            component: hdsj,
          },
          {
            path: "members",
            name: "hdhduicy",
            component: hdhduicy,
          },
          {
            path: "feedback",
            name: "hdhk",
            component: hdhk,
          },
        ],
      },
      {
        path: "donation",
        name: "jkhd",
        component: jkhd,
      },
      {
        path: "donation/:ckxq",
        name: "ckxq",
        component: ckxq,
      },
      {
        path: "donation/:zjsy/donate-use",
        name: "zzsy",
        component: zzsy,
      },
      {
        path: "members/point-logs",
        name: "hsgdb",
        component: hsgdb,
        meta: { requiresAuth: true },
      },
      {
        path: "member/:mason",
        name: "grzx",
        component: grzx,
        // meta: { requiresAuth: true },
      },
      {
        path: "/members/",
        name: "yincger",
        component: yincger,
        // meta: { requiresAuth: true },
      },

      {
        path: "members/settings",
        name: "zhbd",
        component: zhbd,
        meta: { requiresAuth: true },
      },
      {
        path: "variousReminders",
        name: "variousReminders",
        component: variousReminders,
      },
      {
        path: "members/:mason",
        name: "brdgrzx",
        component: brdgrzx,
      },
      {
        path: "join",
        name: "join",
        component: joinactiv,
      },
    ],
  },
  {
    path: "/login",
    name: "login",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/login.vue"),
  },
  {
    path: "/register",
    name: "register",
    component: register,
  },
  {
    path: "/shefuzhuc",
    name: "shefuzhuc",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/shefuzhuc.vue"),
  },
  {
    path: "/yhzcysxy",
    name: "yhzcysxy",
    component: yhzcysxy,
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

// 前置守卫(guard)
router.beforeEach((to, from, next) => {
  // document.title = '好事道';

  const isAuthenticated = localStorage.getItem("token");
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    if (isAuthenticated) {
      if(from.fullPath=='/register' || from.fullPath=='/login' || from.fullPath=='/shefuzhuc'){

      }else{
        localStorage.setItem("redirect",from.fullPath);
      }
      
      next();
      return;
    } else {
      if(from.fullPath=='/register' || from.fullPath=='/login' || from.fullPath=='/shefuzhuc'){

      }else{
        localStorage.setItem("redirect",from.fullPath);
      }
      next("/login");
      return;
    }
  } else {
    if(from.fullPath=='/register' || from.fullPath=='/login' || from.fullPath=='/shefuzhuc'){

    }else{
      localStorage.setItem("redirect",from.fullPath);
    }
    next();
  }
  //  //页面跳转
});

export default router;
