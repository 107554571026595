<template>
  <div>
    <!-- 骨架屏 -->
    <skeleton-screen v-show="!dataLoaded"></skeleton-screen>
    <div v-if="dataLoaded" class="col-md-12 col-sm-12 col-lg-12 col-xl-12 d-flex pl-0 pr-0 ml-0 justify-content-between">
      <div class="col-md-12 col-sm-12 col-xl-9 pl-0 pr-0 ybyc heggs lieb" style="height: 83vh;overflow-y: auto;" @scroll="handleScroll">
        <div class="col-md-12 pl-0 pr-0 sjitop">
          <div class="col-md-12 pl-0 pr-0 pb-3 d-flex justify-content-between">
            <div class="col-sm-4 pl-0 pr-0 position-relative d-flex align-items-center sjbsrkkd">
              <input class="form-control sxbj col-md-12 inppt pr-5 sjbxzt" v-model="keywords" type="text" @input="search()" :placeholder="this.$t('搜寻')" plaintext='true' aria-label="Username" aria-describedby="basic-addon1">
              <img style="right: 20px;width: calc(10px + 0.5vw);" class="position-absolute curpo sbxfbd" src="@/img/daohangl/icon_hssy_sousuo@2x.png" @click="search()" alt="" />
            </div>
            <div class="col-sm-5 d-flex align-items-center d-flex pl-0 pr-0" style="justify-content: flex-end;">

              <div class="pl-0 pr-0 position-relative" style="margin-right: calc(10px + 0.5vw);">
                <!-- 触发Dropdown的按钮 -->
                <button class="d-flex ckqbb zhankai dydpd" type="button" id="dropdownMenuButton" @click="toggleDropdown">
                  <div class="d-flex align-items-center">
                    <div class="sjbxzt" style="font-size: calc(6px + 0.4vw);margin-right: calc(5px + 0.5vw);">{{
											order ? tdjhuxa : $t('全部') }}
                    </div>
                    <img style="width: calc(7px + 0.4vw);" src="@/img/daohangl/icon_xlcd_xljt@2x (1).png" alt="" />
                  </div>
                </button>
                <!-- Dropdown内容，使用v-show控制显示 -->
                <div class="dropdown-menu yuanjiao xlcd position-absolute lieb" aria-labelledby="dropdownMenuButton" v-show="isDropdownOpen" style="border-radius:7px;left:-20%;background: #FFFAF0;max-height: 200px;overflow-y: auto;">
                  <a class="dropdown-item yuanjiao mb-2 sjbxzt" v-for="(item, index) in orderList" :key="index" :style="{ 'background': order == item.value ? '#FFD672' : '' }" @click="tdxuanz(item)" href="#">{{ item.til }}</a>
                </div>
              </div>
              <div class="pl-0 pr-0 position-relative">
                <!-- 触发Dropdown的按钮 -->
                <button class="d-flex ckqbb zhankai dydpd" type="button" id="dropdownMenuButtonm" @click="toggleDropdownm">
                  <div class="d-flex align-items-center">
                    <div class="sjbxzt" style="font-size: calc(6px + 0.4vw);margin-right: calc(10px + 0.5vw);">{{
											jhuxa ? jhuxa :$t("企业类型") }}
                    </div>
                    <img style="width: calc(7px + 0.4vw);" src="@/img/daohangl/icon_xlcd_xljt@2x (1).png" alt="" />
                  </div>
                </button>
                <!-- Dropdown内容，使用v-show控制显示 -->
                <div class="dropdown-menu yuanjiao xlcd position-absolute lieb" aria-labelledby="dropdownMenuButtonm" v-show="istoggleDropdownm" style="border-radius:7px;background: #FFFAF0;max-height: 200px;overflow-y: auto;">
                  <a class="dropdown-item yuanjiao mb-2 sjbxzt" v-for="(item, index) in typeList" :key="index" :style="{ 'background': category_id == item.id ? '#FFD672' : '' }" @click="xuanz(item)" href="#">{{ item.name }}</a>
                </div>
              </div>

            </div>
          </div>
        </div>
        <!-- 列表 -->
        <div class="col-md-12 pl-0 pr-0">
          <div class="col-md-12 pl-0 pr-0">
            <!-- 使用v-for遍历items数组，并通过div包裹每个元素，应用flex工具类 -->
            <div class=" d-flex justify-content-between" style="flex-wrap: wrap;">
              <!-- 当前元素和下一个元素（如果存在） -->
              <!-- {{ items[index].name }}  渲染格式-->
              <div class="baise mb-3 ckqbb p-3 yuanjiao curpo sjibanyihang" style="width: 49%;" v-for="(item, index) in infList" :key="index">

                <img @click="tiaozhuan(item.id,item)" style="width: 100%;height: 200px;object-fit: cover;" :src="item.cover_image" alt="" />

                <div @click="tiaozhuan(item.id,item)" class="mt-3 sjbzt" style="font-size: calc(6px + 0.4vw);color: #FF797A;">{{ item.category_name ?
										item.category_name : $t('暂无') }}</div>
                <div @click="tiaozhuan(item.id,item)" class="mt-2 sjbzt" style="font-size: calc(8px + 0.4vw);font-weight: 700;">
                  {{ item.name ? item.name : $t('暂无') }}
                </div>
                <div @click="tiaozhuan(item.id,item)" class="mt-2 ellipsis-multiline sjbxzt" style="font-size: calc(6px + 0.4vw);color: #666666;">
                  {{ item.introduction ? item.introduction : $t('暂无') }}
                </div>
                <div @click="tiaozhuan(item.id,item)" class="mt-3 mb-3" style="width: 100%;height: 1px;background: #E5E5E5;"></div>
                <div class="d-flex justify-content-between align-items-center">
                  <div @click="tiaozhuan(item.id,item)" class="ml-2" style="display: flex;">
                    <img v-for="(ite, ind) in item.member" :key="ind" style="margin-left: -15px;width: calc(32px + 0.4vw);z-index: 99;height: calc(32px + 0.4vw);border-radius: 50%;" :src="ite.avatar" alt="" />
                    <div class="yiuanjiaos sjbxzt">+{{ item.member_num }}</div>
                  </div>

                  <div v-if="item.is_member == 1" @click="bulletframe(item, 2)" class="pl-3 pr-3 pt-2 pb-2 yuanjiao curpo sjbzt ckqbb" style="font-size: calc(6px + 0.4vw);background: #eee;">
                    {{ $t("退出企业") }}
                  </div>
                  <div v-if="item.is_member == 3" @click="bulletframe(item, 2)" class="pl-3 pr-3 pt-2 pb-2 yuanjiao curpo sjbzt ckqbb" style="font-size: calc(6px + 0.4vw);background: #FAB1AB;">
                    申请中
                  </div>
                  <div v-if="item.is_member != 1 && item.is_member != 3" @click="bulletframe(item, 1)" class="pl-3 pr-3 pt-2 sjbzt pb-2 yuanjiao curpo ckqbb" style="font-size: calc(6px + 0.4vw);background: #FFD672;">
                    {{ $t("加入企业") }}
                  </div>
                </div>
              </div>

            </div>
          </div>

          <!-- 加载更多提示 -->
          <div class="d-flex justify-content-center">
            <!-- <div v-if="isLoading" class="loading">{{ $t("加载中...") }}</div> -->
            <div v-if="infList.length == 0">

              <empty></empty>

            </div>
          </div>

        </div>
      </div>
      <div class="yuanjiao col-xl-3 yinc ml-0 pr-0">
        <div class="baise col-md-12 pt-3 pb-0 yuanjiao pl-0 pr-0" style="height: 410px;">
          <div class="mb-2 d-flex justify-content-between ml-3 mr-3" style="font-size: calc(8px + 0.4vw);font-weight: 500;">
            <div>{{ $t("活动列表") }}</div>
            <div @click="funs()" class="ckq curpo" style="color: #FFD672;">{{ $t("查看全部") }}</div>
          </div>
          <div class="mb-2 ml-3 mr-3 col-md-11" style="height: 1px;background: #E5E5E5;"></div>
          <div v-show="index <= 4" @click="funs(item)" v-for="(item, index) in actLis" :key="index" class="d-flex curpo ckqbb justify-content-between align-items-center mb-2 xunafuxgio pl-3 pr-3 pt-2 pb-2 yuanjiao">
            <div class="d-flex justify-content-between align-items-center">
              <img class="mr-2" style="width: calc(35px + 0.5vw);height: calc(35px + 0.4vw);border-radius: 50%;object-fit: cover" :src="item.image ? item.image : '@/img/daohangl/pic_hssy_hdlb_tx_one@2x.png'" alt="" />
              <div>
                <div class="ellipsis" style="font-size: calc(7px + 0.4vw);width: calc(125px + 0.5vw);">
                  {{ item.name }}
                </div>
                <div class="d-flex align-items-center mt-1">
                  <div class="d-flex mr-3 align-items-center" style="font-size: calc(6px + 0.4vw);color: #666666;">
                    <img style="width: calc(7px + 0.5vw);height: calc(7px + 0.5vw);" src="@/img/daohangl/icon_hssy_hdlb_tiewen@2x.png" alt="" />
                    <div class="ml-1">{{ item.moment_num ? item.moment_num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') : '0' }}</div>
                  </div>
                  <div class="d-flex align-items-center" style="font-size: calc(5px + 0.4vw);color: #666666;">
                    <img style="width: calc(7px + 0.5vw);height: calc(7px + 0.5vw);" src="@/img/daohangl/icon_hssy_hdlb_renyuan@2x.png" alt="" />
                    <div class="ml-1">{{ item.member_num ? item.member_num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') : '0' }}</div>
                  </div>
                </div>
              </div>
            </div>
            <img style="width: calc(15px + 0.5vw);" src="@/img/daohangl/icon_hssy_hdlb_xiangqing@2x.png" alt="" />
          </div>
        </div>
        <!-- <div class="baise col-md-12 pt-3 pb-0 yuanjiao mt-3 pb-3">
					<div class="mb-2" style="font-size: calc(6px + 0.5vw);font-weight: 500;">
						<div>{{ $t("好事任务") }}</div>
					</div>
					<div class="mb-2" style="height: 1px;width: 100%;background: #E5E5E5;"></div>
					<div v-for="(item, index) in taskLis" :key="index"
						class="d-flex justify-content-between align-items-center mb-3" style="cursor: not-allowed;">
						<div class="d-flex justify-content-between align-items-center">
							<img class="mr-2"
								style="width: calc(35px + 0.5vw);height: calc(35px + 0.4vw);border-radius: 50%;"
								:src="item.image ? item.image : '@/img/daohangl/pic_hssy_hdlb_tx_one@2x.png'" alt="" />
							<div>
								<div style="font-size: calc(5px + 0.4vw);">{{ item.title }}</div>
								<div class="mt-1">
									<div style="font-size: calc(4px + 0.4vw);color: #666666;">
										{{ timeFilters(item.create_time) }}
									</div>
								</div>
							</div>
						</div>
						<div class="pl-2 pr-2 pt-1 pb-1 yuanjiao"
							style="font-size: calc(5px + 0.4vw);color: #666666;font-weight: 500;background: #E5E5E5;">
							{{ $t("展未开放") }}</div>
					</div>
				</div> -->
      </div>

      <b-modal v-model="modalShow" modal-class="custom-modal-width" :no-close-on-backdrop='isLoading' :no-close-on-esc="isLoading" :centered='true' hide-header hide-footer>
        <div style="width: 100%;text-align: center;" class="pb-2 position-relative">
          <img @click="modalShow = false" class="position-absolute curpo sbxfbd" style="width: calc(15px + 0.5vw);right: 20px;top: -20px;" src="@/img/details/cha.png" alt="" />
          <div class="mb-3 mt-4 sjbzt" style="font-size: calc(9px + 0.4vw);font-weight: 700;">{{ isOne == 1
						? $t('请填写你的员工编号') : $t('退出企业') }}</div>
          <div class="p-3 yuanjiao" style="width: 90%;margin: auto;background: #F6F4F0;">
            <div style="font-size: calc(5px + 0.4vw);">
              <input v-if="isOne == 1" style="background: #F6F4F0;border: none;font-size: calc(7px + 0.4vw);" v-model="num_bian" type="number" class="form-control sjbxzt pl-3" :placeholder="$t('请填写你的员工编号')">
              <div v-if="isOne == 2" class="sjbxzt">
                {{ $t('是否确认退出该企业') }}
              </div>
            </div>
          </div>

          <div style="height: calc(10px + 1vw);"></div>

          <button v-if="isOne == 1" :disabled="isLoading" @click="qrbulletframe()" class="pl-5 pr-5 pt-3 pb-3 yuanjiao d-flex sjbzt ckqbb curpo annys" style="font-size: calc(6px + 0.4vw);background: #FFD672;border: none;">
            {{ $t('加入企业') }}
            <span v-if="isLoading" class="spinner-border spinner-border-sm ml-2" role="status" aria-hidden="true">
            </span>
          </button>
          <button v-if="isOne == 2" :disabled="isLoading" @click="qrbulletframe()" class="pl-5 pr-5 pt-3 pb-3 yuanjiao ckqbb sjbzt annys d-flex" style="font-size: calc(6px + 0.4vw);background: #eee;">
            <div>{{ $t("退出企业") }}</div>

            <span v-if="isLoading" class="spinner-border spinner-border-sm ml-2" role="status" aria-hidden="true">
            </span>
          </button>

        </div>
      </b-modal>
    </div>

  </div>
</template>

<script>
import { register_category } from "@/request/api.js";

import { timeFilter } from "@/utils/common.js";
import {
  company_Lis,
  activ_Lis,
  comp_join,
  comp_leav,
} from "@/request/aapi.js";
import SkeletonScreen from "./SkeletonScreen/groupSkeletonScreen.vue";
import empty from "@/components/empty/empty.vue";
export default {
  components: {
    SkeletonScreen,
    empty,
  },
  data() {
    return {
      dataLoaded: false,
      isDropdownOpen: false, // 控制Dropdown的显示
      istoggleDropdownm: false,
      modalShow: false,
      isLoading: false,
      jhuxa: this.$t("企业类型"),
      tdjhuxa: this.$t("全部"),
      items: [
        {
          name: 1,
        },
        {
          name: 1,
        },
        {
          name: 1,
        },
        {
          name: 1,
        },
        {
          name: 1,
        },
        {
          name: 1,
        },
      ],
      hdlb: [{}, {}, {}, {}, {}],

      isLoading: false, // 是否正在加载数据
      noMore: false, // 是否没有更多数据
      page: 1, // 当前页码
      cur_page: 10,
      // 列表
      infList: [],
      // 搜寻
      keywords: "",
      // 类别id
      category_id: null,
      order: "",
      // 排序
      orderList: [
        {
          id: 1,
          til: this.$t("全部"),
          value: "",
        },
        {
          id: 2,
          til: this.$t("最新加入"),
          value: "new",
        },
        {
          id: 3,
          til: this.$t("最活跃"),
          value: "active",
        },
        {
          id: 4,
          til: this.$t("成员数升序"),
          value: "member_num_asc",
        },
        {
          id: 5,
          til: this.$t("成员数降序"),
          value: "member_num_desc",
        },
        {
          id: 6,
          til: this.$t("创建时间升序"),
          value: "create_time_asc",
        },
        {
          id: 7,
          til: this.$t("创建时间降序"),
          value: "create_time_desc",
        },
        {
          id: 8,
          til: this.$t("活动数量升序"),
          value: "activity_num_asc",
        },
        {
          id: 9,
          til: this.$t("活动数量降序"),
          value: "activity_num_desc",
        },
      ],
      // 活动信息
      actLis: [],
      // 任务信息
      taskLis: [],
      // 获取团体类型
      typeList: [],
      // 加入企业弹窗内容
      popname: "",
      // 员工编号
      num_bian: "",
      isOne: 1,
    };
  },
  mounted() {
    this.getInfo();
    this.getRegisterCategory();
    this.getAct();
  },
  methods: {
    // 获取团体类型
    getRegisterCategory() {
      register_category({
        type: 2,
      }).then((res) => {
        this.typeList = [
          {
            id: null,
            name: this.$t("企业类型"),
          },
          ...res.data.list,
        ];
      });
    },
    // 时间转换
    timeFilters(stringTime) {
      return formatTimestamp(stringTime * 1000);
    },
    // 活动列表
    getAct() {
      let data = {
        is_activity: 1,
        is_task: 1,
      };
      activ_Lis(data)
        .then((res) => {
          if (res.code == 200) {
            this.dataLoaded = true;
            this.actLis = res.data.activity;
            this.taskLis = res.data.task;
          }
        })
        .catch(() => {});
    },
    // 搜寻
    search() {
      this.page = 1;
      this.getInfo();
    },
    // 滚动
    handleScroll(event) {
      const target = event.target;
      const scrollDistance =
        target.scrollHeight - target.scrollTop - target.clientHeight;
      if (scrollDistance < 10) {
        // 当滚动条距离底部小于10px时，认为是滚动到底部
        if (this.page < this.last_page) {
          this.page += 1;
          this.getInfo()
        }
      }
    },

    // 获取列表
    getInfo() {
      let data = {
        page: this.page,
        category_id: this.category_id,
        keywords: this.keywords,
        order: this.order,
      };
      company_Lis(data)
        .then((res) => {
          // console.log(res, "列表")

          if (res.code == 200) {
            this.infList = [...this.infList, ...res.data.data]
          }
        })
        .catch(() => {});
    },
    funs(e) {
      if (e) {
        let a = e.identity == 2 ? 'groups' : 'enterprises'
        this.$router.push('/activity/' + a + '/' + e.slug + '/' + e.id);
      } else {
        this.$router.push("/activity");
      }
    },
    tiaozhuan(id, a) {
      if (a.is_private == 1 && a.is_member != 1) {
        this.$bvToast.toast(this.$t("请先加入该企业在浏览企业信息！"), {
          title: this.$t("login.notice"),
          autoHideDelay: 2000,
          delay: 5000,
          appendToast: true,
          variant: "danger",
        });
        return;
      } else {
        this.$router.push("/enterprises/" + a.id + '/' + a.slug);
        // this.$router.push(`/enterprisedetails?id=${id}`);
      }
    },
    xuanz(e) {
      this.jhuxa = e.name;
      this.category_id = e.id;
      this.istoggleDropdownm = false;
      this.page = 1;
      this.getInfo();
    },
    tdxuanz(e) {
      this.tdjhuxa = e.til;
      this.order = e.value;
      this.isDropdownOpen = false;
      this.page = 1;
      this.getInfo();
    },
    // 点击确认加入企业
    qrbulletframe() {
      if (this.isOne == 1) {
        var value = this.num_bian.trim(); // 去除首尾空格
        if (!this.num_bian || !/^\+?[1-9]\d*$/.test(value)) {
          this.$bvToast.toast(this.$t("请填写你的员工编号"), {
            title: this.$t("login.notice"),
            autoHideDelay: 2000,
            delay: 5000,
            appendToast: true,
            variant: "danger",
          });
          return;
        }

        this.isLoading = true;
        setTimeout(() => {
          let data = {
            id: this.popname.id,
            // 员工编号
            number: this.num_bian,
          };
          comp_join(data)
            .then((res) => {
              this.$bvToast.toast(res.msg, {
                title: this.$t("login.notice"),
                autoHideDelay: 2000,
                delay: 5000,
                appendToast: true,
                variant: res.code == 200 ? "success" : "danger",
              });
              this.isLoading = false;
              this.modalShow = false;
              if (res.code == 200) {
                this.getInfo();
                this.num_bian = "";
              }
            })
            .catch(() => {});
        }, 2000);
      } else {
        this.isLoading = true;
        comp_leav({
          id: this.popname.id,
        })
          .then((res) => {
            this.$bvToast.toast(res.msg, {
              title: this.$t("login.notice"),
              autoHideDelay: 2000,
              delay: 5000,
              appendToast: true,
              variant: res.code == 200 ? "success" : "danger",
            });
            if (res.code == 200) {
              this.isLoading = false;
              this.modalShow = false;
              this.getInfo();
            }
          })
          .catch(() => {});
      }
    },

    // 点击加入企业
    bulletframe(e, a) {
      this.popname = e;
      // 加入
      if (a == 1) {
        this.isOne = 1;
        this.modalShow = true;
      } else if (a == 2) {
        this.isOne = 2;
        this.modalShow = true;
      }
    },
    toggleDropdown() {
      this.isDropdownOpen = !this.isDropdownOpen;
      this.istoggleDropdownm = false;
      // 如果需要，可以在这里添加Bootstrap Dropdown的额外处理逻辑
    },
    toggleDropdownm() {
      this.istoggleDropdownm = !this.istoggleDropdownm;
      this.isDropdownOpen = false;
      // 如果需要，可以在这里添加Bootstrap Dropdown的额外处理逻辑
    },
  },
};
</script>
<style scoped>
@media (min-width: 376px) {
  .modal-dialog {
    /* max-width: calc(100px + 0.5vw) !important; */
    margin: 1.75rem auto;
  }
}
</style>
<style scoped lang="scss">
@media screen and (max-width: 1208px) {
  .yinc {
    display: none !important;
  }
  .ybyc {
    padding-right: 0px !important;
  }
  .sjbzt {
    font-size: calc(11px + 0.4vw) !important;
  }
  .sjbxzt {
    font-size: calc(9px + 0.4vw) !important;
  }
}
@media (max-width: 992px) {
  .sjitop {
    margin-top: 1rem !important;
  }
  .heggs {
    height: 81vh !important;
  }
}
@media (max-width: 800px) {
  .sjitop {
    margin-top: 1rem !important;
  }
  .sjibanyihang {
    width: 100% !important;
  }
  .sjbsrkkd {
    width: 60% !important;
  }
}
.yiuanjiaos {
  margin-left: -15px;
  width: calc(32px + 0.4vw);
  z-index: 99;
  height: calc(32px + 0.4vw);
  border-radius: 50%;
  background: #fab1ab;
  color: #1a1a1a;
  text-align: center;
  line-height: calc(30px + 0.4vw);
  font-size: calc(6px + 0.4vw);
  border: 1px solid #ffffff;
}
.annys {
  font-size: calc(6px + 0.4vw);
  background: #ffd672;
  // width: 144px;
  margin: auto;
  border: none;
}

.inppt {
  background: white;
  border: none;
  border-radius: 13px;
  font-size: calc(7px + 0.4vw);
  padding-top: 22px;
  padding-bottom: 22px;
}

.zhankai {
  background: #ffebd2;
  text-align: center;
  border-radius: 12px 12px 12px 12px;
  border: none;
  font-weight: 400;
  font-size: calc(6px + 0.4vw);
  color: #1a1a1a;
}

.form-control {
  height: calc(15px + 0.5vw) !important;
}

.sxbj {
  padding-top: calc(15px + 0.5vw);
  padding-bottom: calc(15px + 0.5vw);
}

.ckqbb:hover {
  transform: scale(1.001);
  /* 悬浮时放大1.1倍 */
  // padding: 12px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.ckq:hover {
  transform: scale(1.001);
  /* 悬浮时放大1.1倍 */
  font-weight: 800;
}

.sbxfbd:hover {
  transform: scale(1.1);
}

.dropdown-menu {
	min-width: calc(25px + 0.5vw) !important;
  font-size: calc(8px + 0.4vw) !important;
}

.xlcd {
  display: block;
  background: #ffebd2;
  border: none;
}

/* 隐藏水平滚动条 */
.lieb::-webkit-scrollbar {
  display: none;
}

.ellipsis-multiline {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
  text-overflow: ellipsis;
  min-height: calc(
    1em * 2 + 10px
  ); /* 1em 是行高，你可以根据实际的字体大小调整，10px 是额外的间距，比如内边距或边框等 */
  /* 为了确保在所有情况下都有正确的高度，你可能还需要设置一个固定的行高 */
  line-height: 1.5; /* 例如，1.5 倍的行高 */
  /* 设置一个最大高度，这个高度应该与 min-height 相同，以确保即使内容只有一行也保持两行的高度 */
  max-height: calc(1em * 2 * 1.5 + 10px); /* 这里乘以了 line-height */
  /* 为了在内容溢出时显示省略号 */
}

.dydpd {
  padding-top: calc(7px + 0.4vw);
  padding-bottom: calc(7px + 0.4vw);
  padding-left: calc(10px + 0.5vw);
  padding-right: calc(10px + 0.5vw);
}

.dropdown-item:hover {
  background-color: #ffebd2;
  color: #b66d00;
  border-radius: 12px;
}
</style>