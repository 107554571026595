<template>
  <div>
    <div v-show="txbiaod == 1">
      <skeleton-screen v-if="!dataLoaded"></skeleton-screen>
      <div v-if="dataLoaded" class="col-md-12 col-sm-12 col-lg-12 col-xl-12 pl-0 pr-0 d-flex justify-content-between">
        <div class="col-sm-8 pl-0 pr-0 col-xl-9 yuanjiao pb-3 yinc">
          <div class="col-md-12 mt-3 pl-0 pr-0">
            <div class="col-md-12 yuanjiao pl-0 pr-0 parent" style="font-size: calc(7px + 0.4vw);line-height: calc(15px + 0.5vw);">
              <div class="col-md-12 d-flex pl-0 pr-0 justify-content-between">
                <div class="d-flex pl-0 pr-0">
                  <div @click="fun(0)" class="pt-2 pb-2 curpo qieh" :style="{ 'background': show == 0 ? '#FFFFFF' : '#FFFAF0', 'font-weight': show == 0 ? '700' : '500', 'color': show == 0 ? '' : '#999999' }">
                    {{ $t('所有活动') }} </div>
                  <div @click="fun(1)" class="pt-2 pb-2 curpo qieh" :style="{ 'background': show == 1 ? '#FFFFFF' : '#FFFAF0', 'font-weight': show == 1 ? '700' : '500', 'color': show == 1 ? '' : '#999999' }">
                    {{ $t('志工活动') }}</div>
                </div>
                <div class="col-sm-4 pl-0 pr-0 position-relative d-flex align-items-center">
                  <input @input="shukchange" v-model="inputmodel" class="form-control sxbj col-md-12 inppt pr-5" type="text" :placeholder="this.$t('搜寻')" plaintext='true' aria-label="Username" aria-describedby="basic-addon1">
                  <img style="right: 20px;width: calc(10px + 0.5vw);" class="position-absolute curpo sbxfbd" src="@/img/daohangl/icon_hssy_sousuo@2x.png" alt="" />
                </div>
              </div>
              <div v-if="list.length" class="pl-3 pr-3 col-md-12 baise pt-3 pl-0 pr-0 pb-2 gdt" style="border-radius: 0 0 12px 12px;">
                <div v-show="show == 0" v-for="(item, index) in list" :key="index" style="background: #F6F4F0;" class="yuanjiao  curpo  mb-3 ckqbb pl-3 pr-3 pt-3 pb-3">
                  <div class="d-flex justify-content-between align-items-center">
                    <div @click="huodxiangq(item)" class="d-flex justify-content-between align-items-center">
                      <img class="mr-2" style="width: calc(35px + 0.5vw);height: calc(35px + 0.5vw);border-radius: 50%;object-fit: cover;" :src="item.image" alt="" />
                      <div>
                        <div class="" style="font-size: calc(5px + 0.4vw);">
                          {{ item.name }}
                        </div>
                        <div class="d-flex align-items-center">
                          <div class="d-flex mr-2 align-items-center" style="font-size: calc(4px + 0.4vw);color: #666666;">
                            <img style="width: calc(7px + 0.5vw);height: calc(7px + 0.5vw);" src="@/img/daohangl/icon_hssy_hdlb_tiewen@2x.png" alt="" />
                            <div>{{ item.moment_num ? item.moment_num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') : '0' }}</div>
                          </div>
                          <div class="d-flex align-items-center" style="font-size: 12px;color: #666666;">
                            <img style="width: calc(7px + 0.5vw);height: calc(7px + 0.5vw);" src="@/img/daohangl/icon_hssy_hdlb_renyuan@2x.png" alt="" />
                            <div>{{ item.member_num ? item.member_num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') : '0' }}</div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <button v-if="item.is_member == 0" :disabled="isLoading" @click="chanyughd(item.id, item.name)" class="pl-3 pr-3 pt-1 pb-1 yuanjiao ckqbb annys" style="font-size: calc(5px + 0.4vw);background: #FFD672;border: none;">
                      {{ $t('参加活动') }}
                    </button>
                    <button v-else :disabled="isLoading" class="pl-3 pr-3 pt-1 pb-1 yuanjiao annys" style="font-size: calc(5px + 0.4vw);background: #E5E5E5;border: none;">
                      {{ $t('已报名') }}
                    </button>
                  </div>
                  <div class="mt-2 slhzs" style="font-size: calc(5px + 0.4vw);">
                    {{ item.description }}
                  </div>
                </div>
                <div v-show="show == 1" v-for="(item, index) in list" :key="index" style="background: #F6F4F0;" class="yuanjiao  curpo  mb-3 ckqbb pl-3 pr-3 pt-3 pb-3">
                  <div class="d-flex justify-content-between align-items-center">
                    <div class="d-flex justify-content-between align-items-center">
                      <img @click="huodxiangq(item)" class="mr-2" style="width: calc(35px + 0.5vw);height: calc(35px + 0.5vw);border-radius: 50%;object-fit: cover;" :src="item.image" alt="" />
                      <div>
                        <div class="" style="font-size: calc(5px + 0.4vw);">
                          {{ item.name }}
                        </div>
                        <div class="d-flex align-items-center">
                          <div class="d-flex mr-2 align-items-center" style="font-size: calc(4px + 0.4vw);color: #666666;">
                            <img style="width: calc(7px + 0.5vw);height: calc(7px + 0.5vw);" src="@/img/daohangl/icon_hssy_hdlb_tiewen@2x.png" alt="" />
                            <div>{{ item.moment_num ? item.moment_num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') : '0' }}</div>
                          </div>
                          <div class="d-flex align-items-center" style="font-size: 12px;color: #666666;">
                            <img style="width: calc(7px + 0.5vw);height: calc(7px + 0.5vw);" src="@/img/daohangl/icon_hssy_hdlb_renyuan@2x.png" alt="" />
                            <div>{{ item.member_num ? item.member_num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') : '0' }}</div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <button v-if="item.is_member == 0" :disabled="isLoading" @click="chanyughd(item.id, item.name)" class="pl-3 pr-3 pt-1 pb-1 yuanjiao ckqbb annys" style="font-size: calc(5px + 0.4vw);background: #FFD672;border: none;">
                      {{ $t('参加活动') }}
                    </button>
                    <button v-else :disabled="isLoading" class="pl-3 pr-3 pt-1 pb-1 yuanjiao annys" style="font-size: calc(5px + 0.4vw);background: #E5E5E5;border: none;">
                      {{ $t('已报名') }}
                    </button>
                  </div>
                  <div class="mt-2 slhzs" style="font-size: calc(5px + 0.4vw);">
                    {{ item.description }}
                  </div>
                </div>
              </div>
              <div v-else class="pl-3 pr-3 col-md-12 baise pt-3 pl-0 pr-0 pb-2 gdt" style="border-radius: 0 0 12px 12px;">
                <empty></empty>
              </div>

            </div>
          </div>
        </div>
        <div class=" yuanjiao pb-3 aaaa" style="width: 100%;">
          <div class="col-md-12 mt-3 pl-0 pr-0">
            <div class="col-md-12 yuanjiao pl-0 pr-0 parent" style="font-size: calc(7px + 0.4vw);line-height: calc(15px + 0.5vw);">
              <div class=" d-flex pl-0 pr-0 " style="width: 100%;justify-content: space-between;align-items: center;">
                <div class="d-flex pl-0 pr-0" style="width: 45%;height: 100%;margin-bottom: -10px;">
                  <div @click="fun(0)" class="pt-3 pb-3 curpo qieh sjbzt" :style="{ 'background': show == 0 ? '#FFFFFF' : '#FFFAF0', 'font-weight': show == 0 ? '700' : '500', 'color': show == 0 ? '' : '#999999' }">
                    {{ $t('所有活动') }} </div>
                  <div @click="fun(1)" class="pt-3 pb-3 curpo qieh sjbzt" :style="{ 'background': show == 1 ? '#FFFFFF' : '#FFFAF0', 'font-weight': show == 1 ? '700' : '500', 'color': show == 1 ? '' : '#999999' }">
                    {{ $t('志工活动') }}</div>
                </div>
                <div>
                  <img src="@/img/rili.png" alt="" style="width: 25px;height:25px;" v-b-toggle.sidebar-1>
                </div>
                <div class=" pl-0 pr-0 position-relative d-flex align-items-center" style="width: 40%;">
                  <input @input="shukchange" v-model="inputmodel" class="form-control sxbj col-md-12 inppt pr-5 sjbxzt" type="text" :placeholder="this.$t('搜寻')" plaintext='true' aria-label="Username" aria-describedby="basic-addon1">
                  <img style="right: 20px;width: calc(10px + 0.5vw);" class="position-absolute curpo sbxfbd" src="@/img/daohangl/icon_hssy_sousuo@2x.png" alt="" />
                </div>
              </div>
              <div v-if="list.length" class="pl-3 pr-3 col-md-12 baise pt-3 pl-0 pr-0 pb-2 gdt" style="border-radius: 0 0 12px 12px;">
                <div v-show="show == 0" v-for="(item, index) in list" :key="index" style="background: #F6F4F0;" class="yuanjiao  curpo  mb-3 ckqbb pl-3 pr-3 pt-3 pb-3">
                  <div class="d-flex justify-content-between align-items-center">
                    <div @click="huodxiangq(item)" class="d-flex justify-content-between align-items-center">
                      <img class="mr-2" style="width: calc(40px + 0.5vw);height: calc(40px + 0.5vw);border-radius: 50%;object-fit: cover;" :src="item.image" alt="" />
                      <div>
                        <div class="sjbzt" style="font-size: calc(5px + 0.4vw);">
                          {{ item.name }}
                        </div>
                        <div class="d-flex align-items-center">
                          <div class="d-flex mr-2 align-items-center sjbxzt" style="font-size: calc(4px + 0.4vw);color: #666666;">
                            <img style="width: calc(10px + 0.5vw);height: calc(10px + 0.5vw);" src="@/img/daohangl/icon_hssy_hdlb_tiewen@2x.png" alt="" />
                            <div>{{ item.moment_num ? item.moment_num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') : '0' }}</div>
                          </div>
                          <div class="d-flex align-items-center" style="font-size: 12px;color: #666666;">
                            <img style="width: calc(10px + 0.5vw);height: calc(10px + 0.5vw);" src="@/img/daohangl/icon_hssy_hdlb_renyuan@2x.png" alt="" />
                            <div>{{ item.member_num ? item.member_num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') : '0' }}</div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <button v-if="item.is_member == 0" :disabled="isLoading" @click="chanyughd(item.id, item.name)" class="pl-3 pr-3 pt-1 pb-1 yuanjiao ckqbb annys sjbxzt" style="font-size: calc(5px + 0.4vw);background: #FFD672;border: none;">
                      {{ $t('参加活动') }}
                    </button>
                    <button v-else :disabled="isLoading" class="pl-3 pr-3 pt-1 pb-1 yuanjiao annys sjbxzt" style="font-size: calc(5px + 0.4vw);background: #E5E5E5;border: none;">
                      {{ $t('已报名') }}
                    </button>
                  </div>
                  <div class="mt-2 slhzs sjbxzt" style="font-size: calc(5px + 0.4vw);">
                    {{ item.description }}
                  </div>
                </div>
                <div v-show="show == 1" v-for="(item, index) in list" :key="index" style="background: #F6F4F0;" class="yuanjiao  curpo  mb-3 ckqbb pl-3 pr-3 pt-3 pb-3">
                  <div class="d-flex justify-content-between align-items-center">
                    <div class="d-flex justify-content-between align-items-center">
                      <img @click="huodxiangq(item)" class="mr-2" style="width: calc(40px + 0.5vw);height: calc(40px + 0.5vw);border-radius: 50%;object-fit: cover;" :src="item.image" alt="" />
                      <div>
                        <div class="sjbzt" style="font-size: calc(5px + 0.4vw);">
                          {{ item.name }}
                        </div>
                        <div class="d-flex align-items-center">
                          <div class="d-flex mr-2 align-items-center sjbxzt" style="font-size: calc(4px + 0.4vw);color: #666666;">
                            <img style="width: calc(10px + 0.5vw);height: calc(10px + 0.5vw);" src="@/img/daohangl/icon_hssy_hdlb_tiewen@2x.png" alt="" />
                            <div>{{ item.moment_num ? item.moment_num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') : '0' }}</div>
                          </div>
                          <div class="d-flex align-items-center" style="font-size: 12px;color: #666666;">
                            <img style="width: calc(10px + 0.5vw);height: calc(10px + 0.5vw);" src="@/img/daohangl/icon_hssy_hdlb_renyuan@2x.png" alt="" />
                            <div>{{ item.member_num ? item.member_num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') : '0' }}</div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <button v-if="item.is_member == 0" :disabled="isLoading" @click="chanyughd(item.id, item.name)" class="pl-3 pr-3 pt-1 pb-1 yuanjiao ckqbb annys sjbxzt" style="font-size: calc(5px + 0.4vw);background: #FFD672;border: none;">
                      {{ $t('参加活动') }}
                    </button>
                    <button v-else :disabled="isLoading" class="pl-3 pr-3 pt-1 pb-1 yuanjiao annys sjbxzt" style="font-size: calc(5px + 0.4vw);background: #E5E5E5;border: none;">
                      {{ $t('已报名') }}
                    </button>
                  </div>
                  <div class="mt-2 slhzs sjbxzt" style="font-size: calc(5px + 0.4vw);">
                    {{ item.description }}
                  </div>
                </div>
              </div>
              <div v-else class="pl-3 pr-3 col-md-12 baise pt-3 pl-0 pr-0 pb-2 gdt" style="border-radius: 0 0 12px 12px;">
                <empty></empty>
              </div>

            </div>
          </div>
        </div>
        <div class="col-sm-4 col-xl-3 pl-3 pl-0 pr-0 mt-3 yinc">
          <div class="baise yuanjiao pt-3 pb-3 col-md-12">
            <div class="btn-group d-flex justify-content-between align-items-center mb-3">
              <img class="dcbd curpo" @click="prevMonth" style="width: calc(20px + 0.6vw);height: calc(20px + 0.6vw);" src="@/img/details/icon_rl_shangyige@2x.png" alt="" />
              <div style="font-size: calc(13px + 0.5vw);font-weight: 700;">{{ currentYear }}
                {{ $t('年') }}{{ currentMonth }}{{ $t('月') }}</div>
              <img class="dcbd curpo" @click="nextMonth" style="width: calc(20px + 0.6vw);height: calc(20px + 0.6vw);" src="@/img/details/icon_rl_xiayige@2x.png" alt="" />
            </div>
            <div class="d-flex justify-content-around">
              <div style="font-size: calc(8px +0.5vw);color: #666666;" v-for="day in daysOfWeek" :key="day">
                {{ day }}
              </div>
            </div>
            <table class="table table-bordered" style="border: none;">
              <tbody>
                <tr class="pl-0 pr-0" v-for="(week, index) in weeksInMonth" style="text-align: center;font-size: calc(7px +0.4vw);font-weight: 600;" :key="index">
                  <td class="pl-0 pr-0 pt-1 pb-1" style="border: none;" v-for="day in week" :key="day.date">
                    <div @click="xuanzdys(day.day, currentMonth)" :style="{ 'background': days == day.day && currentMonth == xwac ? '#FAB1AB' : '' }" :class="[day.day?'mt-2 curpo rqxz pt-1 pb-2 yuanjiao':'mt-2 pt-1 pb-2 yuanjiao']" id="">
                      {{ day.day }}
                      <div v-if="isIdEqual(day.day)" class="hongdian"></div>
                      <div v-else class="hongdians"></div>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
    <b-sidebar id="sidebar-1" shadow bg-variant="light" ref="sidebar">
      <div class="col-sm-12 col-xl-3  ">
        <div class="baise yuanjiao pt-3 pb-3 col-md-12" style="height: 87.5vh;text-align: center;">
          <div class="btn-group d-flex justify-content-between align-items-center mb-3">
            <img class="dcbd curpo" @click="prevMonth" style="width: calc(20px + 0.6vw);height: calc(20px + 0.6vw);" src="@/img/details/icon_rl_shangyige@2x.png" alt="" />
            <div style="font-size: calc(13px + 0.5vw);font-weight: 700;">{{ currentYear }}
              {{ $t("年") }}{{ currentMonth }}{{ $t("月") }}</div>
            <img class="dcbd curpo" @click="nextMonth" style="width: calc(20px + 0.6vw);height: calc(20px + 0.6vw);" src="@/img/details/icon_rl_xiayige@2x.png" alt="" />
          </div>
          <div class="d-flex justify-content-around">
            <div style="font-size: calc(9px + 0.4vw);color: #666666;" v-for="day in daysOfWeek" :key="day">
              {{ day }}
            </div>
          </div>
          <table class="table table-bordered" style="border: none;">
            <tbody>
              <tr class="pl-0 pr-0" v-for="(week, index) in weeksInMonth" style="text-align: center;font-size: calc(8px +0.5vw);font-weight: 600;" :key="index">
                <td class="pl-0 pr-0 pt-0 pb-0" style="border: none;" v-for="day in week" :key="day.date">
                  <div @click="xuanzdys(day.day, currentMonth)" :style="{ 'background': days == day.day && currentMonth == xwac ? '#FAB1AB' : '', 'font-size': 'calc(10px + 0.4vw)' }" :class="[day.day ? 'mt-2 curpo rqxz pt-1 pb-2 yuanjiao' : 'mt-2 pt-1 pb-2 yuanjiao']" id="">
                    {{ day.day }}
                    <div v-if="isIdEqual(day.day)" class="hongdian"></div>
                    <div v-else class="hongdians"></div>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
          <img style="width: 58%;height: 18%;margin-top: 80%;object-fit: contain;" src="@/img/dayu.png" alt="">
        </div>
      </div>
    </b-sidebar>
    <cjhdbd class="mt-3 mb-3" v-if="txbiaod == 2" ref="childRef" @custom="custom" @hdfh="hdfh"></cjhdbd>
  </div>
</template>

<script>
import empty from "@/components/empty/empty.vue";
import SkeletonScreen from "./SkeletonScreen/guanywmSkeletonScreen.vue";
import cjhdbd from "@/views/index/cjhdbd.vue";
import { expiry, calendar, activity_list } from "@/request/hshd/index.js";
export default {
  components: {
    SkeletonScreen,
    cjhdbd,
    empty,
  },
  data() {
    return {
      id: "",
      inputmodel: "",
      txbiaod: 1,
      countdown: `0 ${this.$t("天")} 0 ${this.$t("时")}  0 ${this.$t(
        "分"
      )} 0 ${this.$t("秒")}`,
      intervalId: null,
      targetTime: "",
      showButtons: false,
      // 当前显示的元素索引范围
      startIndex: 0,
      // 假设一次显示5个元素
      visibleCount: 1,
      show: 0,
      allItems: [],
      days: "",
      currentYear: new Date().getFullYear(),
      currentMonth: new Date().getMonth() + 1,
      daysOfWeek: ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"],
      daysInMonth: [],
      xwac: "",
      list: [],
      isLoading: false,
      dataLoaded: false,
      modalShow: false,
      page: 1,
      last_page: "",
      current_page: "",
      hongdianlist: [],
	  pagelist:1,
	  last_pages:1
    };
  },
  created() {
	EventBus.$on("hsqyactivity", (msg) => {
      // 执行方法，处理接收到的数据
      // this.message = msg;
      // 如果需要，可以在这里调用其他方法
      if (this.pagelist < this.last_pages) {
          this.pagelist++;
          this.activity_list();
        }
    });
  },
  beforeDestroy() {
    // 在组件销毁前移除事件监听器
    EventBus.$off("hsqyactivity");
  },
  mounted() {
    this.id = this.$route.params.id;
    this.liebsjian();
    this.daysInMonth = this.getDaysInMonth(this.currentYear, this.currentMonth);
    this.calendar();
    this.activity_list();
  },
  beforeDestroy() {
    if (this.intervalId) {
      clearInterval(this.intervalId);
    }
  },
  computed: {
    uniqueItems() {
      return [...new Set(this.allItems)];
    },
    // 计算当前应该显示的元素列表
    visibleItems() {
      return this.allItems.slice(
        this.startIndex,
        this.startIndex + this.visibleCount
      );
    },
    weeksInMonth() {
      let weeks = [];
      let done = false;
      let done1 = true;
      let date = 1;
      const a = this.getDayOfWeek(
        this.currentYear + "-" + this.currentMonth + "-" + "01"
      );
      while (!done) {
        let week = [];
        if (done1) {
          for (let i = 0; i < 7; i++) {
            done1 = false;
            if (date > this.daysInMonth.length) {
              done = true;
              break;
            }
            if (i < a) {
              week.push({ date: "", day: "" });
            } else {
              week.push(this.daysInMonth[date - 1]);
              date++;
            }
          }
        } else {
          for (let i = 0; i < 7; i++) {
            if (date > this.daysInMonth.length) {
              done = true;
              break;
            }
            week.push(this.daysInMonth[date - 1]);
            date++;
          }
        }
        weeks.push(week);
      }
      // console.log(weeks);
      return weeks;
    },
  },
  methods: {
    getDayOfWeek(dateString) {
      const days = [
        "星期日",
        "星期一",
        "星期二",
        "星期三",
        "星期四",
        "星期五",
        "星期六",
      ];
      const date = new Date(dateString);
      const dayIndex = date.getDay();
      // return days[dayIndex];
      return dayIndex;
    },
    chanyughd(e, v) {
      this.txbiaod = 2;
      setTimeout(() => {
        this.$refs.childRef.msg(e, v);
      }, 1000);
    },
    shukchange() {
      this.activity_list();
    },
    async activity_list(e) {
      const { data } = await activity_list({
        page: this.pagelist,
        organization_id: this.id,
        volunteer: this.show,
        keywords: this.inputmodel,
        date: e,
        status: 1,
      });
	  this.last_pages = data.last_pages;
      this.list = [...this.list, ...data.data];
	  
    },
    isIdEqual(e) {
      return this.hongdianlist.find((item) => item === e) !== undefined;
    },
    async calendar() {
      const { data } = await calendar({
        organization_id: this.id,
        year: this.currentYear,
        month: this.currentMonth,
      });
      this.hongdianlist = data.data;
    },
    async liebsjian() {
      const { data, code } = await expiry({
        page: this.page,
        // organization_id:
      });
      if (code == 200) {
        this.dataLoaded = true;
        this.current_page = data.current_page;
        this.last_page = data.last_page;
        this.allItems = [...this.allItems, ...data.data];
        if (data.data[0]?.end_time) {
          this.targetTime = data.data[0]?.end_time;
        }

        this.updateCountdown();
        this.intervalId = setInterval(this.updateCountdown, 1000);
      }
    },
    huodxiangq(e) {
      let a = e.identity == 2 ? "groups" : "enterprises";
      this.$router.push("/activity/" + a + "/" + e.slug + "/" + e.id);
    },
    custom() {
      this.txbiaod = 1;
    },
    hdfh(e) {
      this.txbiaod = 1;
      for (let i = 0; i < this.list.length; i++) {
        if (this.list[i].id == e) {
          this.list[i].is_member = 1;
        }
      }
      for (let i = 0; i < this.allItems.length; i++) {
        if (this.allItems[i].id == e) {
          this.allItems[i].is_member = 1;
        }
      }
    },
    updateCountdown() {
      const now = new Date().getTime();
      const distance = this.targetTime * 1000 - now;
      if (distance < 0) {
        this.countdown = `0 ${this.$t("天")} 0 ${this.$t("时")}  0 ${this.$t(
          "分"
        )} 0 ${this.$t("秒")}`;
        return;
      }

      const days = this.pad(Math.floor(distance / (1000 * 60 * 60 * 24)));
      const hours = this.pad(
        Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60))
      );
      const minutes = this.pad(
        Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60))
      );
      const seconds = this.pad(Math.floor((distance % (1000 * 60)) / 1000));

      this.countdown = `${days} ${this.$t("天")} ${hours} ${this.$t(
        "时"
      )} ${minutes} ${this.$t("分")} ${seconds} ${this.$t("秒")}`;
    },
    pad(num) {
      return ("0" + num).slice(-2);
    },
    // 点击加入当前活动
    bulletframe() {},
    fun(e) {
      this.show = e;
      this.days = "";
      this.xwac = "";
	  this.pagelist=1
      this.activity_list();
    },
    xuanzdys(e, v) {
      if (this.days == e) {
        this.days = "";
        this.xwac = "";
        this.activity_list("");
      } else {
        this.days = e;
        this.xwac = v;
        this.activity_list(
          this.currentYear + "-" + this.currentMonth + "-" + e
        );
      }
      this.$refs.sidebar.hide();
    },
    prevMonth() {
      if (this.currentMonth === 1) {
        this.currentYear--;
        this.currentMonth = 12;
      } else {
        this.currentMonth--;
      }
      this.daysInMonth = this.getDaysInMonth(
        this.currentYear,
        this.currentMonth
      );
      this.calendar();
    },
    nextMonth() {
      if (this.currentMonth === 12) {
        this.currentYear++;
        this.currentMonth = 1;
      } else {
        this.currentMonth++;
      }
      this.daysInMonth = this.getDaysInMonth(
        this.currentYear,
        this.currentMonth
      );
      this.calendar();
    },
    getDaysInMonth(year, month) {
      let days = [];
      let numDays = new Date(year, month, 0).getDate();

      for (let day = 1; day <= numDays; day++) {
        days.push({
          date: day,
          day: day,
        });
      }
      return days;
    },
  },
};
</script>

<style scoped>
@media screen and (max-width: 999px) {
  .aaaa {
    display: block !important;
  }
  .yinc {
    display: none !important;
  }
  .tpiand {
    display: none;
  }
  .sjbzt {
    font-size: calc(11px + 0.4vw) !important;
  }
  .sjbxzt {
    font-size: calc(9px + 0.4vw) !important;
  }
  .sjwd {
    width: 100% !important;
  }
}
.aaaa {
  display: none;
}
.dcbd:hover {
  transform: scale(1.2);
}

.ckqbb:hover {
  transform: scale(1.001);
  /* 悬浮时放大1.1倍 */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.rqxz:hover {
  transform: scale(1.001);
  /* 悬浮时放大1.1倍 */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  background-color: #fcd7d5;
  border-radius: 12px;
}

.slhzs {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
  text-overflow: ellipsis;
}

.dcbd:hover {
  transform: scale(1.2);
}

.qieh {
  width: calc(100px + 0.4vw);
  text-align: center;
  border-radius: 12px 12px 0 0;
}

.parent::-webkit-scrollbar {
  display: none;
}

.sxbj {
  padding-top: calc(15px + 0.5vw);
  padding-bottom: calc(15px + 0.5vw);
}

.inppt {
  background: white;
  border: none;
  border-radius: 13px 13px 0 0;
  font-size: calc(7px + 0.4vw);
  padding-top: 22px;
  padding-bottom: 22px;
}

.inppt:hover {
  border: none;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.gdt::-webkit-scrollbar {
  display: none;
}

.hongdian {
  width: 6px;
  height: 6px;
  border-radius: 50%;
  background-color: #ff797a;
  margin: auto;
}

.hongdians {
  width: 6px;
  height: 6px;
  border-radius: 50%;
}
</style>